<template>
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000713562?lang=cs" 
                   URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000713562?lang=en" 
                PopisLinku='clanky.pracCestaSoukrVozidlo'></HelpLink>
    <div height="calc(100% - 57px)">
        <div id="pracCesta">

            <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')"
                :contentTemplate="popupAlertTemplate" :width="400" :height="200">
                <template #SmazatPoz>
                    <h3>{{ $t('majetekVyrazeni-OpravduSiPrejeteSmazat') }}</h3>
                    <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                        :text="$t('central-Ano')" @click="SetState(-99, true); popupAlert = false" />
                    <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                        :text="$t('central-Ne')" @click="popupAlert = false" />
                </template>
            </DxPopup>

            <div id="h2ab">
                <DxButton icon="back" :text="$t('majetekVyrazeni-VratitSeZpet')" height="auto" type="outlined"
                    styling-mode="contained" id="link" @click="$router.push('Pozadavky')" />
                <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="zakladani">{{
                    $t('pracCesta.VytvoreniNadpis1')
                }}
                </h2>
                <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{ $t('pracCesta.Nadpis') }}{{ ' ' + poz +
                    ' ('
                    + state + ')' }} </h2>
            </div>
            <form action="your-action" @submit="onFormSubmit($event)">
                <div id="obaleniFormulare">
                    <div id="obalenigrid">
                        <DxResponsiveBox single-column-screen="sm" class="ResponsiveBoxDefaultCSS">
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxCol :ratio="1" />
                            <DxCol :ratio="1" />
                            <DxCol :ratio="1" />
                            <DxItem>
                                <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <h4>{{ $t('pracCesta.Zamestnanec') }}</h4>
                                </div>

                            </DxItem>
                            <DxItem>
                                <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                <DxLocation :row="1" :col="0" :colspan="2" screen="sm" />
                                <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />

                                <div class="SmlouvaBox">
                                    <div class="dx-field">
                                        <DxTextBox :disabled="stateid != 4" :id="1" width='auto' height="45px"
                                            :label="$t('pracCesta.RidicPrukazCislo')" placeholder=""
                                            v-model:value="formDataCarUsage.ridicskyprukazcislo"
                                            @value-changed=" setSelectedValue($event);">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('pracCesta.VyplnRidicskyPrukaz')" />
                                            </DxValidator>
                                        </DxTextBox>

                                    </div>
                                </div>
                            </DxItem>
                            <DxItem width="400px">
                                <DxLocation :row="1" :col="1" :colspan="1" screen="lg" />
                                <DxLocation :row="1" :col="1" :colspan="1" screen="md" />
                                <DxLocation :row="1" :col="2" :colspan="1" screen="sm" />
                                <DxLocation :row="2" :col="0" :colspan="2" screen="xs" />

                                <div class="SmlouvaBox PracCestaBoxWithWarning" width='auto'>
                                    <DxDateBox :disabled="stateid != 4" :id="2" :label="$t('pracCesta.SkoleniRidicuDo')"
                                        v-model:value="formDataCarUsage.platnost_ridic_skoleni_do" Width='auto'
                                        height="45px" @value-changed=" setSelectedValue($event);"
                                        :accept-custom-value="false" :open-on-field-click="true">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnSkoleniRidicuDo')" />
                                        </DxValidator>
                                    </DxDateBox>
                                    <WarningImage v-if="this.formDataCarUsage.vykricnik1" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px" /> <!--v-if="this.formDataCarUsage.vykricnik1"-->
                                </div>
                            </DxItem>

                            

                        <DxItem>
                            <DxLocation :row="1" :col="2" :colspan="1" screen="lg" />
                            <DxLocation :row="1" :col="2" :colspan="1" screen="md" />
                            <DxLocation :row="3" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="3" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox PracCestaBoxWithWarning"> 
                                <DxDateBox v-if="jeRidiciNad65Let(this.datumNarozeniRidice,new Date())" :disabled="stateid != 4" :id="3" height="45px" 
                                    :label="$t('pracCesta.LekarskaProhlidkaDo')" placeholder=""
                                    v-model:value="formDataCarUsage.platnost_lekar_prohlidka_do" @value-changed=" setSelectedValue($event);" :accept-custom-value="false" :open-on-field-click="true">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnteLekarskouProhlidkuDo')" />
                                        </DxValidator>      
                                </DxDateBox>
                                <WarningImage v-if="jeRidiciNad65Let(this.datumNarozeniRidice,new Date()) && (this.formDataCarUsage.vykricnik1)" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px" />
                                
                            </div>
                             
                        </DxItem>
                        <!--<DxItem>
                            <DxLocation :row="2" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="2" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="4" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="4" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox" id="potvrzeniInfoAuto">
                                <h4 style="color: orange;"><i>{{ $t('pracCesta.VPripadeZmenyAktualizujte')}}</i></h4>
                                <--{{  formDataCarUsage.stvrzeno }}
                                <button type="button" class="btn" id="btnPotvrdit" width="">
                                    <span>
                                        <span>Potvrdit</span>
                                    </span>
                                </button>
                            </div>

                        </DxItem>-->
                            <DxItem>
                                <DxLocation :row="3" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="3" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="5" :col="0" :colspan="2" screen="sm" />
                                <DxLocation :row="5" :col="0" :colspan="2" screen="xs" />
                                <div class="SmlouvaBox">
                                    <h4>{{ $t('pracCesta.Cesta') }}</h4>
                                </div>

                            </DxItem>
                            <DxItem>
                                <DxLocation :row="6" :col="0" :colspan="1" screen="lg" />
                                <DxLocation :row="6" :col="0" :colspan="1" screen="md" />
                                <DxLocation :row="6" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="6" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <DxTextBox :disabled="stateid != 4" :id="4" width="auto" height="45px"
                                        :label="$t('pracCesta.Cil')" placeholder="" v-model:value="formDataCarUsage.cil"
                                        @value-changed=" setSelectedValue($event);">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnteCil')" />
                                        </DxValidator>
                                    </DxTextBox>
                                </div>
                            </DxItem>
                            <DxItem>
                                <DxLocation :row="6" :col="1" :colspan="1" screen="lg" />
                                <DxLocation :row="6" :col="1" :colspan="1" screen="md" />
                                <DxLocation :row="7" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="7" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox" id="odhadkm">
                                    <DxNumberBox class="odhadkmchild" height="45px" width="100%" :disabled="stateid != 4"
                                        :id="5" :label="$t('pracCesta.OdhadKm')" placeholder=""
                                        @value-changed="setSelectedValue($event)"
                                        v-model:value="formDataCarUsage.odhad_cesta_km" :format="{
                                            type: 'fixedPoint',
                                            thousandSeparator: ' ',
                                        }" :step="0">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnteOdhadKm')" />
                                        <DxCustomRule
                                                :validation-callback="validateNumberPositive"
                                                :message="this.$t('Validator.SpropitneMusiBytKladne')"
                                                :reevaluate="true"
                                            />
                                            
                                    </DxValidator>
                                    </DxNumberBox>
                                    <label class="odhadkmchild" id="labelkm">km</label>
                                </div>

                            </DxItem>
                            <DxItem>
                                <DxLocation :row="7" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="7" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="9" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="9" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox" width="auto" height="auto">
                                    <DxTextArea :disabled="stateid != 4" minHeight="80px" :id="6" width="auto"
                                        :label="$t('pracCesta.Ucel')" placeholder=""
                                        v-model:value="formDataCarUsage.ucel_cesty" :autoResizeEnabled="true"
                                        @value-changed="setSelectedValue($event);">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnteUcel')" />
                                        </DxValidator>
                                    </DxTextArea>

                                </div>

                            </DxItem>
                            <DxItem>
                                <DxLocation :row="8" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="8" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="10" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="10" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox" width="auto" height="auto">
                                    <DxSelectBox ref="selectboxduvodpotreby" :id="7" width="auto" height="45px"
                                        :label="$t('pracCesta.DuvodPotreby')" :data-source="duvod_potreby"
                                        display-expr="content" value-expr="id" placeholder=""
                                        v-model:value="formDataCarUsage.cis_duvod_potreby"
                                        @value-changed=" setSelectedValue($event);"
                                        :disabled="stateid != 4">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracCesta.VyplnteDuvodPotreby')" />
                                        </DxValidator>
                                    </DxSelectBox>
                                </div>
                            </DxItem>
                            <DxItem>
                                <DxLocation :row="9" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="9" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="11" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="11" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox" width="auto" height="auto" v-if="zobrazitJinyDuvodTXT">
                                    <DxTextArea :disabled="stateid != 4" minHeight="80px" :id="8" width="auto"
                                        :label="$t('pracCesta.JinyDuvod')" placeholder=""
                                        v-model:value="formDataCarUsage.jinyDuvodTXT" :autoResizeEnabled="true"
                                        @value-changed="setSelectedValue($event);">
                                        <!--<DxValidator>
                                                <DxRequiredRule :message="$t('pracCesta.VyplnteJinyDuvod')" />
                                        </DxValidator>-->
                                    </DxTextArea>
                                </div>
                            </DxItem>
                            <!--<DxItem>
                                    <DxLocation :row="9" :col="0" :colspan="3" screen="lg" />
                                    <DxLocation :row="9" :col="0" :colspan="3" screen="md" />
                                    <DxLocation :row="11" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="11" :col="0" :colspan="3" screen="xs" />
                                    <div class="SmlouvaBox"  width="auto" height="auto">
                                        <h3>{{ $t('pracCesta.UcelPouziti')}}</h3>
                                        <DxSelectBox v-model:readOnly="ReadOnlyState" ref="selectboxpouziti" :id="4" width="auto" height="45px"
                                             :data-source="pouziti" display-expr="content" value-expr="id" placeholder="" :disabled="stateid != 4"
                                            v-model:value="formDataCarUsage.cis_pouziti" @value-changed=" setSelectedValue($event);"
                                            class="DxSelectBoxResponsive">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('pracCesta.VyplnteUcelPouziti')" />
                                            </DxValidator>
                                        </DxSelectBox>
                                    </div>
                        </DxItem>-->
                        <DxItem width="1000px" height="500px">
                            <DxLocation :row="6" :col="2" :colspan="1" screen="lg" />
                            <DxLocation :row="6" :col="2" :colspan="1" screen="md" />
                            <DxLocation :row="8" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="8" :col="0" :colspan="3" screen="xs" />
                                
                                <div class="SmlouvaBox" width='auto' height="45px">
                                            <DxDateRangeBox :id="9" height="45px" :start-date-label="$t('pracCesta.KonaniOd')" :end-date-label="$t('pracCesta.KonaniDo')" 
                                                apply-value-mode="instantly" multiview:="true" 
                                                :startDatePlaceholder="$t('pracCesta.DatumPocatekPracCesty')" :endDatePlaceholder="$t('pracCesta.DatumKonecPracCesty')" :disabled="stateid != 4"
                                                v-model:startDate="formDataCarUsage.konani_od" 
                                                v-model:endDate="formDataCarUsage.konani_do" date-serialization-format="dd-mm-yyyy" @value-changed=" setSelectedValue($event);"
                                                :accept-custom-value="false" :open-on-field-click="true">
                                                <DxValidator>
                                                <DxRequiredRule :message="$t('pracCesta.VyplnteKonani')" />
                                            </DxValidator> 
                                        </DxDateRangeBox>
                                    </div>
                            </DxItem>
                           
                            </DxResponsiveBox>
                            </div>
                            </div>
                            
                            <div id="obalenigrid" v-if="!zakladani"  >
                                    <div id="obaleniFormulare" class="">
                                    <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " >{{ $t('pracCesta.VytvoreniNadpis2')}}</h2>
                                   
                                    
                                    <Cars class="ResponsiveBoxDefaultCSS" :disabled="stateid != 4" :CarID="formDataCarUsage.cislo_vozidla" 
                                    :TripRequestID="poz" @value-changed="setSelectedValue($event)" :SmazatVozidloEnabled="false" :volaniTyp=2 :DatumCestyDo="formDataCarUsage.konani_do" />
                                </div>
                            </div>
            <div id="obalenigrid" class="obaleniFormulare2 predposledniDiv" padding-top="5px" v-if="!zakladani">
            <DxResponsiveBox single-column-screen="sm" id="" class="ResponsiveBoxClass">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxItem>
                        <DxLocation :row="0" :col="2" :colspan="2" screen="lg" />
                        <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="4" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="4" screen="xs" />
                        <div style="margin-left: 10px;">
                            <Attachments :Disabled="stateid != 4" :Pozid="this.poz"  :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                            <br>
                        </div>

                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="4" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="4" screen="xs" />
                        <div class="dx-field" v-if="!zakladani">

                            <DxTextArea style="margin-left: 10px;" class="DxSelectBoxResponsive" :disabled="stateid != 4"
                                :id="10" :autoResizeEnabled="true" :label="$t('majetekPrevod-Komentar')" width="auto"
                                :minHeight="100"  v-model:value="komentar" @value-changed=" setSelectedValue($event);"
                                placeholder="" /> 
                        </div>
                    </DxItem>
                </DxResponsiveBox>
            </div>
            <DxResponsiveBox single-column-screen="sm" id="PosledniResponsiveBox"
                class="ResponsiveBoxClass SpodniMenuDetailPozadavku">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />

                <DxItem>
                    <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                    <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                    <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                    <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                    <div class="child" v-if="zakladani" id="ResponsiveBoxItemActionButtons">
                        <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('pracCesta.Zalozit')"
                            :use-submit-behavior="true" type="success" />

                    </div>
                </DxItem>

                <DxItem>
                    <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                    <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!zakladani">
                    <div id="ResponsiveBoxItemActionButtons" v-if="!zakladani">
                        <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                            <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default"
                                styling-mode="contained" :text="item.nazev" :hint="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                        </div>
                    </div>
                    </DxScrollView>

                </DxItem>
            </DxResponsiveBox>

        </form>



    </div>
    </div>
</template>

<script>
//import axios from 'axios'
import DxDateRangeBox from 'devextreme-vue/date-range-box';
import notify from 'devextreme/ui/notify';
import Attachments from '@/components/Attachments.vue';
import Cars from '@/components/Cars.vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import WarningImage from '@/components/WarningImage.vue';
import HelpLink from '@/components/HelpLink.vue';
import { isNull, isUndefined } from 'lodash';
import DxValidator, {
    DxCustomRule
} from 'devextreme-vue/validator';
import DxScrollView from 'devextreme-vue/scroll-view';
export default {
    name: "PracCestaSoukrVozidloView",

    components: {
        Attachments,
        DxTextBox,
        DxNumberBox,
        DxDateRangeBox,
        WarningImage,
        Cars,
        HelpLink,
        DxValidator,
        DxCustomRule,
        DxScrollView
    },

    data() {
        return {
            //  pattern: /^\d{}/i,
            ZamestnanecJeStarsi65LET: false,
            formDataCarUsage: {
                ridicskyprukazcislo: null,
                platnost_ridic_skoleni_do: null,
                platnost_lekar_prohlidka_do: null,
                cil: '',
                odhad_cesta_km: null,
                ucel_cesty: '',
                konani_od: null,
                konani_do: null,
                konani: null,
                spz: '',
                vyrobni_znacka: '',
                objem_valcu: null,
                jinyVlastnik: false,
                jinyvlastnik_jmeno: '',
                jinyvlastnik_prijmeni: '',
                phm_druh: '',
                spotreba: null,
                STK_platnost: null,
                povinne_pojistovna: '',
                povinne_cislo_pojistky: '',
                povinne_od: null,
                povinne_do: null,
                havarijni_pojistovna: null,
                havarijni_cislo_pojistky: '',
                havarijni_od: null,
                havarijni_do: null,
                cis_pouziti: 0,
                cis_duvod_potreby: null,
                pozadavek_cislo: 0,
                cislo_vozidla: 0,
                vlastniksouhlas: null,
                stvrzeno: null,
                jinyDuvodTXT: '', 
                vykricnik1: false,
                vykricnik2: false,

            },
            datumNarozeniRidice: null,
            PocetKolonek: 8,  // kolikrat se zavola setselectedvalue, pokud je komentar vyplneny, dava se PocetKolonek++
            MountingData: false,
            stateid: 4,
            state: 'Uložený',
            popupAlert: false,
            popupAlertTemplate: '',
            actionsPozadavky: [],
            komentar: '',
            poz: 0,
            zakladani: true,
            pouziti: [],
            typypouziti:[],
            duvod_potreby: [],
            zobrazitJinyDuvodTXT: false,

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        window.MountCount = 0
    },
    mounted() {
        this.CisPouzitiLoad();
        this.CisTypyPouziti();
        this.CisDuvodPotrebyLoad();
        this.LoadZamPersInfo();
        this.MountingData = true
        if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
            this.zakladani = false
            this.poz = window.localStorage.getItem("pozid")
            this.DisplayRequest();
            this.GetCarUsageApproval(this.poz)
        }
        this.LoadBarvyTheme();

    },

    methods: {
        ValidateDatesBusinessTrip() {
            if (this.formDataCarUsage.platnost_ridic_skoleni_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik1 = true
            else this.formDataCarUsage.vykricnik1 = false
            if (this.formDataCarUsage.platnost_lekar_prohlidka_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik2 = true
            else this.formDataCarUsage.vykricnik2 = false
            if (this.formDataCarUsage.STK_platnost < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik3 = true
            else this.formDataCarUsage.vykricnik3 = false 
            if (this.formDataCarUsage.povinne_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik3 = true
            else this.formDataCarUsage.vykricnik4 = false
            if (this.formDataCarUsage.havarijni_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik3 = true
            else this.formDataCarUsage.vykricnik5 = false
        },
        CisTypyPouziti(){
            let self = this;
            self.typypouziti.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookupDetail('DuvodyPouziti', 1, 25,
                function (result) {
                    window.this.$log.debug(result);
                    window.this.$log.debug('DuvodyPouziti');
                    for (var x = 0; x < result.List.value.items.length; x++) {
                    //window.this.$log.debug(result.List.value.items[x]);
                        var i = self.typypouziti.length;

                        self.typypouziti[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                            ident: result.List.value.items[x].Ident.value,
                        };
                    }
                }, window.this.ApiError());
        },
        CisPouzitiLoad() {
            let self = this;
            self.pouziti.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('VozidloPouziti', '', 1, 25,
                function (result) {
                    window.this.$log.debug(result);
                    window.this.$log.debug('CisPouziti');
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.pouziti.length;

                        self.pouziti[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    self.$log.debug('pouziti hotovo')
                    self.$log.debug(self.$refs.selectboxpouziti.instance.getDataSource())
                    var phm = this.formDataCarUsage.cis_pouziti
                    self.$refs.selectboxpouziti.instance.reset()
                    self.$refs.selectboxpouziti.instance.option('value', parseInt(phm))
                    self.$log.debug(self.$refs.selectboxpouziti.instance.option('value'))

                }, window.this.ApiError());
        },
        CisDuvodPotrebyLoad() {
            let self = this;
            self.duvod_potreby.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('DuvodyPouziti', '', 1, 25,
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.duvod_potreby.length;

                        self.duvod_potreby[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    self.$log.debug('duvod_potreby hotovo')
                    self.$log.debug(self.$refs.selectboxduvodpotreby.instance.getDataSource())
                    var phm = this.formDataCarUsage.cis_duvod_potreby
                    self.$refs.selectboxduvodpotreby.instance.reset()
                    self.$refs.selectboxduvodpotreby.instance.option('value', parseInt(phm))
                    self.$log.debug(self.$refs.selectboxduvodpotreby.instance.option('value'))

                }, window.this.ApiError());
        },
        LoadBarvyTheme() {
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
            var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
            var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
            var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
            //console.log('Background Color:', FillColor);
            document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
            document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
        setSelectedValue(e) {

            window.MountCount++
            window.this.$log.debug(e);
            window.this.$log.debug(window.MountCount + 'MountCount');
            window.this.$log.debug(this.PocetKolonek + 'Dokolika');
            if (window.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
            switch (e.element.id) {
                case '1':

                    break;
                case '2':

                    break;
                case '3':

                    break;
                case '4':

                    //this.komentar = e.value
                    break;
                case '6':
                    //this.komentar = e.value

                    break;
                case '7':
                    try{
                        this.zobrazitJinyDuvodTXT = false;
                        var itemIdToFind = this.formDataCarUsage.cis_duvod_potreby; 
                        var foundItem = this.typypouziti.find(item => item.id === itemIdToFind);
                        // window.this.$log.debug(foundItem)
                        if ((this.formDataCarUsage.cis_duvod_potreby == foundItem.id) && (foundItem.ident == "OtherReason")) 
                            {this.zobrazitJinyDuvodTXT = true;} 
                        else 
                            {this.zobrazitJinyDuvodTXT = false;}
                        //window.this.$log.debug(this.zobrazitJinyDuvodTXT);
                    } catch {window.this.$log.debug('setSelectedValue7CATCH')}
                    break;
                case '8':

                    // this.komentar = e.value
                    break;
                case '9':
                    // this.komentar = e.value
                    break;
                case '10':
                    // this.komentar = e.value
                    break;
            }
            if (!isUndefined(e.value) && !isNull(e.value) && !this.zakladani && !this.MountingData && !isNull(e.value))
                this.SetRequestPrivateCarUsage()
            // this.SkipDiacritics(this.typpozadavek2.content)
            //  this.pozadavek=e.value
            // this.selectedValue = e.value;
        },
        LoadZamPersInfo() {
            let self = this;
            var x = null;
            var employeeID = null;
            x = JSON.parse(localStorage.getItem("user"));
            employeeID = x.EmployeeID.value;
            var pers = new window.Personal(window.channel, window.message);
            pers.GetPersonalUserInfo(employeeID, function (result) {
                window.this.$log.debug("GetPersonalUserInfo");
                window.this.$log.debug(result);
                if (result.DateToMedicalCheckUp.value.getFullYear() != 1899) {
                    self.formDataCarUsage.platnost_lekar_prohlidka_do = result.DateToMedicalCheckUp.value;
                    self.PocetKolonek++
                }
                self.datumNarozeniRidice = result.DateOfBorn.value;
                self.ZamestnanecJeStarsi65LET = self.jeRidiciNad65Let(self.datumNarozeniRidice, new Date());

                if (result.DateToTrainingOfDrivers.value.getFullYear() != 1899) {
                    self.formDataCarUsage.platnost_ridic_skoleni_do = result.DateToTrainingOfDrivers.value;
                    self.PocetKolonek++
                }
                self.formDataCarUsage.ridicskyprukazcislo = self.ValidReturnNotInputedValues(result.DriversLicence.value);
                // if (result.DateOfBorn.value) -> ověření zdali je potřeba povinná lékařská prohlídka nebo ne 
            }, window.this.ApiError());

        },
        ValidReturnNotInputedValues(inputValue) {
            if (inputValue == 0) { return '' } else { return inputValue }

        },
        validateNumberPositive(e) {
                    this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value >= 0;
                    //this.validationStatus = isValid ? 'valid' : 'invalid';
                    return isValid;
        },
    
        VyplnAutoInformace(e) {
            var self = this;
            var x, employeeID = null;
            x = JSON.parse(localStorage.getItem("user"));
            employeeID = x.EmployeeID.value;
            self.formDataCarUsage.spz = e.value;
            var pers = new window.Personal(window.channel, window.message);
            pers.GetCar(e.value, employeeID, function (result) {
                window.this.$log.debug(result);
                self.formDataCarUsage.vyrobni_znacka = result.Manufacturer.value;
                self.formDataCarUsage.objem_valcu = result.Engine_Content.value;
                self.formDataCarUsage.phm_druh = result.cis_FuelType.value;
                self.formDataCarUsage.spotreba = result.Consumption.value;
                self.formDataCarUsage.STK_platnost = result.STK_DateTo.value;
                self.formDataCarUsage.povinne_pojistovna = result.Povinne_Insurance.value;
                self.formDataCarUsage.povinne_cislo_pojistky = result.Povinne_NumOfInsurance.value;
                self.formDataCarUsage.povinne_od = result.Povinne_DateFrom.value;
                self.formDataCarUsage.povinne_do = result.Povinne_DateTo.value;
                self.formDataCarUsage.havarijni_pojistovna = result.Havarijni_Insurance.value;
                self.formDataCarUsage.havarijni_cislo_pojistky = result.Havarijni_NumOfInsurance.value;
                self.formDataCarUsage.havarijni_od = result.Havarijni_DateFrom.value;
                self.formDataCarUsage.havarijni_do = result.Havarijni_DateTo.value;
                self.formDataCarUsage.jinyvlastnik_jmeno = result.Owner_Firstname.value;
                self.formDataCarUsage.jinyvlastnik_prijmeni = result.Owner_Surname.value;
                /*ridicskyprukazcislo: 0,
                 platnost_ridic_skoleni_do: null,
                 platnost_lekar_prohlidka_do: null,
                 cil: '',
                 odhad_cesta_km: 0,
                 ucel_cesty: '',
                 konani_od: null,
                 konani_do: null,
                 konani:null,
                 spz: '',
                 vyrobni_znacka: '',
                 objem_valcu: 0,
                 jinyVlastnik: false,
                 jinyvlastnik_jmeno: '',
                 jinyvlastnik_prijmeni: '',
                 phm_druh: '',
                 spotreba: 0,
                 STK_platnost: null,
                 povinne_pojistovna: '',
                 povinne_cislo_pojistky: '',
                 povinne_od:null,
                 povinne_do:null,
                 havarjini_pojistovna:null,
                 havarjini_cislo_pojistky:'',
                 havarjini_od:null,
                 havarjini_do:null,
                 pozadavek_cislo:null*/
            }
            );

        },

        callfce() {
            this.LoadspzZamestnance();
        },
        test() {
            //  const checkbox = document.getElementById('check');
            const getValueButton = document.getElementById('getValueButton');
            getValueButton.addEventListener('click', () => {
                // const checkboxValue = checkbox.checked;

            })

        },

        SetState(stav, alert) {
            window.this.$log.debug(alert + ' alert<<<??>')
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);

            if (stav == -1) {
                window.this.$log.debug('konec')
                this.popupAlert = true
                this.popupAlertTemplate = 'SmazatPoz'
                return 1;
            }
            if (alert == true) stav = -1
            // self.open();
            wh.SetState(self.poz, stav, '',
                function (result) {
                    window.this.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.DisplayRequest();
                        if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

                        var stavText;
                        switch (stav) {
                            case -1: stavText = self.$t('pozadavky-StavZrusen')
                                break;
                            case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                                break;
                            case 4: stavText = self.$t('pozadavky-StavUlozeny')
                                break;
                        }
                        notify({
                            message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                    }
                    //   self.hide();
                }, window.this.ApiError());

        },
        DisplayRequest() {
            window.this.$log.debug('display request')
            let self = this;
            self.actionsPozadavky.length = 0;
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetRequest(self.poz,
                function (result2) {
                    window.this.$log.debug(result2);
                    self.state = result2.ReqStateText.value;
                    self.stateid = result2.ReqState.value;
                    for (var x = 0; x < result2.Actions.value.items.length; x++) {
                        var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
                        };
                    }
                }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
        },
        onFormSubmit(e) {
            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            e.preventDefault();
            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e)
            this.SetRequestPrivateCarUsage();

        },
        SetRequestPrivateCarUsage() {
            let self = this;
            //  var podminkaLekarskaprohlidka=false;
            var wf = new window.WorkFlow(window.channel, window.message);
            this.$log.debug(self.formDataCarUsage)
            self.formDataCarUsage.vlastniksouhlas = self.formDataCarUsage.konani_od
            if (self.formDataCarUsage.platnost_lekar_prohlidka_do == null) self.formDataCarUsage.platnost_lekar_prohlidka_do = '';
            wf.SetCarUsageApproval(self.poz, self.formDataCarUsage.cil, self.formDataCarUsage.odhad_cesta_km, self.formDataCarUsage.ucel_cesty,
                self.formDataCarUsage.konani_od, self.formDataCarUsage.konani_do, self.formDataCarUsage.ridicskyprukazcislo, self.formDataCarUsage.cislo_vozidla, self.formDataCarUsage.platnost_lekar_prohlidka_do,
                self.formDataCarUsage.platnost_ridic_skoleni_do, self.formDataCarUsage.vlastniksouhlas, self.formDataCarUsage.cis_pouziti, self.formDataCarUsage.cis_duvod_potreby, self.komentar, self.formDataCarUsage.jinyDuvodTXT,
                function (result) {
                    window.this.$log.debug(result);
                    if (result.Error.value.ErrorNum.value == 0) {
                        if (self.poz == 0) {
                            window.localStorage.setItem("pozid", result.Value.value)
                            self.poz = result.Value.value
                            self.zakladani = false
                            self.MountingData = false
                            self.DisplayRequest()
                            notify({
                                message: self.$t('pracCesta.pracCestaZalozena'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        }
                        else {
                            notify({
                                message: self.$t('pracCesta.pracCestaZmenyUlozeny'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        }
                    } else {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 3000);

                    }
                    self.ValidateDatesBusinessTrip()
                }, window.this.ApiError());

        },
        GetCarUsageApproval(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetCarUsageApproval(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.formDataCarUsage.ridicskyprukazcislo = result.DriversLicence.value
                        self.formDataCarUsage.platnost_ridic_skoleni_do = result.Driver_Training_To.value
                        self.$log.debug("result.Driver_Training_To.value")
                        self.$log.debug(result.Driver_Training_To.value)
                        self.formDataCarUsage.platnost_lekar_prohlidka_do = result.Doctors_Examination_To.value
                        if (self.formDataCarUsage.platnost_lekar_prohlidka_do.getFullYear != 1899) self.PocetKolonek++
                        self.formDataCarUsage.cil = result.Destination.value
                        self.formDataCarUsage.odhad_cesta_km = result.Length_Estimation.value
                        self.formDataCarUsage.ucel_cesty = result.Purpose.value
                        self.formDataCarUsage.konani_od = result.Date_From.value
                        self.formDataCarUsage.konani_do = result.Date_To.value
                        self.formDataCarUsage.cislo_vozidla = result.Ev_Car_ID.value
                        self.formDataCarUsage.cis_pouziti = result.Cis_Pouziti.value
                        //   if(result.Cis_Pouziti.value!=0) self.PocetKolonek++
                        self.formDataCarUsage.cis_duvod_potreby = result.Cis_Duvod_Potreby.value
                        self.formDataCarUsage.jinyDuvodTXT = result.Other_ReasonTXT.value
                        // if (result.Other_ReasonTXT.value != '') self.PocetKolonek++  // neni potreba, protoze tato kolonka jeste neni nactena,kdyz se do ni uklada hodnota
                        self.komentar = result.Request.value.Comment.value
                        if (self.komentar != '') self.PocetKolonek++
                    }
                    self.ValidateDatesBusinessTrip()
                }, window.this.ApiError());
        },
        jeRidiciNad65Let(narozeni, dnesek) {
            this.ZamestnanecJeStarsi65LET = this.isDateDifferenceGreaterThan65Years(narozeni, dnesek);
            return this.ZamestnanecJeStarsi65LET
        },
        isDateDifferenceGreaterThan65Years(date1, date2) {
            const startDate = new Date(date1);
            const endDate = new Date(date2);

            const timeDiff = endDate - startDate;
            const millisecondsIn65Years = 65 * 365 * 24 * 60 * 60 * 1000;

            return timeDiff > millisecondsIn65Years;
        },
    }
}

</script>



<style scoped> :root {
     --colorByThemeQuarterOpacity: none;
 }
 /*
.ResponsiveBoxClass{
      padding: 10px;
} */
 :deep(.dx-daterangebox .dx-dropdowneditor-icon::before) {
     content: "\f026";

 }

 :deep(.dx-icon-to::before) {
     content: "\f00e";
 }

 h4 {
     margin-top: 0px;
     margin-left: 5px;
     margin-bottom: 5px;
 }

 .SmlouvaBox {
     padding: 5px;
 }

 .VlastnikBox {
     display: flex;
     justify-content: flex-start;

 }

 #obalenigrid {
     padding: 10px;
     margin-bottom: 10px;
 }

 .ResponsiveBoxDefaultCSS {
     border-style: ridge;
     border-color: rgba(128, 128, 128, 0.1);
     border-radius: 10px;
     background-color: rgba(128, 128, 128, 0.075);
     padding: 5px;
 }



 .checkboxzverejnitZvyraz {
     padding: 5px;
     border-style: ridge;
     border-color: rgba(46, 46, 46, 0.1);
     border-radius: 10px;
     background-color: rgba(170, 3, 3, 0.459);
     width: max-content;
     font-weight: bold;
 }

 #odhadkm {
     display: inline-flex;
     flex-direction: row !important;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: normal;
 }

 #labelkm {
     height: 100%;
     display: flex;
     align-items: center;
     flex-direction: column;
     justify-content: center;
     padding: 2px;
     padding-left: 5px;
 }

 h2 {
     /*padding-top: 0px !important;*/
     overflow: hidden
 }

 .spodnimargin {
     margin-bottom: 50px;
 }

 .btn {
     -webkit-border-radius: 20;
     -moz-border-radius: 20;
     border-radius: 20px;
     font-family: Arial;
     color: #ffffff;
     font-size: 16px;
     background: #34d939;
     padding: 10px 10px 10px 10px;
     text-decoration: none;
     height: 40px;
 }

 .btn:hover {
     background: #a3a3a3;
     text-decoration: none;
     cursor: pointer;
 }

 #potvrzeniInfoAuto {
     display: inline-flex !important;
     flex-direction: row !important;
 }

 #divPrintSouhlas {
     display: flex;
     justify-content: center;
 }

 hr.dotted-black {
     border-top: 1px dashed black;
 }

 .predposledniDiv {
     padding-bottom: 50px !important;
 }

 .buttonDefault:hover,
 .buttonUlozitProUpravy:hover,
 .buttonKeSchvaleni:hover,
 .buttonSmazat:hover,
 .buttonKEditaci:hover {
     background-color: var(--colorByThemeLighter);
     border: 0px;

 }

 .buttonKeSchvaleni {
     background: #5cb83c;
     border-color: none;
     border: 0px;
 }

 .buttonUlozitProUpravy {
     background: #3cb8b8;
     border-color: none;
     border: 0px;
 }

 .buttonSmazat {
     background: #d9534f;
     border-color: none;
     border: 0px;
 }

 .buttonKEditaci {
     background: #ffcc33;
     border-color: none;
     border: 0px;
 }

 .buttonDefault {
     background: none;
     border-color: none;
     border: 0px;
 }

 .SmlouvaBox h3 {
     display: initial;
 }

 @media (max-width: 600px) {
     .SmlouvaBox h3 {
         display: block;
     }
 }
 .PracCestaBoxWithWarning{
    display: flex;
     flex-direction: row !important;
 }
 </style>