<template>
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000697033?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000697033?lang=en" 
  PopisLinku='clanky.organizaceResitele'></HelpLink>
  <div>
    <div class="clNadpisAktualniStranky" height="50px">
      <h2 id="h2k" class=" prevent-select nadpisH2Moduly">{{ $t('organizace.OrganizacniStruktura') }}</h2>
    </div>
    
    <div id="h2vrsek">
      <DxButton styling-mode="outlined" type="default" icon="expand" :text="$t('organizace-RozbalitVse')"
        @click="expandAllNodes" id="rozbalitvse" />
      <DxButton styling-mode="outlined" type="default" icon="collapse" :text="$t('organizace-SbalitVse')"
        @click="collapseAllNodes" id="sbalitvse" />
      <DxButton v-if="NastaveniTymuVisible" styling-mode="outlined" type="default" icon="runner" :text="$t('organizace-ResitelskeSkupiny')"
        @click=" stavResitelskeSkupiny=true;" id="tlacitka" />
        <DxButton v-if="InvKomiseVisible" styling-mode="outlined" type="default" icon="folder" :text="$t('organizace.InventarniKomise')"
        @click=" InvKomiseClick()" id="tlacitka" />
    </div>
    <hr class="hrModuly">
    <div class="bordersDataGridPozadavkyModuly">
      <div id="container">
        <div id="inner" class="margin10left">

          <div class="child">
            <DxButton :ref="MojeButtonRef" :text="$t('organizace-Moje')" type="default" @click="ListOrganization(1);" />
          </div>
          <div class="child">
            <DxButton :ref="CelaButtonRef" :text="$t('organizace-Cela')" type="default" @click="ListOrganization(2)" />
          </div>

        </div>
      </div>
      <DxTreeList ref="treeListRef" id="organizace" :data-source="dataSource" :show-row-lines="true"
        :hoverStateEnabled="true" :rowAlternationEnabled="true" :show-column-lines="true" :show-borders="true"
        @cellPrepared="CellSettings" :column-auto-width="true"  :allow-column-resizing="true"
        :column-min-width="50" @nodesInitialized="onNodesInitialized" :height="(vyskaZobrazitelna / 1.4)"
        :expanded-row-keys="expandedRowKeys" :selected-row-keys="selectedRowKeys" @toolbarPreparing="toolbarPreparing"
        @rowCollapsed="RowCollapsed" key-expr="unitid" parent-id-expr="parentunitid" @row-click="RowClick"
        :noDataText="$t('central-NenalezenyZadneZaznamy')" @row-expanding="onRowExpanding"
        @row-collapsing="onRowCollapsing" @initialized="TreeListInit" @optionChanged="onOptionChanged">
        <DxStateStoring :enabled="true" type="localStorage" storage-key="TreelistOrganizace" /> 
        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
        <DxColumn data-field="description" caption="" cell-template="item-type" />
        <template #item-type="{ data }">
          <img v-if="data.data.itemtype == 1" id="favoriteicon" class="imgOddeleni" src="@/assets/images/oddeleni.png"
            width="15" :title="$t('organizace-Oddeleni')" height="15">
          <img v-if="data.data.itemtype == 0" id="favoriteicon" src="@/assets/images/grant.png" width="15" height="15"
            :title="$t('organizace-Grant')">
          <img v-if="data.data.itemtype == 2" id="iconActiveUser" src="@/assets/images/locwork.gif" width="15" height="15"
            :title="$t('organizace-Pritomen')">
          <img v-if="data.data.itemtype == 3" id="favoriteicon" src="@/assets/images/lochome.gif" width="15" height="15"
            :title="$t('organizace-Nepritomen')">
          <span v-if="data.data.role == 2" class="dx-icon-user" id="vedouci" icon="user" :title="$t('organizace-Vedouci')"
            width="15" height="15" ></span>
          <span v-if="data.data.role == 1" class="dx-icon-user" id="zastupce" icon="user"
            :title="$t('organizace-Zastupce')" width="15" height="15"></span>

          <span v-if="((data.data.itemtype == 3) || (data.data.itemtype == 2))">
            {{ giveBackFormatedUserInfoSTART(data.data.description) }} <span> <!-- {{ giveBackFormatedTitles(data.data.titles) }} -->
            </span>{{ giveBackFormatedUserInfoEND(data.data.description) }}
          </span>

          <span v-else>{{ data.data.description }}</span>

          <!--    <img v-if="data.data.itemtype==2" id="favoriteicon" src="@/assets/images/logosede.png" width="25" height="25"> -->
          <!-- <DxButton :disabled="true" v-if="data.data.itemtype==2" icon="user" width="35" height="35" />  -->
        </template>
        <DxColumn data-field="datefrom" :caption="$t('organizace-PlatnostOd')" width="120px" />
        <DxColumn data-field="dateto" :caption="$t('organizace-PlatnostDo')" width="120px" />
        <DxColumn data-field="employeeid" :visible="false" />
        <DxColumn data-field="parentunitid" :visible="false" />
        <DxColumn data-field="unitid" :visible="false" />
        <DxColumn data-field="role" :visible="false" sort-order="desc" />
        <DxSearchPanel :visible="true" :width="300" :placeholder="$t('central-Hledat')" :highlightCaseSensitive="true"
          :highlightSearchText="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumnChooser :enabled="false" />
      </DxTreeList>
    </div>
    
    <DxPopup  id="PopupInventarniKomise" v-model:visible="InventarniKomiseVisible" :show-title="true" :drag-enabled="false"
      :hide-on-outside-click="true" :show-close-button="true" :title="$t('organizace.NastaveniInventarniKomise')"
      icon="user" width="420px" :focusStateEnabled="true" :shading="true" height="auto"
      @Hiding="InventarniKomiseVisible = false;" @showing="this.computeHeightposDivClient($event);">
      
      <DxScrollView direction="vertical" showScrollbar="onHover" >
        <form  action="your-action" @submit="onFormSubmit" id="formLideResitele">
          <br>
          <div id="posDivHorniCastA">
            <div class="inlineflexSpaceBetween" @mouseover="getTitle(Oddeleni,0,selectedOddeleni)"  v-bind:title="getTitle(Oddeleni,0,selectedOddeleni)" >
              <DxSelectBox ref="selectbox5" :id="5" width="80%" height="45px" :label="$t('organizace-Oddeleni')"
                :data-source="Oddeleni" display-expr="content" value-expr="id" placeholder="" @opened="showWarningIcon()"
                 item-template="itemWithIcon" :value="selectedOddeleni" @value-changed="setSelectedValue" class="margin_0_10p" :drop-down-options="selectBoxAttributes" >
                <template #itemWithIcon="{ data }">
                  <div class="custom-item">
                    <span>{{ data.content }}</span>
                    <WarningImage v-if="data.ShowIcon" :PopisChybyKEY="$t('organizace.NekompletniKomise')" Position="absolute"  ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="20px" WidthIcon="20px"/>
                  </div>
                </template>
                <DxValidator>
                  <DxRequiredRule v-if="InventarniKomiseVisible"  :message="$t('organizace-SkupinaMusiBytVyplnena')" />
                </DxValidator>
              </DxSelectBox>
              
            </div>
            <hr class="brSolid">
          </div>
          <DxScrollView direction="vertical" showScrollbar="onHover" :height="180">
          <div style="margin-left: 40px" id="posDivClientA" :height="this.HeightposDivClient" >
            <table >
              <tr>
                <td></td>
                <td><b><label>{{ $t('organizace.Zamestnanec') }}</label></b></td>
                <td>
                  <b>
                    <span id="spanPocet" :style="InventarniKomise.length === 3 ? 'color:  rgb(0, 128, 0); white-space: nowrap;' : 'color: red; white-space: nowrap;'">
                      {{ InventarniKomise.length + ' / ' + 3 }}
                    </span>
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                
                <td>
                <hr class="brDotted">
                </td>
              </tr>
              <tr class="soubory" v-for="item in InventarniKomise" :key="item">
                <td >
                  <DxButton id="close" icon="remove" text=""
                    @click="RemoveInventoryCommissionMember(item.id)" :hint="$t('organizace.Odebrat')+String.fromCharCode(160)+item.content+String.fromCharCode(160)+$t('organizace.ZInvKomise')"/>
                </td>
                <td><label id={{item.id}} v-bind:title="item.content">{{item.detail+'. '+ item.content }}</label></td>
              </tr>

            </table>
          </div>
        </DxScrollView>
        <div id="posDivSpodniCastA" >
          <div id="inner">
            <hr style="margin-bottom: 15px" class="brSolid" v-show="InventarniKomiseVisible">
                        <DxSelectBox style="margin-left: 35px; margin-bottom: 10px;" :id="3" height="45px" width="85%" :label="$t('organizace-Zamestnanec')"
                          :data-source="zamestnanci" v-model="zamestnanec" display-expr="content" placeholder=""
                          @value-changed="setSelectedValue($event)" :search-enabled="true" @input="SearchZam" @opened="ClearValue"
                          noDataText="" :drop-down-options="selectBoxAttributes">
                          <DxValidator>
                            <DxRequiredRule :message="$t('organizace-ZamestnanecMusiBytVyplnen')"/>
                          </DxValidator>
                        </DxSelectBox>
              </div>
                  <div>
                      <DxButton style="margin-left: 35px; margin-bottom: 15px;" class="button2" id="addToInventoryCommission" height="auto" width="85%" type="success" icon="add"
                        styling-mode="contained" :text="$t('organizace.PridatDoKomise')" :use-submit-behavior="true" />
                  </div>
        </div>
        </form>
      </DxScrollView>
    
    </DxPopup>
    <DxPopup id="PopupResitelskeTymy" v-model:visible="stavResitelskeSkupiny" :show-title="true" :drag-enabled="false" :resize-enabled="false"
      :hide-on-outside-click="true" :show-close-button="true" :title="$t('organizace-NastaveniResitelskychTymu')"
      icon="user" width="auto" :focusStateEnabled="true" :shading="true" titleTemplate="title"
      @Hiding="stavResitelskeSkupiny = false;stateCopyResTymy=false; " @showing="this.computeHeightposDivClient($event); this.IDZaznamresitelskeTymy=0; ">
      <DxScrollView direction="vertical" showScrollbar="onHover">
        <form action="your-action" @submit="onFormSubmit" id="formLideResitele">
          <div  id="posDivHorniCastA">
            <br>
            <div class="inlineflexCentered">
              <span style="margin-left: 1%;margin-right:-1%" class="w35px" v-if="this.stateCopyResTymy">
                {{$t('organizace.Z')}}
              </span>
              <div class="clParentDivWidthMax clPadRight5"  @mouseover="getTitle(resitelskeTymy,selectedResitelskeTymyGrant,0)"  v-bind:title="getTitle(resitelskeTymy,selectedResitelskeTymyGrant,0)">
              <DxSelectBox  ref="selectbox2" :id="2" width="100%" height="45px" :label="$t('central-Grant')"
                :data-source="resitelskeTymy" display-expr="content" placeholder="" :search-enabled="true" v-model="selectedResitelskeTymyGrant"
                :value="resitelskeTymy[IDZaznamresitelskeTymy]" @value-changed="setSelectedValue" class="margin_selectbox" :drop-down-options="selectBoxAttributes">
                <!-- @customItemCreating="customItemCreating($event,1)"-->
                <DxValidator>
                  <DxRequiredRule :message="$t('organizace-SkupinaMusiBytVyplnena')" />
                </DxValidator>
              </DxSelectBox>
              </div>
              <div class="inlineflexSpaceBetween">
                <DxButton icon="edit" type="default" style="margin-right: 5%" :hint='computedTextEditGrantDescription' @click="FundingNameEditing"/>
                <DxButton icon="copy" type="default" class="buttonHover" :hint='computedTextCopyEmpFromGrantToGrant' @click="this.stateCopyResTymy=!this.stateCopyResTymy; this.EditGrantDescription=false "/>
              </div>
            </div>
            <div class="inlineflexSpaceBetween" v-if="EditGrantDescription" style="margin-top: 19px !important" >
              <span style="margin-left: 1%; margin-right:-1%; width: auto"  >
                {{ Cgrantu }}
              </span>
              <DxTextBox
                id="textbox"
                width="70%"
                height="45px"
                class="margin_selectbox"
                :value="GrantName"
                :label="$t('organizace.PopisGrantu')"
                :placeholder="$t('organizace.VyplntePopis')"
                :maxLength="60"
                @value-changed="setSelectedValue"
              />
            <DxButton id="potvrditKopirovaniGrantu" type="default" icon="check" :hint='$t("organizace.ZmenaPopisuGrantu")' @click="ChangeFundingPersonalName"/>
          </div>
            <span style="margin-left: 1%" class="w35px" v-if="this.stateCopyResTymy">
              ↓
            </span>
            <div class="inlineflexSpaceBetween" v-if="this.stateCopyResTymy">
            <span class="w35px" v-if="this.stateCopyResTymy">
             {{$t('organizace.Do')}}
            </span>
            <DxSelectBox ref="selectbox3" :id="4" width="90%" height="45px" :label="$t('central-Grant')"
                :data-source="resitelskeTymy" display-expr="content" placeholder="" :accept-custom-value="true"
                 @value-changed="setSelectedValue"  class="margin_selectbox" :drop-down-options="selectBoxAttributes">
                <!-- @customItemCreating="customItemCreating($event,1)"-->
                <DxValidator>
                  <DxRequiredRule :message="$t('organizace-SkupinaMusiBytVyplnena')" />
                </DxValidator>
              </DxSelectBox>
              <DxButton id="potvrditKopirovaniGrantu" type="default" icon="check" :hint='$t("organizace.NakopirovatZDoVybranehoGrantu")' @click="this.CopyFromGrantAToGrantB()"/>
              
            </div>
            <br>
            <hr class="brSolid">
          </div>
          <DxScrollView direction="vertical" showScrollbar="onHover" >
          <div id="posDivClientA" :height="this.HeightposDivClient" >
            <table >
              <tr class="soubory">
                <td>
                 
                </td>
                <td><label id={{item.id}}>{{ $t('organizace.Zamestnanec') }}</label></td>
                <td class="textCenter">{{ $t('organizace-O') }}
                    </td>
                <td class="textCenter">{{ $t('organizace-P') }}
                    </td>
              </tr>
              <tr>
                <td>
                <hr class="brDotted">
                </td>
                <td>
                <hr class="brDotted">
                </td>
                <td>
                <hr class="brDotted">
                </td>
                <td>
                <hr class="brDotted">
                </td>
              </tr>
              <tr class="soubory" v-for="item in resitelskyTymLide" :key="item">
                <td >
                  <DxButton :disabled="!this.CanApprove" id="close" icon="remove" text=""
                    @click="RemovePersonFromGroup(item.id, item.Opermission, item.Ppermission)" :hint="$t('organizace.Odebrat')+String.fromCharCode(160)+item.content+String.fromCharCode(160)+$t('organizace.ZTymu')"/>
                </td>
                <td><label id={{item.id}} v-bind:title="item.content">{{ item.content }}</label></td>
                <td width="100px" >
                    <div class="tdObjednavat"><DxCheckBox :disabled="!this.CanOrder" id="objednavat" v-model:value="item.Opermission"
                      @value-changed="SetFundingPermission(item.id, item.Opermission, item.Ppermission, $event)"
                      height="35px" />
                    </div>
                  </td>
                <td width="100px">
                  <div class="tdProhlizet">
                    <DxCheckBox :disabled="!this.CanOrder" id="prohlizet" v-model:value="item.Ppermission"
                      @value-changed="SetFundingPermission(item.id, item.Opermission, item.Ppermission, $event)"
                      height="35px" />
                    </div>
                  </td>
              </tr>

            </table>
          </div>
        </DxScrollView>
        <div id="posDivSpodniCastA" v-if="!this.stateCopyResTymy" >
          <div id="inner" v-if="this.CanApprove">
            <hr class="brSolid">
              <br>
                <div class="margin_0_5p inlineflexSpaceBetween ">
                      <div class="child">
                        <DxSelectBox :id="3" height="45px" width='100%' :label="$t('organizace-Zamestnanec')"
                          :data-source="zamestnanci" v-model="zamestnanec" display-expr="content" placeholder=""
                          @value-changed="setSelectedValue($event)" :search-enabled="true" @input="SearchZam" @opened="ClearValue"
                          noDataText="" :drop-down-options="selectBoxAttributes">
                          <DxValidator>
                            <DxRequiredRule :message="$t('organizace-ZamestnanecMusiBytVyplnen')"/>
                          </DxValidator>
                        </DxSelectBox>
                      </div>
                      <div>
                        <DxButton class="button1" id="addToGroup" height="auto" width="auto" type="success" icon="add"
                          styling-mode="contained" :text="$t('organizace-PridatDoSkupiny')" :use-submit-behavior="true" />
                      </div>
                      
                    </div>
                    <br>
                  </div>
                  <PrintVariants Report="PIN" v-if="false" :PrintID="2261" :Hint="$t('granty-KartaVydejky')" />
        </div>
        </form>
      </DxScrollView>
      


    </DxPopup>

  </div>
</template>

<script>


import DataSource from 'devextreme/data/data_source';
import {
  DxStateStoring, DxLoadPanel
} from 'devextreme-vue/data-grid';
import WarningImage from '@/components/WarningImage.vue';
import { isUndefined } from 'lodash';
import notify from 'devextreme/ui/notify';
import DxScrollView from 'devextreme-vue/scroll-view';
const MojeButtonRef = 'my-buttonMoje';
const CelaButtonRef = 'my-buttonCela';
import PrintVariants from '@/components/PrintVariants.vue';
import HelpLink from '@/components/HelpLink.vue';
//import axios from 'axios'
//import notify from 'devextreme/ui/notify';
const dataSource = new DataSource({
  // ...
});
export default {
  name: "OrganizaceView",
  data() {
    return {
      currentGrant: null,
      Cgrantu: '',  
      GrantName: '',
      EditGrantDescription: false,
      InvKomiseVisible: false,
      NastaveniTymuVisible: false,
      InventarniKomiseVisible: false,
      MojeButtonRef,
      CelaButtonRef,
      zamestnanec: [],
      CanApprove: false,
      CanOrder: false,
      stavResitelskeSkupiny: false,
      expandedRowKeys: [],
      selectedRowKeys: [],
      dataSource,
      zobrazeni: 1,
      Phones: [],
      resitelskeTymy: [],
      resitelskyTym: [],
      resitelskyTymLide: [],
      Oddeleni: [],
      selectedOddeleni: null,
      zamestnanci: [],
      Timer: undefined,
      vyskaZobrazitelna: 250,
      expand: false,
      HeightposDivClient:'100%',
      eventComponentPopup:undefined,
      stateCopyResTymy:false,
      IDZaznamresitelskeTymy:0,
      InventarniKomise:[],
      selectBoxAttributes: { 
                minWidth:600,
                minHeight:0    
            },
      selectedResitelskeTymyGrant:null
    };
  },
  components: {
    DxStateStoring,
    DxScrollView, PrintVariants, DxLoadPanel,HelpLink,
    WarningImage
  },
  computed: {
    ButtonMoje: function () {
      return this.$refs[MojeButtonRef].instance;
    },
    ButtonCela: function () {
      return this.$refs[CelaButtonRef].instance;
    },
    treeList: function () {
      return this.$refs.treeListRef.instance;
    },
    computedTextCopyEmpFromGrantToGrant: function () {
      if (this.stateCopyResTymy) {
        return this.$t('organizace.ZavritKopirovani') 
      } else {
        return this.$t('organizace.OtevritKopirovani')
      }
    },
    computedTextEditGrantDescription: function () {
      if (this.EditGrantDescription) {
        return this.$t('organizace.ZavritEditaciPopisuGrantu') 
      } else {
        return this.$t('organizace.OtevritEditaciPopisuGrantu')
      }
    },
     
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.DrawerSelected()
   // window.localStorage.setItem("refresh-page", "Organizace");
   this.ListGroups();
   setTimeout(() => {
    this.ListOrganization(this.zobrazeni);
   }, 200);
  
    //this.ListFundingPermission(); 
    this.Funding();
    setTimeout(() => {
      this.$nextTick(() => {
        this.myEventHandler();
        this.sortItems();
      });
    }, 1000);
    setTimeout(() => {
      if(window.thisNavigation.WarningKomise && !this.InventarniKomiseVisible ) this.InventarniKomiseVisible=true
    }, 500);
    
  },
  methods: {
      onOptionChanged(e) {
      //  this.$log.debug(e)
        
      if (e.name === "searchValue" && e.value) {
        var searchValue=e.value
         // Zkontrolovat, jestli existuje vyhledávací text
      // Najít všechny uzly, které odpovídají vyhledávacímu kritériu
      const searchedNodes = this.$refs.treeListRef.instance.getDataSource().items().filter(node => {
        return node.description.includes(searchValue); // Vyhledávání na základě popisu uzlu
      });

      // Rozbalit všechny nalezené uzly a jejich podřízené
      searchedNodes.forEach(node => {
        this.$refs.treeListRef.instance.expandRow(node.unitid);
      });
    }
  },
    getTitle(datasource, item, itemid) {
      this.$log.debug('getTitle(item)');
      this.$log.debug('item', item, 'datasource', datasource, 'itemid',itemid);
      var ItemContent = item;
      var LookupArray = datasource;
      this.$log.debug('LookupArray', LookupArray, 'ItemContent', ItemContent);
      if ((Array.isArray(LookupArray)) && ((ItemContent > '') || (itemid>0))) {
        var foundItem
        if (itemid == 0) {
          this.$log.debug('ITEM NALEZEN', LookupArray.find(data => data.id == ItemContent.id))
          foundItem = LookupArray.find(data => data.id == ItemContent.id)
        }
        else {
          this.$log.debug('ITEM NALEZEN', LookupArray.find(data => data.id == itemid))
          foundItem = LookupArray.find(data => data.id == itemid)}
          return foundItem ? foundItem.content : undefined;
        
        }else {
        this.$log.debug('LookupArray.length', LookupArray.length)
        return ''
      }
    }, 
    showWarningIcon() {
      this.Oddeleni.forEach(item => {
        item.ShowIcon = window.gid && window.gid.includes(item.id);
      });
      this.$log.debug(this.Oddeleni, 'showwarningicon')
    //  this.$refs.selectbox5.instance.repaint()
    },
    InvKomiseClick(){
      this.InventarniKomiseVisible=true; 
      if(window.gid.length!=0) this.selectedOddeleni=window.gid[0]
    },
    FundingNameEditing(){
      this.EditGrantDescription=!this.EditGrantDescription; 
      this.stateCopyResTymy=false
    },
    ChangeFundingPersonalName(){
      var self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.SetPersonalFundingName(self.GrantName,self.currentGrant, function (result) {
        self.$log.debug(result)
        if (result.ErrorNum.value != 0) {
          notify({
            message: result.ErrorTextCZ.value,
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
        }
        else {
          notify({
            message: self.$t('organizace.VlastniNazevZmenen'),
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            self.Funding()

          
        }
      }, window.this.ApiError());
    },
    TreeListInit(e) {
            this.$log.debug(e)
            setTimeout(() => {
              e.component.option('searchPanel.text', '')
            }, 200);
        },
    sortItems() {
      this.$log.debug('sortItems');
      this.$log.debug(this.$refs.treeListRef.instance);
      this.$refs.treeListRef.instance.columnOption('role', 'visible', true);
      this.$refs.treeListRef.instance.columnOption('role', {
    sortOrder: 'desc',
    sortIndex: 0
  });
      this.$refs.treeListRef.instance.columnOption('role', 'visible', false);
    },
    ClearValue(e) {
      if (e.component.option("text") != '')
        e.component.reset()
    },
    myEventHandler(e) {
      this.$log.debug(e);
      this.handleMainViewChange()
    },
    handleMainViewChange() {
      //  console.log("handleMainViewChange");
      let self = this;
      var element = document.getElementById("ScrollViewAPP");
      if (element) {
        //       console.log(element);
        var height = element.clientHeight;
        if ((height < 500) && (height > 300)) { height = 550 }
        if (height <= 300) { height = 450 }
        self.vyskaZobrazitelna = height;
        // console.log("handleMainViewChange height:", height);
      }
      if (this.eventComponentPopup!=undefined){
        try {
          var UpPart,DownPart,ParentPart,VyskaHlavickaPopup;
          UpPart=document.getElementById('posDivHorniCastA').clientHeight;
          DownPart=document.getElementById('posDivSpodniCastA').clientHeight;
          VyskaHlavickaPopup = 80;
          ParentPart=this.eventComponentPopup.component._renderedDimensions.height-VyskaHlavickaPopup;
          this.HeightposDivClient=(ParentPart-(UpPart+DownPart));
        } catch (error) {
          this.$log.debug('Error: ',  error.message)
          this.HeightposDivClient='100%';
    }


      }

    },
    onFormSubmit(e) {
      var self = this
      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
      self.$log.debug(self.resitelskyTym.id);

      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "addToGroup") {
        self.AddPersonToGroup();
      }
      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "addToInventoryCommission") {
        self.SetInventoryCommissionMember();
        
      }
      e.preventDefault();
    },
    SetInventoryCommissionMember(){
      var self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.SetInventoryCommissionMember(self.selectedOddeleni,self.zamestnanec.id, function (result) {
        self.$log.debug(result)
        if (result.ErrorNum.value != 0) {
          notify({
            message: result.ErrorTextCZ.value,
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
        }
        else {
          notify({
            message: result.ErrorTextCZ.value,
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          self.ListInventoryCommission()
          window.thisNavigation.ValidateInventoryCommission()
          
        }
      }, window.this.ApiError());
    },
    AddPersonToGroup() {
      var self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.SetFundingPermission(self.resitelskyTym.id, self.zamestnanec.id, true, false, false, function (result) {
        self.$log.debug(result)
        if (result.ErrorNum.value != 0) {
          notify({
            message: result.ErrorTextCZ.value,
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          self.ListFundingPermission()
        }
        else {
          self.ListFundingPermission()
        }
      }, window.this.ApiError());
    },
    Funding() {
      
      let self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
    //  self.resitelskeTymy.length=0
      wf.ListFunding(1, 4, function (result) {
        window.this.$log.debug(result);
        window.this.$log.debug("FUNDING");
        for (var x = 0; x < result.List.value.items.length; x++) {
          if(x==0) self.NastaveniTymuVisible=true
          //  window.this.$log.debug(result.List.value.items[x]);
          self.resitelskeTymy[x] = {
            content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
            //  number: result.List.value.items[x].Text.value,
            cgrantu:result.List.value.items[x].Text.value,
            id: result.List.value.items[x].ID.value,
            canapprove: result.List.value.items[x].CanApprove.value,
            canorder: result.List.value.items[x].CanOrder.value,
            x: x,


          };
        }
        self.selectedResitelskeTymyGrant=self.resitelskeTymy[self.IDZaznamresitelskeTymy];
 /*       self.GrantName=self.resitelskeTymy[0].content
        self.Cgrantu=self.resitelskeTymy[0].cgrantu
        if (self.GrantName.startsWith(self.Cgrantu)) {
          self.GrantName=self.GrantName.substring(self.Cgrantu.length+1);
      }
      */
      self.$refs.selectbox2.instance.getDataSource().reload(); 
      }, window.this.ApiError());
      window.this.$log.debug(self.resitelskeTymy);
      window.this.$log.debug("FUNDING RESULT");

    },
    ListGroups() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      self.Oddeleni.length=0;
      const zamid = JSON.parse(window.localStorage.getItem('user')).EmployeeID.value;
      gen.ListLookup('GROUPLEADER:'+zamid, '', 1, 25,
        function (result) {
            window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.Oddeleni.length;
            self.Oddeleni[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              detail: result.List.value.items[x].Detail.value,
            };
          }
          if(self.Oddeleni.length>0){
            self.InvKomiseVisible=true      
            if(window.gid.length!=0) self.selectedOddeleni=window.gid[0]
            else self.selectedOddeleni=self.Oddeleni[0].id; 
           // self.ListInventoryCommission();
          }
        }, window.this.ApiError());

    },
    ListInventoryCommission() {
      let self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      self.InventarniKomise.length=0;
      wf.GetInventoryCommission(self.selectedOddeleni,
        function (result) {
            window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            var i = self.InventarniKomise.length;
            self.InventarniKomise[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              detail: result.List.value.items[x].Detail.value,
            };
          }
          /*
          if (self.InventarniKomise.length != 3) {
            window.thisNavigation.WarningKomise=true
            //self.$forceUpdate();
          } else window.thisNavigation.WarningKomise=false
          */  
        }, window.this.ApiError());

    },

    RemovePersonFromGroup(zamid, objednavat, prohlizet) {
      window.this.$log.debug(zamid)
      var self = this
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.SetFundingPermission(self.resitelskyTym.id, zamid, objednavat, prohlizet, true,
        function (result) {
          self.$log.debug(result);
          self.ListFundingPermission()
        }, window.this.ApiError());

    },
    RemoveInventoryCommissionMember(zamid) {
      window.this.$log.debug(zamid)
      var self = this
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.RemoveInventoryCommissionMember(self.selectedOddeleni, zamid,
        function (result) {
          self.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 5000)
          }
          else {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'success', 5000)
          self.ListInventoryCommission()
          window.thisNavigation.ValidateInventoryCommission()
          
          }
        }, window.this.ApiError());

    },
    SearchZam(e) {
      let self = this;
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        self.GetEmployees(e)
      }, 1000)

    },
    ListFundingPermission() {
      let self = this;
      this.$log.debug(this.resitelskyTym.id);
      this.$log.debug('Resitelsky tym');
      self.resitelskyTymLide.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      this.$log.debug(wf);
      wf.ListFundingPermission(this.resitelskyTym.id,
        function (result) {
          window.this.$log.debug(result)
          window.this.$log.debug('Resitelsky tym result')
          var Opermission, Ppermission
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            switch (result.List.value.items[x].Detail.value) {
              case '0': case 0:
                Opermission = false
                Ppermission = false
                break;
              case '1': case 1:
                Opermission = true
                Ppermission = false
                break;
              case '2': case 2:
                Opermission = false
                Ppermission = true
                break;
              case '3': case 3:
                Opermission = true
                Ppermission = true
                break;
            }
            self.resitelskyTymLide[x] = {
              content: result.List.value.items[x].Value.value,
              Opermission: Opermission,
              Ppermission: Ppermission,
              //  number: result.List.value.items[x].Text.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          self.$log.debug(self.resitelskyTymLide)
        }, window.this.ApiError());



    },
    SetFundingPermission(zamid, objednavat, prohlizet, e) {
      let self = this;
      this.$log.debug(e)
      this.$log.debug(this.resitelskyTym.id);
      this.$log.debug('Resitelsky tym');
      var wf = new window.WorkFlow(window.channel, window.message);
      this.$log.debug(wf);
      wf.SetFundingPermission(this.resitelskyTym.id, zamid, objednavat, prohlizet, false,
        function (result) {
          window.this.$log.debug(result)
          if (result.ErrorNum.value != 0) {
            self.$log.debug('SetFundingPermission CHYBA API ROZDILNE OD 0')

            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000)

            //   e.component.option('value',!objednavat)
            self.ListFundingPermission() //'info' - barva templatu, 'success', 'warning', 'error'

          }
          else {
            self.$log.debug('SetFundingPermission VSE OK API ROVNO 0')

            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'success', 5000)
            //self.ListFundingPermission() //'info' - barva templatu, 'success', 'warning', 'error'

          }


        }, window.this.ApiError());



    },
    setSelectedValue(e) {
      window.this.$log.debug(e)
      switch (e.element.id) {
        case '2':
          var self=this
          this.resitelskyTym = e.value
          this.CanApprove = e.value.canapprove
          this.CanOrder = e.value.canorder
          this.selectedFROMGrant = e.value.id;
          this.GrantName=e.value.content
          this.Cgrantu=e.value.cgrantu
          this.currentGrant=e.value.id
          this.IDZaznamresitelskeTymy=e.value.x
          if (self.GrantName.startsWith(self.Cgrantu)) {
          self.GrantName=self.GrantName.substring(self.Cgrantu.length+1);
         }
          window.this.$log.debug('this.selectedFROMGrant',this.selectedFROMGrant)
          window.this.$log.debug(e.value.id)
          window.this.$log.debug("setSelectedValue E VALUE")
          this.ListFundingPermission()
          break;
        case '3':
          this.zamestnanec = e.value;
          break;
        case '4':
          this.selectedTOGrant = e.value.id;
          window.this.$log.debug('this.selectedENDGrant',this.selectedTOGrant)
          break;
          case '5':
          this.selectedOddeleni = e.value;
          this.ListInventoryCommission();
          window.this.$log.debug('this.selectedoddleni',this.selectedOddeleni)
          break;
          case 'textbox':
          this.GrantName=e.value
          break;
      }
    },
    GetEmployees(employee) {
      if (employee.component != undefined) {
        var e = employee
        employee = e.component.option("text")
      }
      var gen = new window.General(window.channel, window.message);
      let self = this;
      self.zamestnanci.length = 0;
      //  window.this.$log.debug(employee)
      gen.ListEmp(employee,
        function (result) {
          //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
          //    window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[self.zamestnanci.length] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
            if (x > 20) break;
          }
          e.component.getDataSource().reload();
        }, window.this.ApiError());
    },

    DrawerSelected() {
      var e = window.Drawer
      if (window.prevElement != undefined && window.prevPosition != undefined)
        window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
      this.$log.debug(window.Drawer)
      this.$log.debug(this.$route.meta.id)
      this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
      for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
        // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
        if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
          e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
          window.prevElement = e
          window.prevPosition = x
        }
      }
    },
    toolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(function (item) {
        if (item.name === "searchPanel") {
          item.location = 'before';
        }
        if (item.name === "groupPanel") {
          item.location = 'after';
        }
      })
    },
    onNodesInitialized: function (e) {
      e.component.__root = e.root;
    },
    getNodeKeys(node) {
      var self = this;
      var keys = [];
      keys.push(node.key);
      node.children.forEach(function (item) {
        keys = keys.concat(self.getNodeKeys(item));
      });
      return keys;
    },
    RowCollapsed() {
      // console.log(this.treeList.option("autoExpandAll"))
      this.treeList.option("autoExpandAll", false);
    },
    expandAllNodes() {
      // var self = this;
      // window.this.$log.debug(this.treeList);
      // window.this.$log.debug(this.treeList.root);
      if (!this.treeList.option("autoExpandAll")) this.treeList.option("expandedRowKeys", []);
      this.treeList.option("autoExpandAll", true);
      /*            var keys = this.getNodeKeys(this.treeList.__root);
               // window.this.$log.debug(keys);
                this.treeList.beginUpdate();
                keys.forEach(function (key) {
                    self.treeList.expandRow(key);
                });
                this.treeList.endUpdate();*/

    },
    collapseAllNodes() {
      //  var self = this;
      // window.this.$log.debug(this.treeList);
      // window.this.$log.debug(this.treeList.root);
      this.treeList.option("expandedRowKeys", []);
      this.treeList.option("autoExpandAll", false);
      //var keys = this.getNodeKeys(this.treeList.__root);
      // window.this.$log.debug(keys);
      /*            this.treeList.beginUpdate();
                keys.forEach(function (key) {
                    self.treeList.collapseRow(key);
                });
                this.treeList.endUpdate();
                */
    },
    CellSettings(e) {
      // window.this.$log.debug(e)
      var self = this
      if (e.columnIndex == 0 && e.rowType == "data") {
        if (e.row.data.itemtype < 2) {
          e.cellElement.style.fontWeight = "bold";
        }
        else {
          window.this.$log.debug(e)

          //  window.this.$log.debug(Zamid)
          e.cellElement.addEventListener('mouseover', function () {
            var Zamid = e.value.substring(
              e.value.indexOf("(") + 1,
              e.value.lastIndexOf(")")
            );

            clearTimeout(this.Timer)
            this.Timer = setTimeout(() => {
              self.GetPhoneList(Zamid)
              window.TelHint = e.cellElement.querySelector('.dx-treelist-text-content');
            }, 200)
            //self.GetPhoneList(Zamid)

            /*  if (headerTitle.scrollWidth > headerTitle.clientWidth) {
                headerTitle.setAttribute("title", "Minimální cena");
              }  */
          });
        }
      }
    },
    GetPhoneList(search) {
      this.Counter++;
      this.$log.debug(this.Counter);

      if (window.prevsearch != search) {
        var ph = new window.Phones(window.channel, window.message);
        let self = this;
        self.Phones.length = 0;
        ph.GetPhoneList_v2(search, 1, 30,
          function (result) {
            window.this.$log.debug(result)
            var hint = ''
            for (var x = 0; x < result.items.length; x++) {
              if (result.items[x].Phone.value == '') hint += 'Tel: <Prázdné>'
              else {
                var phone = result.items[x].Phone.value.replace(/[() ]/g, '');
                hint += 'Tel: ' + phone
              }
              hint += ' Místo: ' + result.items[x].Room.value + '\n'
            }
            window.TelHint.setAttribute("title", hint);
            window.prevsearch = search
          }, window.this.ApiError());
      }
      this.$log.debug(this.Counter);

    },
    onRowExpanding() {
      // Handler of the "rowExpanding" event
      // window.this.$log.debug('expanduju')
      // window.this.$log.debug(e)
      this.expand = false;
    },
    onRowCollapsing() {
      // Handler of the "rowCollapsing" event
      // window.this.$log.debug('collapsuju')
      // window.this.$log.debug(e)
      this.expand = false;
    },
    RowClick(e) {
      window.this.$log.debug(e);
      if (!isUndefined(e.node)) {
        if (e.node.hasChildren && this.expand) {
          if (e.isExpanded == false)
            e.component.expandRow(e.key);
          else
            e.component.collapseRow(e.key);
        }
        this.expand = true;
      }
    },
    giveBackFormatedTitles(inputString) {
      if (inputString.length <= 1) { return inputString }
      else { return ", " + inputString + " " }
    },
    giveBackFormatedUserInfoSTART(inputString) {
      const indexOfParenthesis = (inputString.indexOf('(')) - 1;
      if (indexOfParenthesis !== -1) {
        const result = inputString.substring(0, indexOfParenthesis);
        return result
      } else return inputString;
    },
    giveBackFormatedUserInfoEND(inputString) {

      const indexOfParenthesisFirst = inputString.indexOf('(');
      const indexOfParenthesisLast = inputString.indexOf(')');

      if ((indexOfParenthesisFirst !== -1) && (indexOfParenthesisLast !== -1)) {
        const result = inputString.substring(indexOfParenthesisFirst, indexOfParenthesisLast + 1);
        return result
      } else return inputString
    },
    ListOrganization(ListType) {
      this.$log.debug('listorganization')
      let self = this;
      if (ListType == 1) {
        this.ButtonMoje.option('stylingMode', 'contained')
        this.ButtonCela.option('stylingMode', 'outlined')
      }
      if (ListType == 2) {
        this.ButtonMoje.option('stylingMode', 'outlined')
        this.ButtonCela.option('stylingMode', 'contained')
      }
      this.zobrazeni = ListType;
      var gen = new window.General(window.channel, window.message);
      const store = dataSource.store();
      var datefrom, dateto;
      this.treeList.option("autoExpandAll", false);
      store.clear();
      gen.ListOrganization(ListType, function (result) {
        window.this.$log.debug("ListOrganization");
        window.this.$log.debug(result);
        for (var x = 0; x < result.List.value.items.length; x++) {
          //  window.this.$log.debug(result.List.value.items[x]);  
          datefrom = result.List.value.items[x].DateFrom.value;
          dateto = result.List.value.items[x].DateTo.value;
          // window.this.$log.debug(result.List.value.items[x].DateTo.value.getFullYear())
          if (result.List.value.items[x].DateFrom.value.getFullYear() == 1899) {
            datefrom = null;
            //  window.this.$log.debug(datefrom)
          }
          if (result.List.value.items[x].DateTo.value.getFullYear() == 1899) {
            dateto = null;
            // window.this.$log.debug(dateto)
          }
          store.insert({
            itemtype: result.List.value.items[x].ItemType.value,
            description: result.List.value.items[x].Description.value,
            datefrom: datefrom,
            dateto: dateto,
            employeeid: result.List.value.items[x].EmployeeID.value,
            parentunitid: result.List.value.items[x].ParentUnitID.value,
            unitid: result.List.value.items[x].UnitID.value,
            role: result.List.value.items[x].Role.value,
            titles: result.List.value.items[x].Titles.value,
          })
            .then(() => {          
            }, (error) => { window.this.$log.debug(error); /* ... */ });
        }
        dataSource.reload();
        self.$refs.treeListRef.instance.refresh();
       // self.$log.debug(dataSource)
        /*
        self.$nextTick(() => {
            dataSource.reload().then(() => {
                self.$log.debug("DataSource successfully reloaded");
                self.$refs.treeListRef.instance.getDataSource().reload();
            }).catch((error) => {
                window.this.$log.debug("Error reloading dataSource: ", error);
            });
        });
        */

        /*
        self.$log.debug(dataSource)
        self.$log.debug(self.dataSource)
       // self.$refs.treeListRef.instance.getDataSource().reload();  
       */
      }, window.this.ApiError());
    },
    computeHeightposDivClient(e){
      try {
      var UpPart,DownPart,ParentPart,VyskaHlavickaPopup;
      UpPart=document.getElementById('posDivHorniCastA').clientHeight;
      DownPart=document.getElementById('posDivSpodniCastA').clientHeight;
      VyskaHlavickaPopup = 80;
      ParentPart=e.component._renderedDimensions.height-VyskaHlavickaPopup;
      this.eventComponentPopup=e;
      this.$log.debug('H posDivHorniCastA',UpPart)
      this.$log.debug('H posDivSpodniCastA',DownPart)
      this.$log.debug('H event',e.component._renderedDimensions.height)
      this.$log.debug('PRED HeightposDivClient',this.HeightposDivClient)
      this.HeightposDivClient=(ParentPart-(UpPart+DownPart));
      this.$log.debug('PO HeightposDivClient',this.HeightposDivClient)
    } catch (error) {
      this.$log.debug('Error: ',  error.message)
      this.HeightposDivClient='100%'
      this.$log.debug('100P HeightposDivClient',this.HeightposDivClient)
    }



    },
    CopyFromGrantAToGrantB(){
      let self = this;
      self.$log.debug('Z grantu ',self.selectedFROMGrant,' >>>>>>> ', self.selectedTOGrant)
      if (self.selectedFROMGrant!=null && self.selectedFROMGrant!=undefined && self.selectedTOGrant!=null && self.selectedTOGrant!=undefined) {
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.CopyEmployeesFromGrantToGrant(self.selectedFROMGrant, self.selectedTOGrant, function (result) {
        self.$log.debug(result)
        if (result.ErrorNum.value != 0) {
          notify({
            message: result.ErrorTextCZ.value,
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          
        } else {
          notify({
            message: self.$t('organizace.UspesnePrevedeniDoTymu'),
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'success', 2500);
          var foundindex;
          for (var i = 0; i <  self.resitelskeTymy.length; i++) {
              if (self.resitelskeTymy[i].id == self.selectedTOGrant) {
                foundindex = i;
                //self.$log.debug('foundindex ',foundindex)
                //self.$log.debug('self.resitelskeTymy[i].id ',self.resitelskeTymy[i].id,)
                  break;
              }
          }
          self.$log.debug('INDEX in RESITELSKE TYMY',foundindex)
          self.ListFundingPermission();
          self.stateCopyResTymy=false;
          if (foundindex !== -1) {
            self.IDZaznamresitelskeTymy=foundindex;
          } else {
            self.IDZaznamresitelskeTymy=0;
          }
        }
      }, window.this.ApiError());
      
    } else {
      notify({
            message: self.$t('organizace.ChybaKopirovani'),
            position: {
              my: 'center top',
              at: 'center top',

            },
          }, 'error', 5000); 
      }
    },
  },

}
</script>

<style scoped>
.custom-item {
  display: flex;
  align-items: center;
}
#spanPocet{
  margin-left: 50px;
}
.dx-icon-user {
  margin-top: 5px;
  font: 10px/1 DXIcons;
}

#vedouci {
  background-color: rgb(0, 128, 0);
  color: rgb(255, 255, 255);
  margin-top: 10px;
  border: 1px #666666 solid;
  border-radius: 50%;
}

#zastupce {
  background-color: rgb(255, 255, 255);
  color: grey;
  margin-top: 10px;
  border: 1px #666666 solid;
  border-radius: 50%;
}

#organizace .dx-treelist-rowsview td {
  vertical-align: middle;
}

#oMoje {
  order: 4;
  border-bottom: 2px solid rgba(0, 32, 91, 1);
  text-align: left;
  flex-grow: 0;
  margin-bottom: 10px;
}

#oCela {
  order: 5;
  text-align: left;
  flex-grow: 0;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(0, 32, 91, 1);
  border-style: ridge;
}

#rozbalitvse {
  order: 2;
  text-align: left;
  flex-grow: 0;
  margin-bottom: 10px;
}

#sbalitvse {
  order: 3;
  text-align: left;
  flex-grow: 0;
  margin-bottom: 10px;
  border-style: ridge;
}
#tlacitka{
  order: 3;
  text-align: left;
  flex-grow: 0;
  margin-bottom: 10px;
  border-style: ridge;
}
#h2k {
  width: 100%;
  text-align: center;
  /*background-color: aquamarine;*/
  padding: 0px;
  order: 1;
}

#h2vrsek {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px 0px 0px;
  margin-left: 20px;
  width: 100%;
}

[notActive="true"] {
  opacity: 0.25
}

#iconActiveUser {
  padding-right: 5px;
}

.imgOddeleni,
#vedouci,
#zastupce {
  margin-right: 2px;
}
.margin_selectbox{
  margin-right: 5%;
}
.margin_0_10p{
  margin: 0 10%;
}
.margin_0_5p{
  margin:0 5%;
}
.brSolid{
  border: 1.5px black solid;
  border-style: ridge;
}
.brDotted{
  border: 1px black solid;
  border-style: dashed;
}
#posDivClientA, #formLideResitele{
  margin-right:10px
}
.tdObjednavat,.tdProhlizet{
  width: '100px';
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.textCenter{
  text-align: center;
}
.inlineflexSpaceBetween{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inlineflexCentered{
  display: flex;
  justify-content: center;
}
.w35px{
  width: 25px;
}
table tr:nth-child(1), table tr:nth-child(2) {
  background-color: transparent !important;
}
table tr:nth-child(odd):nth-child(n+3) td:nth-child(n+2){
  background-color: rgba(255, 255, 255, 0.15);
}
table tr:nth-child(even):nth-child(n+3) td:nth-child(n+2) {
  background-color: rgba(0, 0, 0, 0.0250);
}
#potvrditKopirovaniGrantu{
  background: var(--colorZelenaAgree);
}
#potvrditKopirovaniGrantu:hover{
  box-shadow: #666666;
}
#potvrditKopirovaniGrantu:hover,.buttonHover:hover{
  background-color: var(--colorByThemeLighter);
  
}
.clParentDivWidthMax{
 width:100%;
}
.clPadRight5{
  margin-right:5px
}

</style>