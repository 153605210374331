export default {
  "requestVyvojoveDilny-Oprava": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprava"])},
  "requestVyvojoveDilny-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "requestVyvojoveDilny-InvC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventární číslo"])},
  "requestVyvojoveDilny-Vytvoreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení objednávky"])},
  "requestVyvojoveDilny-PopisMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis musí být vyplněn."])},
  "requestVyvojoveDilny-InvCMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventární číslo musí být vyplněno."])},
  "requestVyvojoveDilny": {
    "Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "OmezeniNa50Znaku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délka názvu je nejvýše 50 znaků. Popis pište do Komentáře požadavku"])}
  },
  "DochazkaNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docházka"])},
  "Otocit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otočit"])},
  "Predchozi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předchozí"])},
  "Dalsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
  "Nahled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled: "])},
  "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "granty-Zdroj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
  "granty-Rozliseni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozlišení"])},
  "granty-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "granty-Vznik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vznik"])},
  "granty-Castka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "granty-Faktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktury"])},
  "granty-Promenna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proměnná"])},
  "granty-KartaVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta výdejky s PIN"])},
  "granty-Pokyny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokyny ke grantu"])},
  "granty-NascanovanyDne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenováno dne"])},
  "granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grantové výkazy"])},
  "granty-Vykaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grantový výkaz"])},
  "granty-Rozpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položkový rozpis"])},
  "granty-DatumVykazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum Výkazu"])},
  "granty-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "granty-DetailPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailní náhled"])},
  "granty-PrilozeneFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiložené dokumenty"])},
  "konec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec"])},
  "menuAktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně(Požadavky)"])},
  "menuAktualne-KmemuVyjadreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K mému vyjádření"])},
  "menuAktualne-PozadavkyZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavky zaměstnance"])},
  "menuAktualne-SchvalenePozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválené požadavky"])},
  "menuDokumenty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenty"])},
  "menuGranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granty"])},
  "menuMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek"])},
  "menuSklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklad"])},
  "menuTelefony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefony"])},
  "menuTelefony-DuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důležité kontakty"])},
  "menuTelefony-DuleziteKontakty-PodporaEIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora EIS"])},
  "menuTelefony-OblibeneKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblíbené kontakty"])},
  "menuTelefony-OsobniKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní kontakty"])},
  "menuVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdejky"])},
  "pozadavky-Sledujici": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledující požadavku:"])},
  "pozadavky-VyplnteKomentarKeSchvalovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napište prosím důvod vrácení resp. zamítnutí."])},
  "pozadavky-PozadavekSchovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek schován."])},
  "pozadavky-NemateOpravneniObjednavat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte oprávnění objednávat."])},
  "pozadavky-NelzeUvestPozadavekKeSchvaleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze úvest požadavek do stavu 'ke schválení', pokud neobsahuje žádné položky."])},
  "pozadavky-PozadavekSchvalen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek schválen."])},
  "pozadavky-PozadavekVracen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek vrácen."])},
  "pozadavky-PozadavekZamitnut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek zamítnut."])},
  "pozadavky-ObjednavkaPridanaPolozka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidána/upravena položka objednávky."])},
  "pozadavky-Grid-Sloupec-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah"])},
  "pozadavky-Grid-Sloupec-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
  "pozadavky-Grid-Sloupec-Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "pozadavky-Grid-Sloupec-Stav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "pozadavky-Grid-Sloupec-PoslZmena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední změna"])},
  "pozadavky-Grid-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "pozadavky-Pozadavek-KmemuVyjadreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K mému vyjádření"])},
  "pozadavky-Pozadavek-PozadavkyZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavky zaměstnance"])},
  "pozadavky-Pozadavek-SchvalenePozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnou schválené požadavky"])},
  "pozadavky-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "pozadavky-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "pozadavky-IDPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Požadavku"])},
  "pozadavky-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "pozadavky-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst více záznamů"])},
  "pozadavky-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah"])},
  "pozadavky-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Žádná data k zobrazení>"])},
  "pozadavky-ObjednatOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednat od"])},
  "pozadavky-ObjednavkaPresActivu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit objednávku od ACTIVA.CZ"])},
  "pozadavky-PosledniZmena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední změna"])},
  "pozadavky-Pozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek"])},
  "pozadavky-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přes"])},
  "pozadavky-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "pozadavky-Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy:"])},
  "pozadavky-Stav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "pozadavky-StavPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav požadavku"])},
  "pozadavky-Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "pozadavky-TypPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ požadavku"])},
  "pozadavky-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubory"])},
  "pozadavky-VytvoreniNovehoPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření nového požadavku"])},
  "pozadavky-VytvoreniObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit objednávku"])},
  "pozadavky-ZalozitVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit požadavek"])},
  "pozadavky-Importovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubor"])},
  "pozadavky-Import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import položek z Excelu"])},
  "pozadavky-SablonaHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím stáhnete šablonu."])},
  "pozadavky-Sablona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona"])},
  "pozadavky-SablonaNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablona_obj_polozky_albina.xlsx"])},
  "pozadavky-ImportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text Dalsi chytrej text"])},
  "pozadavky-VybratSoubor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubor"])},
  "pozadavky-ObjednavkaLibovolnyDodavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libovolný dodavatel"])},
  "pozadavky-ZalozeniObjednavkyZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření objednávky(zásobování)"])},
  "pozadavky-ZalozitNovyPozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit nový požadavek"])},
  "pozadavky-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
  "pozadavky-ZobrazeniPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení požadavku"])},
  "pozadavky-ZpetNaPozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "pozadavky-DetailObjednavkyZasob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail objednávky (zásobování)"])},
  "pozadavky-Nazev:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah: "])},
  "pozadavky-Zamestnanec:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec: "])},
  "pozadavky-Stav:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav: "])},
  "pozadavky-Grant:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant: "])},
  "pozadavky-Dodavatel:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavatel: "])},
  "pozadavky-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "pozadavky-KatalogCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalogové číslo"])},
  "pozadavky-TypObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ objednávky"])},
  "pozadavky-Specifikace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikace"])},
  "pozadavky-Schvalovani:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schvalování: "])},
  "pozadavky-KomentarKeSchvalovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke schvalování"])},
  "pozadavky-SchvalProces:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schvalovací proces: "])},
  "pozadavky-ObjednavkaZalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka založena."])},
  "pozadavky-PresmerovaniActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesměrování do e-shopu ACTIVA.CZ"])},
  "pozadavky-ObjednavkaZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny objednávky uloženy."])},
  "pozadavky-UvedenDoStavu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek uveden do stavu \""])},
  "pozadavky-StavZrusen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušen"])},
  "pozadavky-StavKeSchvaleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ke schválení"])},
  "pozadavky-StavUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložený"])},
  "pozadavky-VyrazeniMajetkuZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny vyřazení majetku uloženy."])},
  "pozadavky-PrevodMajetkuZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny převodu majetku uloženy."])},
  "pozadavky-PrevodMajetkuPridanMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek přidán do převodu."])},
  "pozadavky-PrevodMajetkuOdebranMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek odebrán z převodu."])},
  "pozadavky-VyvojoveDilnyZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny uloženy."])},
  "pozadavky-PrevodMajetkuZalozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod majetku založen."])},
  "pozadavky-PrevodMajetkuUpraven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úpravy převodu majetku uloženy."])},
  "pozadavky-MajetekPreveden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek převeden"])},
  "pozadavky-VyrazeniMajetkuZalozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazení majetku založeno."])},
  "pozadavky-Zarazeni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zařazení: "])},
  "pozadavky-DatumSplatnosti:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum splatnosti: "])},
  "pozadavky-DatumVystaveni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vystavení: "])},
  "pozadavky-DatumZaplaceni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zaplacení: "])},
  "pozadavky-DatumPrijeti:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum přijetí: "])},
  "pozadavky-Cislo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo: "])},
  "pozadavky-Objednavka:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka: "])},
  "pozadavky-CenaV:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena v "])},
  "pozadavky-CenaCelkemCZK:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem (CZK): "])},
  "pozadavky-CisloUctu:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo účtu: "])},
  "pozadavky-TypPlatby:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ platby: "])},
  "pozadavky-Mena:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna: "])},
  "pozadavky-DetailPozadavkuDoVyvojDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail požadavku na vývojové dílny"])},
  "pozadavky-Popis:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis: "])},
  "pozadavky-Majetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek: "])},
  "pozadavky-DetailPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail převodu majetku"])},
  "pozadavky-PrevodNa:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod na:"])},
  "pozadavky-PrevadenyMajetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převáděný majetek: "])},
  "pozadavky-InvC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inv. číslo"])},
  "pozadavky-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zařazení"])},
  "pozadavky-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořizovací cena"])},
  "pozadavky-DetailVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail vyřazení majetku"])},
  "pozadavky-Duvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod"])},
  "pozadavky-Zpusob:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob: "])},
  "pozadavky-VyrazovanyMajetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazovaný majetek: "])},
  "pozadavky-TypID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TypID"])},
  "pozadavky-StavID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StavID"])},
  "pozadavky-ZalozeniPozadavkuNaVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení objednávky přes vývojové dílny"])},
  "pozadavky-ObjednavkaPresVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka (přes vývojové dílny):"])},
  "pozadavky-Uroven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". úroveň"])},
  "pozadavky-Schvalovatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". schvalovatel"])},
  "pozadavky-ZalozitNovyPozadavekPodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit kopii tohoto požadavku"])},
  "pozadavky-Popisek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro vyhledávání ve všech záznamech klikněte na Načíst vše"])},
  "telefony-EditovatDuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editovat důležité kontakty"])},
  "telefony-Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "telefony-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat v celém telefonním seznamu"])},
  "telefony-IdZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Zaměstnance"])},
  "telefony-Pritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přítomen"])},
  "telefony-Jmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
  "telefony-KategorieZobrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie zobrazení"])},
  "telefony-Misto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo"])},
  "telefony-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst další záznamy"])},
  "telefony-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Žádná data k zobrazení>"])},
  "telefony-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "telefony-Telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "telefony-TelefonniSeznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní seznam"])},
  "telefony-DuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důležité kontakty"])},
  "telefony-Ostatni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])},
  "telefony-MeOddeleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mé oddělení"])},
  "telefony-OblibeneKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblíbené kontakty"])},
  "telefony-SkupinaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina musí být vyplněna"])},
  "telefony-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "telefony-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
  "telefony-ZamestnanecMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec musí být vyplněn"])},
  "telefony-PridatDoSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do skupiny"])},
  "telefony": {
    "OdebratZOblibenych": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím odeberete z oblíbených"])},
    "PridatDoOblibenych": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím přidáte do oblíbených"])},
    "Aktivni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přítomen"])},
    "NeAktivni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřítomen"])},
    "KliknutimZobraziteDetaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailní zobrazení"])}
  },
  "topToolBar-Jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
  "topToolBar-Jazyk-CS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čeština (CZ)"])},
  "topToolBar-Jazyk-EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angličtina (EN)"])},
  "topToolBar-Odhlasit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
  "topToolBar-PrihlasenyUzivatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně přihlášen:"])},
  "topToolBar-Vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled"])},
  "topToolBar": {
    "connectedAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API URL:"])}
  },
  "majetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek"])},
  "majetek-Pohyb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pohyb"])},
  "majetek-PohybDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "majetek-PohybDoklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doklad"])},
  "majetek-PohybCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
  "majetek-PohybPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "majetek-PohybPuvodniZamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Původní zaměstnanec"])},
  "majetek-PohybNaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový zaměstnanec"])},
  "majetek-MajetekPohyby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pohyby majetku"])},
  "majetek-DatumZarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zařazeno"])},
  "majetek-Mistnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místnost"])},
  "majetek-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "majetek-DokladZarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doklad zařazení"])},
  "majetek-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod majetku"])},
  "majetek-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazení majetku"])},
  "majetek-MajetekSoucastiPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek je součástí aktivního požadavku převodu/likvidace."])},
  "majetek-MajetekNelzeVyradit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majetek nelze vyřadit, protože se nachází v jiném aktivním požadavku."])},
  "majetek-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "majetek-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "majetek-InvCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventární číslo"])},
  "majetek-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "majetek-PresmerovaniDoVyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazovaný majetek:"])},
  "majetek-Vyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazení"])},
  "majetek-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zařazení"])},
  "majetek-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořizovací cena"])},
  "majetek-Blokovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokován*"])},
  "majetek-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst další záznamy"])},
  "majetek-DetailMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail majetku: "])},
  "majetek-EvidovanoNaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidováno na zaměstnance:"])},
  "majetek-InventarniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventární číslo:"])},
  "majetek-VyrobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výrobní číslo:"])},
  "majetek-NachaziSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachází se:"])},
  "majetek-DatumPorizeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum pořízení"])},
  "majetek-Obor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obor:"])},
  "majetek-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina:"])},
  "majetek-Stredisko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středisko:"])},
  "majetek-Zarazeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zařazeno:"])},
  "majetek-ZustatkovaCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstatková cena:"])},
  "majetek-Umisteni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění:"])},
  "majetek-Poznamka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka: "])},
  "majetek-Popisek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod/vyřazení majetku naleznete v požadavcích..."])},
  "sklad-Sklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladová evidence"])},
  "sklad-PouzeNenulove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Jen dostupné"])},
  "sklad-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "sklad-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "sklad-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst další záznamy"])},
  "sklad-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "sklad-Pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet"])},
  "sklad-Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
  "sklad-CenaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena od*"])},
  "sklad-CenaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena do*"])},
  "sklad-Cislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo výdejky"])},
  "sklad": {
    "nadpisSelectBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklad"])}
  },
  "majetekPrevod-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod majetku:"])},
  "majetekPrevod-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "majetekPrevod-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přes"])},
  "majetekPrevod-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Přes musí být vyplněno"])},
  "majetekPrevod-NaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na zaměstnance"])},
  "majetekPrevod-ZamestnanecMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Zaměstnanec musí být vyplněn"])},
  "majetekPrevod-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubory"])},
  "majetekPrevod-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])},
  "majetekPrevod-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo přetáhněte zde"])},
  "majetekPrevod-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor nahrán."])},
  "majetekPrevod-SouborSeNepodariloNahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor se nepodařilo nahrát."])},
  "majetekPrevod-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "majetekPrevod-VytvoreniPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení převodu majetku"])},
  "majetekPrevod-ZalozeniPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit převod majetku"])},
  "majetekPrevod-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat změny"])},
  "majetekPrevod-PrevadenyMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převáděný majetek"])},
  "majetekPrevod-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenalezeny žádné záznamy"])},
  "majetekPrevod-InvCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventární číslo"])},
  "majetekPrevod-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "majetekPrevod-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zařazení"])},
  "majetekPrevod-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořizovací cena"])},
  "majetekPrevod-InterniId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní Id"])},
  "majetekPrevod-Vybran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrán"])},
  "majetekPrevod-Blokovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokován"])},
  "majetekPrevod-Upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
  "majetekPrevod-Potvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
  "majetekPrevod-Zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "majetekPrevod-Smazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
  "majetekPrevod-Varovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varování"])},
  "majetekPrevod-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento záznam?"])},
  "majetekPrevod-PretahneteSemProSouhrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "majetekPrevod-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "majetekVyrazeni-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazení majetku: "])},
  "majetekVyrazeni-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "majetekVyrazeni-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přes"])},
  "majetekVyrazeni-Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "majetekVyrazeni-Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
  "majetekVyrazeni-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento požadavek?"])},
  "majetekVyrazeni-ZalozeniVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit vyřazení majetku"])},
  "majetekVyrazeni-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Přes musí být vyplněno"])},
  "majetekVyrazeni-VyrazovanyMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazovaný majetek"])},
  "majetekVyrazeni-VyrazMajetekMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazovaný majetek musí být vyplněn."])},
  "majetekVyrazeni-ZpusobVyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob vyřazení"])},
  "majetekVyrazeni-ZpusobVyrazeniMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob vyřazení musí být vyplněn."])},
  "majetekVyrazeni-Duvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod"])},
  "majetekVyrazeni-DuvodMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod musí být vyplněn."])},
  "majetekVyrazeni-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubory"])},
  "majetekVyrazeni-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])},
  "majetekVyrazeni-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo přetáhněte zde"])},
  "majetekVyrazeni-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor nahrán."])},
  "majetekVyrazeni-SouborSeNenahral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor se nepodařilo nahrát."])},
  "majetekVyrazeni-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "majetekVyrazeni-VytvoreniVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení vyřazení majetku"])},
  "majetekVyrazeni-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat změny"])},
  "Smlouvy-Cjednaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo jednací (ÚOCHB)"])},
  "Smlouvy-VytvoreniSmluv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení průvodního listu ke smlouvě"])},
  "Smlouvy-PruvodniList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průvodní list ke smlouvě: "])},
  "Smlouvy-NazevSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název smlouvy"])},
  "Smlouvy-Protistrana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protistrana"])},
  "Smlouvy-PredmetSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět smlouvy"])},
  "Smlouvy-FinancniPlneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční plnění (Kč)"])},
  "Smlouvy-TypSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ smlouvy"])},
  "Smlouvy-Oblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast"])},
  "Smlouvy-Referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referent"])},
  "Smlouvy-Podpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavazuji se, že podepsanou smlouvu odešlu protistraně, případně zajistím podpis protistrany sám."])},
  "Smlouvy-SmlouvyZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny smlouvy uloženy."])},
  "Smlouvy-SmlouvaZaložena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průvodní list ke smlouvě založen."])},
  "Smlouvy-ZalozitPruvodniList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit požadavek"])},
  "Smlouvy-VyplnenNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název smlouvy musí být vyplněn."])},
  "Smlouvy-VyplnenPredmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět smlouvy musí být vyplněn."])},
  "Smlouvy-VyplnenTypSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ smlouvy musí být vyplněn."])},
  "Smlouvy-VyplnenaOblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast smlouvy musí být vyplněna."])},
  "Smlouvy-VyplnenoFinancniPlneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční plnění smlouvy musí být vyplněno."])},
  "Smlouvy-FyzickyPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smlouva bude ředitelem podepsána fyzicky - doručte vytištěný formulář smlouvy na právní oddělení"])},
  "Smlouvy": {
    "TextPodpisReditelANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručte vytištěný formulář smlouvy na právní oddělení."])},
    "TextPodpisReditelNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiložený dokument opatří ředitel elektronickým podpisem."])},
    "ElektronickyPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ředitel podepíše přiloženou smlouvu elektronicky"])},
    "MusiteZvolitTypPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musíte vybrat způsob podepsání smlouvy"])},
    "ZpusobPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob podepsání smlouvy"])},
    "PrilohyKPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy k podpisu:"])},
    "PrilohyBezPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní přílohy:"])}
  },
  "objednavkaPresKnihovnu-OpravduSiPrejeteSmazatPozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento požadavek?"])},
  "objednavkaPresKnihovnu-ZalozeniObjPresKnihovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení objednávky přes knihovnu"])},
  "objednavkaPresKnihovnu-ObjPresKnihovnu:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka (přes knihovnu):"])},
  "sklad-vydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdejky"])},
  "sklad-PouzeMeVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje výdejky"])},
  "sklad-Id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "sklad-Datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "sklad-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "sklad-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
  "sklad-Misto:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo: "])},
  "sklad-Zamestnanec:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec: "])},
  "sklad-Grant:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant: "])},
  "sklad-Informace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace"])},
  "dotazovac-ParametryDotazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry dotazu: "])},
  "dotazovac-Zobraz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraz"])},
  "dotazovac": {
    "dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotazovač"])},
    "Dotazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dotazu"])},
    "Dotaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotaz"])}
  },
  "central-OdebratSledujiciho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat sledujícího"])},
  "central-SledujiciZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidán sledující požadavku."])},
  "central-EditovatSledujiciho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editace sledujícího požadavku"])},
  "central-NovinkyHistorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie novinek"])},
  "central-OtevreniEkoisSigneru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při stisknutí tlačítka schválit dojde k přesměrování do aplikace EkoIS Signer."])},
  "central-OznacitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit vše"])},
  "central-Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy"])},
  "central-PoleMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole musí být vyplněno."])},
  "central-NepodariloSeStahnout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se stáhnout."])},
  "central-Skryt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt"])},
  "central-GrantZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna grantu uložena."])},
  "central-EditovatGrantObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editace grantu"])},
  "central-EditovatVlastnika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editace vlastníka požadavku"])},
  "central-VlastnikZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník požadavku změněn."])},
  "central-ZmenaGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit grant"])},
  "central-Dodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgentní dodání: "])},
  "central-TerminDodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet dnů"])},
  "central-UrgentniDodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Potřebná doba dodání (nepovinné)"])},
  "central-UrgentniDodaniTypy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba"])},
  "central-KontaktNaPodporu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty na technickou podporu:"])},
  "central-ZkusitZnovu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkusit znovu"])},
  "central-StrankuSeNepodariloNacist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránku se nepodařilo načíst"])},
  "central-Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte psát..."])},
  "central-Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání..."])},
  "central-ZrusitFiltr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit filtr"])},
  "central-VytvoritFiltr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit filtr"])},
  "central-FiltrVysvetlivka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrovat"])},
  "central-PolozkyObjednavky:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky objednávky:"])},
  "central-ExportVseExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat do Excelu"])},
  "central-ExportPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat do PDF"])},
  "central-Vsechny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny"])},
  "central-PretahneteSemProSouhrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetáhněte sem záhlaví pro souhrn"])},
  "central-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
  "central-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst další záznamy"])},
  "central-NacistVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst vše"])},
  "central-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Žádná data k zobrazení>"])},
  "central-NenalezenyZadnePolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Žádné položky k zobrazení>"])},
  "central-KatalogNeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog není k dispozici>"])},
  "central-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "central-Pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet"])},
  "central-PocetMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet musí být číslo"])},
  "central-PocetMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet musí být vyplněn"])},
  "central-Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
  "central-Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
  "central-Potvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
  "central-Zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "central-ZrusitUpravy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit úpravy"])},
  "central-Upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
  "central-Ulozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "central-Smazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
  "central-Pridat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat položku"])},
  "central-Varovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varování"])},
  "central-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přes"])},
  "central-Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "central-Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
  "central-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "central-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubory"])},
  "central-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])},
  "central-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo přetáhněte zde"])},
  "central-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor nahrán."])},
  "central-SouborSeNenahral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor se nepodařilo nahrát."])},
  "central-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento požadavek?"])},
  "central-OpravduSiPrejeteSmazatZaznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento záznam?"])},
  "central-DatumPosledniZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední aktualizace"])},
  "central-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Přes musí být vyplněno."])},
  "central-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "central-GrantNevybran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezvolen"])},
  "central-GrantPovinny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant*"])},
  "central-GrantMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Grant musí být vyplněn."])},
  "central-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "central-ObjednatOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednat od*"])},
  "central-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat změny"])},
  "central-NenalezenyZadneSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenalezeny žádné soubory"])},
  "central-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "central-PopisMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Popis musí být vyplněn."])},
  "central-TypMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Typ dokumentu musí být vyplněn."])},
  "central-KatC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kat. č."])},
  "central-KatalogoveCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalogové číslo"])},
  "central-Zakazka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázka"])},
  "central-KMajetkuC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K majetku inv. č."])},
  "central-KMajetkuCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K majetku inv. číslo"])},
  "central-Specifikace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikace"])},
  "central-SpecifikaceMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikace musí být vyplněna"])},
  "central-JednotkaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka musí být vyplněna"])},
  "central-Cena/Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena/Jednotka"])},
  "central-CenaMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena musí být číslo"])},
  "central-FormaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Forma musí být vyplněna."])},
  "central-CenaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Cena musí být vyplněna."])},
  "central-Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
  "central-MenaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Měna musí být vyplněna."])},
  "central-Celkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem Kč"])},
  "central-KonecnaCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konečná cena Kč"])},
  "central-CelkemMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem musí být číslo"])},
  "central-CenaZaJednotku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena za jednotku"])},
  "central-OdhadCenaZaJednotku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhad ceny za jednotku"])},
  "central-Jednotka2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
  "central-TypObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Objednávky"])},
  "central-Zamitnout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnout"])},
  "central-Vratit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit"])},
  "central-Schvalit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválit"])},
  "central-Editovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editovat"])},
  "central-ElPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronický podpis"])},
  "central-DeponovanoU:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deponováno u"])},
  "central-Titul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titul"])},
  "central-TitulMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Titul musí být vyplněn."])},
  "central-Vydavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydavatel"])},
  "central-Autori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoři"])},
  "central-Vydani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydání"])},
  "central-VydaniMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydání musí být číslo."])},
  "central-VydaniMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydání musí být vyplněno."])},
  "central-Rok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "central-RokMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok musí být číslo."])},
  "central-RokMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok musí být vyplněn."])},
  "central-Forma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
  "central-ISBN/ISSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN/ISSN/DOI"])},
  "central-DetailFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail faktury"])},
  "central-DetailSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail průvodního listu ke smlouvě"])},
  "central-DetailPozadavkuPracCesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail soukromé vozidlo pracovní cesta"])},
  "central-ZalozeniObjPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení objednávky přes zásobování"])},
  "central-ObjPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka (přes zásobování):"])},
  "central-SumaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková cena v Kč:"])},
  "central-KonecnaCenaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konečná cena v Kč"])},
  "central-PolozekCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položek celkem:"])},
  "central-Napoveda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda"])},
  "central-Tisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tisk"])},
  "central-TypDokumentu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "central-funkcni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albína funguje správně :)"])},
  "central_stavalbinyCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav Albíny v češtině"])},
  "central_stavalbinyEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav Albíny v angličtině"])},
  "central_ZmenaStavuAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna aktuálního stavu Albíny"])},
  "central_ZmenaUzivateleAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna uživatele Albíny"])},
  "central_ImportDat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importuji data..."])},
  "central-Texts-A-saveRowChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
  "central-Texts-A-cancelRowChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "central-Texts-A-deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
  "central-Texts-A-confirmDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varování"])},
  "central-Texts-A-confirmDeleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete smazat tento záznam?"])},
  "central-Vyberte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte"])},
  "central-VyberSloupce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte sloupec"])},
  "central-PretahneteSemSloupecProZmizeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přetažením sloupce sem skryjete"])},
  "central-Upozorneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornění"])},
  "central-PridatZaznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat položku"])},
  "central-ObnovitPuvodniRozlozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit původní rozložení"])},
  "central-PridatClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat novinku"])},
  "central-DetailPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Požadavku"])},
  "central-UpravitNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit novinku"])},
  "central-ZobrazitArchivniPozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" vč. archivních"])},
  "central-ZacnetePsat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte psát..."])},
  "central": {
    "CenaOdhad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Původní odhad"])},
    "CenaOdhadJednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
    "CenaVKc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena v Kč"])},
    "Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
    "VyplnteDuvodDoKomentare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte důvod vrácení/zamítnutí do komentáře"])},
    "Faktura": {
      "ZobrazitPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit položky"])},
      "SkrytPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt položky"])}
    },
    "pagingInfoStrana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strana"])},
    "pagingInfoCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
    "pagingInfoPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["položek"])},
    "Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolvoval(a)"])},
    "Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neabsolvoval(a)"])},
    "KomentarEditace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář lze upravit v editaci požadavku."])},
    "EditaceGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editace grantu"])},
    "Skoleni": {
      "Skoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Školení"])},
      "NazevSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název školení"])},
      "Uspesnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek"])},
      "DatumSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum školení"])},
      "DatumExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum expirace"])},
      "Faktura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura"])},
      "Odkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])},
      "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
      "BezFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez Faktury"])},
      "MusiBytVyplnenPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Školení musí být vybráno"])},
      "MusiBytVyplnenDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum školení musí být vyplněno"])},
      "MusiBytVyplnenDatumExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum expirace musí být vyplněno"])},
      "PridatSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat školení"])},
      "PouzeAktualni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze platné"])},
      "Filtr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
      "UzivatelskyVytvorene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelsky vytvořené"])},
      "Globalni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převzato od poskytovatelů"])},
      "TypSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ školení"])},
      "Expirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirace"])},
      "BezExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez expirace"])},
      "PrilohyKeSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přilohy ke školení"])},
      "Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy"])},
      "ZobrazitPrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit přílohy"])},
      "PridatPrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat přílohy"])}
    },
    "Grouping": {
      "groupByThisColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seskupit podle tohoto sloupce"])},
      "groupContinuedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračuje z předchozí stránky"])},
      "groupContinuesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračuje na další stránce"])},
      "ungroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozdělit"])},
      "ungroupAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozdělit vše"])}
    },
    "Sorting": {
      "ascendingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řadit vzestupně"])},
      "clearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit řazení"])},
      "descendingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řadit sestupně"])}
    },
    "ColumnFixing": {
      "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upevnit"])},
      "leftPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na levou stranu"])},
      "rightPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pravou stranu"])},
      "unfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvolnit"])}
    },
    "Napovedy": {
      "FormatHTTPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz vložte ve formátu viz. příklad: https://intraweb.uochb.cas.cz/"])}
    },
    "textZkopirovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text zkopírován do schránky:"])},
    "NovaVerzeAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová verze Albíny je k dispozici, pro aktualizaci klikněte zde."])},
    "prepnoutDoCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit na češtinu"])},
    "prepnoutDoAJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to English"])},
    "otevritPostranniMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít hlavní menu"])},
    "zavritPostranniMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít hlavní menu"])},
    "otevritNastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít nastavení"])},
    "zavritNastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít nastavení"])}
  },
  "clanky": {
    "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuál:"])},
    "seznamFciAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam funkcí Albíny a kde je nalézt"])},
    "objednavkaZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření objednávky"])},
    "objednavkaKnihovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření objednávky (knihovna)"])},
    "objednavkaVyvojovky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření objednávky (vývojové dílny)"])},
    "vyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřazení majetku"])},
    "prevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod majetku"])},
    "pracCestaSoukrVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použití soukromého vozidla při prac. cestě"])},
    "pruvodniListSmlouva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průvodní list ke smlouvě"])},
    "platebniKarta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení účtárně o platbě kartou"])},
    "osobniInformace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní informace"])},
    "granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granty"])},
    "organizaceResitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizace (nastavení týmů a inventarizační komise)"])},
    "sklady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklad"])},
    "dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Práce s tabulkami"])},
    "Pozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavky"])}
  },
  "admin_ZmenaUzivatele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN - Změna uživatele"])},
  "admin_ZmenaStavAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN - Změna Stavu Albiny"])},
  "menu_nastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
  "osobni_informace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní informace"])},
  "zmenit_vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna vzhledu"])},
  "zmenit_heslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna hesla"])},
  "odhlasit_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit"])},
  "language_EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to English"])},
  "language_CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čeština je aktuální jazyk."])},
  "logged_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášen:"])},
  "vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled:"])},
  "odkazy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkazy:"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CESKY"])},
  "stavAlbinyCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[CZ] Stav v ČEŠTINĚ"])},
  "stavAlbinyEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[EN] Stav v ANGLIČTINĚ"])},
  "login-OsobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní číslo"])},
  "login-Zapamatovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapamatovat osobní číslo"])},
  "login-NeboBysteRadeji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebo byste raději?"])},
  "login-Heslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
  "login-Prihlasit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit"])},
  "login-Rozcestnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozcestník"])},
  "login-OKBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKbase"])},
  "login-ZapamatovatSi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapamatovat osobní číslo"])},
  "login-OsobniCisloMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní číslo musí být vyplněno."])},
  "login-OsobniCisloMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní číslo musí být tvořeno pouze z číslic."])},
  "login-HesloMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo musí být vyplněno."])},
  "polozky-zalozeni-ZalozeniObjednavkyPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení objednávky přes zásobování"])},
  "polozky-zalozeni-VytvoritObjednavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit objednávku"])},
  "polozky-zalozeni-PrejitNaActivaCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít do e-shopu ACTIVA.cz"])},
  "polozky-zalozeni-ObjednavkaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka přes ACTIVA.cz"])},
  "pozadavky-ObjednavkaPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka (přes zásobování):"])},
  "pozadavky-Objednavka-PolozkyObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky objednávky"])},
  "pozadavky-PozadavekZkopirovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek byl úspěšně zkopírován."])},
  "organizace-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "organizace-Oddeleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddělení"])},
  "organizace-Pritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přítomnost v práci"])},
  "organizace-Nepritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřítomnost v práci"])},
  "organizace-Vedouci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedoucí grantu"])},
  "organizace-Zastupce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zástupce vedoucího grantu"])},
  "organizace-PlatnostOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost od"])},
  "organizace-PlatnostDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost do"])},
  "organizace-RozbalitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozbalit vše"])},
  "organizace-SbalitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbalit vše"])},
  "organizace-ResitelskeSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení týmů"])},
  "organizace-Moje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje oddělení/granty"])},
  "organizace-Cela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizační struktura"])},
  "organizace-NastaveniResitelskychTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení týmů"])},
  "organizace-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "organizace-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
  "organizace-PridatDoSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do týmu"])},
  "organizace-SkupinaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tým musí být vyplněn"])},
  "organizace-ZamestnanecMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec musí být vyplněn"])},
  "organizace-ZamestnanecPridanDoResitelskeSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec přidán do Řešitelského týmu"])},
  "organizace-O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávat "])},
  "organizace-P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlížet "])},
  "organizace-PravoObjednavat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["právo objednávat"])},
  "organizace-PravoProhlizet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["právo prohlížet"])},
  "organizace": {
    "VlastniNazevZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní název grantu úspěšně změněn."])},
    "PopisGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní název grantu"])},
    "VyplntePopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte vlastní název grantu"])},
    "ZmenaPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení změny vlastního názvu grantu."])},
    "ZavritEditaciPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít editaci vlastního názvu grantu."])},
    "OtevritEditaciPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít editaci vlastního názvu grantu."])},
    "NekompletniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybějící členové inventarizační komise."])},
    "PridatDoKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat člena inventarizační komise"])},
    "NastaveniInventarniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení inventarizační komise"])},
    "InventarniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventarizační komise"])},
    "OrganizacniStruktura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizační struktura"])},
    "Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení a jméno (os. č.)"])},
    "Odebrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat"])},
    "OtevritKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít kopírování lidí mezi týmy"])},
    "ZavritKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít kopírování lidí mezi týmy"])},
    "Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z "])},
    "Do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "NakopirovatZDoVybranehoGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírovat zaměstnance do zvoleného týmu"])},
    "ZTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z týmu."])},
    "ZInvKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z inventarizační komise."])},
    "ChybaKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeden z grantů nebyl zvolen. Zvolte Grant výchozí a Grant Konečný a akci opakujte"])},
    "UspesnePrevedeniDoTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanci byli úspěšně překopírováni do zvoleného týmu"])}
  },
  "aktualne": {
    "Nastenka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nástěnka"])},
    "Aktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně"])},
    "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum od"])},
    "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum do"])},
    "DatumPotvrzeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum k potvrzení"])},
    "Komu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemci"])},
    "IdClanku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID článku"])},
    "ClanekVCj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text v češtině"])},
    "ClanekVAj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text v angličtině"])},
    "ZverejnitClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejnit článek"])},
    "SmazatClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat článek"])},
    "TypGlobalni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globální"])},
    "TypOsobni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní"])},
    "PlaceholderCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde vpište obsah Vaší novinky v Češtině..."])},
    "PlaceholderEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde vpište obsah Vaší novinky v Angličtině..."])},
    "Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ novinky"])},
    "PridatNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor novinek"])},
    "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ústavní"])},
    "Personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddělení"])},
    "Vyberte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte..."])},
    "PrijemceOdebran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce odebrán."])},
    "PrijemcePridan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce přidán."])},
    "NovinkaZverejnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka zveřejněna."])},
    "NovinkaSmazana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka smazána."])},
    "NovinkaPrectena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka přečtena."])},
    "NovinkaSkryta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka skryta."])},
    "NovinkaVracena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka vrácena."])},
    "Prectena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako přečtené"])},
    "ZpravaPotvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet zbývajících dnů, do potvrzení \"Označit jako přečtené\": "])},
    "ZpravaPromeskana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promeškáno potvrzení přečtení článku! "])},
    "historieNovinek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie novinek"])},
    "pridatNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat Novinku"])}
  },
  "errory-Universal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce se něco nepovedlo."])},
  "errory-Aktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Aktuálně se něco nepovedlo."])},
  "errory-Dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Dotazovače se něco nepovedlo."])},
  "errory-Granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Grantů se něco nepovedlo."])},
  "errory-Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Přihlášení se něco nepovedlo."])},
  "errory-Majetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Majetku se něco nepovedlo."])},
  "errory-Objednavka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Objednávek se něco nepovedlo."])},
  "errory-ObjednavkaKnihovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Objednávek do knihovny se něco nepovedlo."])},
  "errory-Organizace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Organizace se něco nepovedlo."])},
  "errory-Pozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Požadavků se něco nepovedlo."])},
  "errory-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Převodu majetku se něco nepovedlo."])},
  "errory-Sklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Skladu se něco nepovedlo."])},
  "errory-TelSeznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Telefonního seznamu se něco nepovedlo."])},
  "errory-Vydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Výdejek se něco nepovedlo."])},
  "errory-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Vyřazení majetku se něco nepovedlo."])},
  "errory-VyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce Vývojových dílen se něco nepovedlo."])},
  "errory-ZkusitZnovu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkusit znovu"])},
  "podpora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
  "podpora-NadpisFormular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora - mail formulář"])},
  "podpora-PopisProblemu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručně popište otázku nebo problém"])},
  "pracCesta": {
    "TiskSouhlasu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář o souhlasu s použitím vozidla"])},
    "VytvoreniNadpis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o cestě a řidiči - Vytvoření vyjádření k použití soukromého motorového vozidla při pracovní cestě."])},
    "VytvoreniNadpis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvolení automobilu - Vytvoření vyjádření k použití soukromého motorového vozidla při pracovní cestě."])},
    "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjádření k použití soukromého motorového vozidla při pracovní cestě"])},
    "Zalozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit požadavek"])},
    "Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec (řidič)"])},
    "InformaceCesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o cestě"])},
    "RidicPrukazCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řidičský průkaz číslo"])},
    "SkoleniRidicuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné školení řidičů platné do"])},
    "LekarskaProhlidkaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lékařská prohlídka (osob starších 65 let) platná do"])},
    "Cesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta"])},
    "Cil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
    "Ucel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel cesty"])},
    "OdhadKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhad vzdálenosti (tam i zpět)"])},
    "KonaniOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konání od"])},
    "KonaniDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
    "MotoroveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorové vozidlo"])},
    "SPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPZ"])},
    "JinyVlastnikVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiný vlastník vozidla (není-li vlastníkem zaměstnanec nebo jeho manželka/manžel)"])},
    "JinyVlastnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a přijmení jiného vlastníka vozidla"])},
    "JinyVlastnikJmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
    "JinyVlastnikPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
    "VyrobniZnacka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výrobní značka"])},
    "ObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objem válců"])},
    "DruhPohonneHmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh pohonné hmoty"])},
    "Spotreba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotřeba"])},
    "PlatnostTechnickeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost technické prohlídky do"])},
    "Pojistovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojišťovna"])},
    "CisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo pojistky"])},
    "PojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištěno na období od"])},
    "PojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
    "PojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné ručení"])},
    "PojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havarijní pojištění"])},
    "VyplnRidicskyPrukaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte číslo řidičského průkazu"])},
    "VyplnSkoleniRidicuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Platnost školení řidičů"])},
    "VyplnteLekarskouProhlidkuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte platnost lékařské prohlídky"])},
    "VyplnteCil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Cíl"])},
    "VyplnteUcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Účel cesty"])},
    "VyplnteOdhadKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Odhad počtu km (tam i zpět)"])},
    "VyplnteKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte dobu konání"])},
    "VyplnteSPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte SPZ"])},
    "VyplnteVyrobniZnacku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Výrobní značku"])},
    "VyplnteObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte objem válců"])},
    "VyplnteDruhPHM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte druh PHM"])},
    "VyplnteSpotrebu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Spotřebu"])},
    "DatumPocatekPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum začátku cesty"])},
    "DatumKonecPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum konce cesty"])},
    "VyplntePojistovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojišťovna"])},
    "VyplnteCisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo pojistky"])},
    "VyplntePojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištěno na období od"])},
    "VyplntePojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "VyplntePojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné ručení"])},
    "VyplntePojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havarijní pojištění"])},
    "VPripadeZmenyAktualizujte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- ověřte správnost a platnost těchto informací"])},
    "UcelPouziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dle § 157 zákona č. 262/2006 Sb. (Zákoník práce) je soukromé motorové vozidlo použito:"])},
    "UcelPouzitiNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromé motorové vozidlo je použito"])},
    "VyplnteUcelPouziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte důvod použití soukromého osobního vozidla"])},
    "pracCestaZalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použití soukromého osobního vozidla na pracovní cestě založeno"])},
    "pracCestaZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny uloženy pracovní cesta"])},
    "DuvodPotreby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod použití soukr. osob. vozidla"])},
    "zkontrolujteAktualnostDat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost jednoho z dat není naplněna. Zkontrolujte, že vyplněná data jsou v budoucnu. Dnešní datum nelze zadat."])},
    "JinyDuvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis jiného důvodu"])},
    "VyplnteJinyDuvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte popis jiného důvodu"])},
    "ulozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek uložen"])},
    "Ulozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "ZkontrolujtePlatnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum není platné v době konaní pracovní cesty."])},
    "VyplnteDuvodPotreby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte důvod použití soukr. osob. vozidla"])}
  },
  "osobniInformace": {
    "osobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní číslo"])},
    "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní informace"])},
    "jmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
    "prijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddělení"])},
    "osobni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní informace"])},
    "skoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Školení"])},
    "osVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní vozidla"])},
    "SkoleniRidicu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Školení řidičů"])},
    "expirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konec platnosti"])},
    "datumSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum školení"])},
    "datumSkoleniVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte datum školení"])},
    "expiraceLekarskaProhlidka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lékařská prohlídka (nad 65 let kvůli použití soukr. auta při pracovní cestě)"])},
    "expiraceLekarskaProhlidkaVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte lékařskáou prohlídku (nad 65 let kvůli použití soukr. auta při pracovní cestě)"])},
    "datumNarozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narození"])},
    "ridicskyPrukaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řidičský průkaz"])},
    "ridicskyPrukazVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte řidičský průkaz"])},
    "upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "zapsatzmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat změny"])},
    "zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "skoleniObecne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecná školení"])},
    "skoleniIndividualni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuální školení"])},
    "nelzeZadatDatumMinule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vámi zadané datum je již expirované"])},
    "poleKEditaciOsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete změnit: [Řidičský průkaz], [Lékařskou prohlídku]"])},
    "poleKEditaciSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete změnit: [datum školení řidičů], [konec platnosti školení řidičů]"])},
    "Odevy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní oděvy"])},
    "pripravujeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v přípravě"])},
    "tituly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tituly"])},
    "ZadostVydeje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o výdej oděvů"])},
    "ZadostVydejeOznameni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o výdej oděvů odeslána do skladu."])},
    "NelzeEditovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto položku nelze editovat (vygenerovaná automaticky)."])}
  },
  "Odevy": {
    "nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "cetnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Četnost v měsících"])},
    "posledni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední odběr"])},
    "dalsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr možný od"])},
    "mamNarok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kusů"])},
    "prvni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První"])},
    "Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "vyzvednete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K vyzvednutí"])},
    "pockejte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyčkejte"])}
  },
  "Vozidla": {
    "NoveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové vozidlo"])},
    "UlozitZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "SmazatVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat vozidlo"])},
    "VozidloSmazano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vozidlo úspěšně smazáno."])},
    "MotoroveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorové vozidlo"])},
    "SPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPZ"])},
    "JinyVlastnikVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiný vlastník vozidla (není-li vlastníkem zaměstnanec nebo jeho manželka/manžel)"])},
    "JinyVlastnikJmenoPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiný vlastník vozidla (Jméno a přijmení)"])},
    "JinyVlastnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a přijmení jiného vlastníka vozidla"])},
    "JinyVlastnikJmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno jiného vlastníka vozidla"])},
    "JinyVlastnikPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení jiného vlastníka vozidla"])},
    "VyrobniZnacka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výrobní značka"])},
    "ObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objem válců"])},
    "DruhPohonneHmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh pohonné hmoty"])},
    "Spotreba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotřeba"])},
    "PlatnostTechnickeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost technické prohlídky do"])},
    "Pojistovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojišťovna"])},
    "VyplntePojistovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňtě pojišťovnu"])},
    "CisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo pojistky"])},
    "PojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištěno na období od"])},
    "PojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
    "PojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné ručení"])},
    "PojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havarijní pojištění"])},
    "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny vozidla úspěšně uloženy."])},
    "Zakladani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAKLÁDANÍ NOVÉHO VOZIDLA"])},
    "VozidloInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o vozidle"])},
    "ZvolteAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr automobilu"])},
    "UpravitInformaceOVozidle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit informace o vozidle"])},
    "ZalozitVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat vozidlo"])},
    "ZalozitZruseni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "VozidloPridano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vozidlo bylo přidáno"])},
    "PotvrditZvoleniAuta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení výběru vozu"])},
    "PridanoDoCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto vybráno"])},
    "VyplnteSPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte SPZ"])},
    "VyplnteVyrobniZnacku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Výrobní značku"])},
    "VyplnteObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte objem válců"])},
    "VyplnteDruhPHM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte druh PHM"])},
    "VyplnteSpotrebu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Spotřebu"])},
    "DatumPocatekPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum začátku cesty"])},
    "DatumKonecPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum konce cesty"])},
    "VyplnteCisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte číslo pojistky"])},
    "VyplntePojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte pojištěno na období od"])},
    "VyplntePojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte Do"])},
    "VyplntePojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte povinné ručení"])},
    "VyplntePojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte havarijní pojištění"])},
    "VyplnteSpotrebuZTechnickehoPrukazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná spotřeba uvedená v technickém průkazu vozidla."])},
    "VyplntePojisteniOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte od"])},
    "VyplntePojisteniDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte do"])},
    "VyplnteDobuPojisteni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte interval pojištění"])},
    "VyplnteSTKDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte platnost technické prohlídky"])}
  },
  "pracSetkani": {
    "Podnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurace"])},
    "DatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum konání"])},
    "Hoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosté"])},
    "Zamestnanci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanci"])},
    "TypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ platby"])},
    "Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena (Kč)"])},
    "Spropitne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spropitné (Kč)"])},
    "CenaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem (Kč)"])},
    "VyplntePodnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podnik musí být vyplněn."])},
    "VyplnteDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum musí být vyplněn."])},
    "Vyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je potřeba vyplnit zaměstnance nebo hosty."])},
    "VyplnteTypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ platby musí být vyplněn."])},
    "VyplnteCenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena musí být vyplněna."])},
    "VyplnteCenuCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem musí být vyplněna."])},
    "ValidaceCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem neodpovídá součtu ceny a spropitného."])},
    "Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
    "Zadatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žadatel"])},
    "zaUOCHB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za ÚOCHB"])},
    "CenaObedVecere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena obědu / večeře"])},
    "FormaPlaceni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma placení"])},
    "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
    "SchvalProces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schvalovací proces"])},
    "Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
    "Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "Zalozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení požadavku pracovní oběd/večeře "])},
    "AutoNeniVybrano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není vybráno auto pro požadavek. Vyberte auto a akci zopakujte."])},
    "Zalozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek pracovního obědu/večeře založen."])},
    "Zalozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založit požadavek"])}
  },
  "Pozadavky": {
    "DoplnenaPole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole k doplnění."])},
    "DoplnenaPoleVPrubehu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole doplněná v průběhu schvalování"])},
    "CenaKZaplaceni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena k zaplacení (Kč)"])},
    "FinalniCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková cena (Kč)"])},
    "SchvalovanaCastka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schvalovaná část (Kč)"])},
    "Platkarta": {
      "Dodavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavatel"])},
      "NadpisGrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily platby"])},
      "OstatniGranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny granty"])},
      "VyplnteZemi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země musí být vyplněna."])},
      "VyplnteTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta musí být vyplněna."])},
      "VyplnteDatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum cesty musí být vyplněn."])},
      "VyplnteDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum od musí být vyplněn."])},
      "VyplnteAdresu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa musí být vyplněna."])},
      "VyplnteDatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum od musí být vyplněn."])},
      "VyplnteZam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec musí být vyplněn."])},
      "VyplnteMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna musí být vyplněna."])},
      "VyplnteUplatneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uplatnění musí být vyplněno."])},
      "VyplnteDrzitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držitel karty musí být vyplněn."])},
      "VyplnteUcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel platby musí být vyplněn."])},
      "DetailPlatKarty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail oznámení o platbě kartou"])},
      "JmenoAPrijmeniDrzitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitel karty"])},
      "UcelPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel platby"])},
      "PresnyNazevFirmy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesný název firmy"])},
      "PlnaAdresaFirmy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úplná adresa firmy"])},
      "Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
      "Celkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem"])},
      "Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
      "PlacenoDne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeno dne"])},
      "UhradaJeNaPracovniCeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úhrada souvisí s pracovní cestou"])},
      "UhradaNeníNaPracovniCeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úhrada nesouvisí s pracovní cestou"])},
      "TypUhrady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ úhrady"])},
      "Zeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
      "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta od"])},
      "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta do"])},
      "PolID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id položky"])},
      "Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financováno z"])},
      "TripID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo cesty"])},
      "TripTXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta"])},
      "ZamID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zaměstnanec"])},
      "ZamTXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
      "Zalozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Založení požadavku oznámení o platbě kartou"])},
      "Zalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek oznámení o platbě kartou založen"])},
      "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení o platbě kartou"])},
      "Drzitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držitel platební karty"])},
      "Ucel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel platby"])},
      "Adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa dodavatele"])},
      "VyplnteAdresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte adresu dodavatele "])},
      "DatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum konání"])},
      "TypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ platby"])},
      "Datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum platby"])},
      "VyplnteDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum musí být vyplněn."])},
      "VyplnteCenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena musí být vyplněna."])},
      "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
      "SloupecCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo cesty"])},
      "SloupecZeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
      "SloupecPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
      "SloupecKonaniOdDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konaní od - do"])},
      "SloupecZamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnanec"])},
      "SloupecCastka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "SloupecGrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
      "Uplatneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ uplatnění"])},
      "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny uloženy"])},
      "SouhlasObsah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlašuji, že úhrada nesouvisející se zahraniční cestou, byla řádně projednána a schválena dle směrnice 3/2008."])},
      "Souhlas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlas"])},
      "ValidaceCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem neodpovídá celkové sumě cen položek"])},
      "ValidaceSumyZakladani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena celkem musí být vyšší než 0.00"])},
      "CelkemCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
      "ValidaceDatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba, aby datum bylo vyšší nebo rovno datumu od"])},
      "ValidaceDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba, aby datum bylo nižší nebo rovno datumu do"])},
      "NeplatnaCestaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta Od není platná"])},
      "NeplatnaCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta Do není platná"])},
      "CestaOdZaCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta od je dále než Cesta do"])},
      "okCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta do je v pořádku"])},
      "okCestaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta od je v pořádku"])},
      "CestaTrvani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvání cesty od-do"])},
      "FormaVlastnenehoDokladu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mám originály dokladů v elektronické podobě."])},
      "vysvetlivkyKDokladu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Pokud m&aacute;te origin&aacute;ly dokladů <strong>v elektronick&eacute; podobě</strong>, přiložte je zde jako př&iacute;lohy.</p><p>Pokud m&aacute;te origin&aacute;ly dokladů <strong>v pap&iacute;rov&eacute; podobě</strong>, ode&scaron;lete požadavek ke schv&aacute;len&iacute; bez př&iacute;loh. Dal&scaron;&iacute; instrukce dostanete do e-mailu po schv&aacute;len&iacute;.</p>"])},
      "PrilohyNahrajiVDalsimKroku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originál/y nahrajte v dalším kroku do přílohy."])},
      "ZdeNahrajteElektroDokument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde nahrajte originály dokumentů."])},
      "MojeCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plánovaná cesta"])},
      "VyplnteMojeCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plánovaná cesta musí být vyplněna."])}
    },
    "CisloObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka (vyřizuje)"])},
    "CisloDodaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodací číslo"])},
    "CisloFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura"])},
    "Skladem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladem"])},
    "Odkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])}
  },
  "Validator": {
    "CenaMusiBytKladna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena musí být nezáporná."])},
    "SpropitneMusiBytKladne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spropitné musí být kladné."])},
    "CenaMusiBytVyssiNezNula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena musí být vyšší než 0."])},
    "VzdalenostMusiBytKladna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdálenost musí být kladná."])}
  },
  "Prilohy": {
    "VelikostSouboru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální velikost jedné přílohy je 50 MB."])},
    "PovolenePripony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolené přípony příloh: "])},
    "MaximalniPocetSouboru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální počet příloh je 10."])},
    "PrekrocenaMaxVelikost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se nahrát tyto přílohy z důvodu překročení maximální velikosti: "])},
    "NespravnaPripona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se nahrát tyto přílohy z důvodu nesprávné přípony: "])}
  },
  "Dochazka": {
    "DenniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denní přehled"])},
    "TydenniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdenní přehled"])},
    "MesicniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční přehled"])},
    "CelkemTyden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem týdně "])},
    "CelkemMesic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem měsíčně: "])},
    "ViditelneHodiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazené hodiny"])},
    "Tyden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
    "Mesic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
    "Udalost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Událost"])},
    "UdalostPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte..."])},
    "Poznamka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum od"])},
    "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum Do"])},
    "UdalostValidace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Událost musí být vyplněna."])}
  }
}