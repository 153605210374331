<template>
 <div> 

    <form action="your-action" @submit="onFormSubmit($event)">
                <div id="obaleniFormulare">
                    <div id="obalenigrid">
                        <DxResponsiveBox single-column-screen="sm" id="CarsResponsiveBox">
                            <DxRow :ratio="1" />
                            <DxRow :ratio="1" />
                            <DxCol :ratio="1" />
                            <DxCol :ratio="1" />
                            <DxCol :ratio="1" />
                                    <DxItem >
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                                    <h4 class="h4left">{{ $t('Vozidla.ZvolteAuto') }}</h4> 
                                    
                                    <div id="divTabVozidla"> 
                                    <DxTabs
                                        :visible="vozidla.length > 0"
                                        ref="tabs"
                                        id="tabsvozidla"
                                        :data-source="vozidla"
                                        width="auto"
                                        :scroll-by-content="true"
                                        :show-nav-buttons="true"
                                        v-model:selected-index="selectedVozidloIndex"
                                        @item-click="TabsItemClick"
                                        style=" margin-left: 5px;"
                                        />
                                        
                                        <DxButton v-if="!ZakladaniNoveVozidlo" class="button1" id="newcar" icon="add" height="auto" width="auto" type="success" styling-mode="outlined"
                                            :text="$t('Vozidla.NoveVozidlo')" @click="Action('NoveAuto')"
                                             />
                                        
                                            
                                             
                                    </div>
                                    <div class="CarBoxItem" v-if="false">
                                        <hr class="dotted-black">
                                    <DxButton  id="potvrdit" class="button1 buttonPotvrzeniVyberu" icon="todo" height="auto" width="100%" type="normal" styling-mode="contained" :text="$t('Vozidla.PotvrditZvoleniAuta')"
                                                    @click="Action('PotvrzeniVyberu');"/>
                                    
                                    </div>
                                    
                                    </DxItem>
                        </DxResponsiveBox>   
                        
                        <div v-if="zobrazitInformace">                                              
                            <DxResponsiveBox class="clResponsiveBordered" single-column-screen="sm" id="CarsResponsiveBox" >
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                            
                            
                                <DxItem>
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                                    <div class="CarBoxItemNadpis" width='auto'>
                                            <h4>{{ $t('Vozidla.VozidloInfo') }}</h4>
                                        </div>  
                                    
                                </DxItem>
                                <DxItem>
                                        <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
                                        <DxLocation :row="1" :col="0" :colspan="2" screen="sm" />
                                        <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />
                                        <div class="CarBoxItem">
                                            <DxTextBox  v-model:readOnly="ReadOnlySPZ" id="car" width="auto" height="45px" noDataText=""
                                                :label="$t('Vozidla.SPZ')"  v-model:value="formDataCarUsage.spz" class="DxSelectBoxResponsive canBeEditedSPZ inlineflex">
                                                <DxValidator>
                                                    <DxRequiredRule :message="$t('Vozidla.VyplnteSPZ')" />
                                                </DxValidator>
                                                <DxButton v-if="!ReadOnlyState && zobrazitEditSPZIcon" styling-mode="text" id="btnSpzEdit" icon="edit" @click="Action('SPZ');"></DxButton>
                                            </DxTextBox>
                                            </div>
                                    </DxItem>
                                <DxItem>
                                    <DxLocation :row="1" :col="1" :colspan="1" screen="lg" />
                                    <DxLocation :row="1" :col="2" :colspan="1" screen="md" />
                                    <DxLocation :row="2" :col="0" :colspan="2" screen="sm" />
                                    <DxLocation :row="2" :col="0" :colspan="3" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxTextBox v-model:readOnly="ReadOnlyState" :id="2" width="auto" height="45px"
                                            :label="$t('Vozidla.VyrobniZnacka')" 
                                            placeholder="" v-model:value="formDataCarUsage.vyrobni_znacka" 
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteVyrobniZnacku')" />
                                            </DxValidator>
                                        </DxTextBox>
                                        </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="1" :col="2" :colspan="1" screen="lg" />
                                    <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                    <DxLocation :row="3" :col="0" :colspan="2" screen="sm" />
                                    <DxLocation :row="3" :col="0" :colspan="3" screen="xs" />
                                    <div class="CarBoxItem inlineflex">
                                        
                                        <DxNumberBox v-model:readOnly="ReadOnlyState" :id="3" width="125px" height="45px"
                                            :label="$t('Vozidla.ObjemValcu')"
                                            placeholder=""
                                            v-model:value="formDataCarUsage.objem_valcu" 
                                            class="DxSelectBoxResponsive canBeEdited"
                                            :format="{
                                                type: 'fixedPoint',
                                                thousandSeparator: ' ',
                                                decimalSeparator: ','
                                            }">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteObjemValcu')" />
                                            </DxValidator>
                                        </DxNumberBox>
                                        <label id="labelalignedcenter">cm³</label></div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                    <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                    <DxLocation :row="4" :col="0" :colspan="2" screen="sm" />
                                    <DxLocation :row="4" :col="0" :colspan="3" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxSelectBox v-model:readOnly="ReadOnlyState" ref="selectboxphm" :id="4" width="auto" height="45px"
                                            :label="$t('Vozidla.DruhPohonneHmoty')" :data-source="pohonneHmoty"
                                            display-expr="content" value-expr="id" placeholder=""
                                            v-model:value="formDataCarUsage.phm_druh" 
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteDruhPHM')" />
                                            </DxValidator>
                                        </DxSelectBox>
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="2" :col="1" :colspan="1" screen="lg" />
                                    <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                    <DxLocation :row="5" :col="1" :colspan="2" screen="sm" />
                                    <DxLocation :row="5" :col="1" :colspan="3" screen="xs" />
                                    <div class="CarBoxItem">
                                        <div class="inlineflex">
                                        <DxNumberBox v-model:readOnly="ReadOnlyState" :id="5" width="125px" height="45px"
                                            :label="$t('Vozidla.Spotreba')" :hint="$t('Vozidla.VyplnteSpotrebuZTechnickehoPrukazu')"
                                            placeholder="" v-model:value="formDataCarUsage.spotreba"
                                            class="DxSelectBoxResponsive kratkapole canBeEdited ">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteSpotrebu')" />
                                            </DxValidator>
                                            
                                        </DxNumberBox>
                                        <label id="labelalignedcenter">l/100 km</label>
                                        <DxButton v-if="!ReadOnlyState" styling-mode="text" id="btnInfoSpotreba" icon="info" @click="Action('InfoSpotreba');"></DxButton>    
                                    </div>
                                        
                                    </div>
                                </DxItem>
                                <DxItem width="400px">
                                    <DxLocation :row="2" :col="2" :colspan="1" screen="lg" />
                                    <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                    <DxLocation :row="6" :col="2" :colspan="2" screen="sm" />
                                    <DxLocation :row="6" :col="2" :colspan="3" screen="xs" />

                                    <div class="CarBoxItem CarBoxWithWarning" width='auto'>
                                        <DxDateBox v-model:readOnly="ReadOnlyState" :id="6" :label="$t('Vozidla.PlatnostTechnickeDo')"
                                        v-model:value="formDataCarUsage.STK_platnost"  Width='auto'
                                            height="45px" class="canBeEdited" @value-changed="ValidateDatesBusinessTrip"
                                            :accept-custom-value="false" :open-on-field-click="true"> 
                                            <DxValidator>
                                                    <DxRequiredRule :message="$t('Vozidla.VyplnteSTKDatum')" />
                                            </DxValidator> 
                                        </DxDateBox>
                                        <WarningImage v-if="this.formDataCarUsage.vykricnik3" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px"/>
                                    </div>
                                </DxItem>
                            </DxResponsiveBox>
                            <DxResponsiveBox class="clResponsiveBordered" single-column-screen="sm" id="CarsResponsiveBox">
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                

                                <DxItem>
                                    <DxLocation :row="3" :col="0" :colspan="2" screen="lg" />
                                    <DxLocation :row="3" :col="0" :colspan="2" screen="md" />
                                    <DxLocation :row="3" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="3" :col="0" :colspan="4" screen="xs" />
                                    <div class="CarBoxItemNadpis" width='auto'>
                                        <h4>{{ $t('Vozidla.PojisteniPovinne') }}</h4>
                                    </div>

                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="3" :col="2" :colspan="2" screen="lg" />
                                    <DxLocation :row="7" :col="0" :colspan="2" screen="md" />
                                    <DxLocation :row="7" :col="0" :colspan="2" screen="sm" />
                                    <DxLocation :row="7" :col="0" :colspan="2" screen="xs" />
                                    <div class="CarBoxItemNadpis" width='auto'>
                                        <h4>{{ $t('Vozidla.PojisteniHavarijni') }}</h4>
                                    </div>

                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                    <DxLocation :row="4" :col="0" :colspan="2" screen="md" />
                                    <DxLocation :row="4" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="4" :col="0" :colspan="4" screen="xs" />

                                    <div class="CarBoxItem">

                                        <DxTextBox v-model:readOnly="ReadOnlyState" :id="7" width="auto" height="45px"
                                            :label="$t('Vozidla.Pojistovna')" placeholder="" v-model:value="formDataCarUsage.povinne_pojistovna"
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplntePojistovnu')" />
                                            </DxValidator>
                                        </DxTextBox>
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="4" :col="1" :colspan="1" screen="lg" />
                                    <DxLocation :row="4" :col="2" :colspan="2" screen="md" />
                                    <DxLocation :row="5" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="5" :col="0" :colspan="4" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxTextBox v-model:readOnly="ReadOnlyState" :id="8" width="auto" height="45px"
                                            :label="$t('Vozidla.CisloPojistky')" v-model:value="formDataCarUsage.povinne_cislo_pojistky"
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteCisloPojistky')" />
                                            </DxValidator>
                                        </DxTextBox>
                                    </div>
                                </DxItem>
                                <DxItem width="400px">
                                    <DxLocation :row="5" :col="0" :colspan="2" screen="lg" />
                                    <DxLocation :row="5" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="6" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="6" :col="0" :colspan="4" screen="xs" />

                                    <div class="CarBoxItem CarBoxWithWarning" width='auto'>
                                            <DxDateRangeBox v-model:readOnly="ReadOnlyState" :id="9" :start-date-label="$t('Vozidla.PojistenoOd')" :end-date-label="$t('Vozidla.PojistenoDo')" apply-value-mode="instantly" multiview:="true"  
                                                        :startDatePlaceholder="$t('Vozidla.PojistenoOd')" :endDatePlaceholder="$t('Vozidla.PojistenoDo')"
                                                        v-model:startDate="formDataCarUsage.povinne_od" v-model:endDate="formDataCarUsage.povinne_do" date-serialization-format="dd-mm-yyyy" height="45px" class="canBeEdited"
                                                        :accept-custom-value="false" :open-on-field-click="true" :show-clear-button="true" @value-changed="ValidateDatesBusinessTrip"  >
                                                        <DxValidator>
                                                        <DxRequiredRule :message="$t('Vozidla.VyplnteDobuPojisteni')" />
                                                    </DxValidator> 
                                            </DxDateRangeBox>
                                            <WarningImage v-if="this.formDataCarUsage.vykricnik4" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px"/>
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="4" :col="2" :colspan="1" screen="lg" />
                                    <DxLocation :row="8" :col="0" :colspan="2" screen="md" />
                                    <DxLocation :row="8" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="8" :col="0" :colspan="4" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxTextBox v-model:readOnly="ReadOnlyState" :id="10" width="auto" height="45px"
                                            :label="$t('Vozidla.Pojistovna')" placeholder="" v-model:value="formDataCarUsage.havarijni_pojistovna"
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplntePojistovnu')" />
                                            </DxValidator>
                                        </DxTextBox>
                                        
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="4" :col="3" :colspan="1" screen="lg" />
                                    <DxLocation :row="8" :col="2" :colspan="2" screen="md" />
                                    <DxLocation :row="9" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="9" :col="0" :colspan="4" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxTextBox v-model:readOnly="ReadOnlyState" :id="11" width="auto" height="45px"
                                            :label="$t('Vozidla.CisloPojistky')"  placeholder="" v-model:value="formDataCarUsage.havarijni_cislo_pojistky"
                                            class="DxSelectBoxResponsive canBeEdited">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('Vozidla.VyplnteCisloPojistky')" />
                                            </DxValidator>
                                        </DxTextBox>
                                        
                                    </div>
                                </DxItem>
                                <DxItem width="400px">
                                    <DxLocation :row="5" :col="2" :colspan="2" screen="lg" />
                                    <DxLocation :row="9" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="10" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="10" :col="0" :colspan="4" screen="xs" />

                                    <div class="CarBoxItem CarBoxWithWarning" width='auto'>
                                        <DxDateRangeBox v-model:readOnly="ReadOnlyState" :id="12" :start-date-label="$t('Vozidla.PojistenoOd')" :end-date-label="$t('Vozidla.PojistenoDo')" apply-value-mode="instantly" multiview:="true"  
                                                        :startDatePlaceholder="$t('Vozidla.PojistenoOd')" :endDatePlaceholder="$t('Vozidla.PojistenoDo')"
                                                        v-model:startDate="formDataCarUsage.havarijni_od" v-model:endDate="formDataCarUsage.havarijni_do" date-serialization-format="dd-mm-yyyy" height="45px" class="canBeEdited"
                                                        :accept-custom-value="false" :open-on-field-click="true" :show-clear-button="true" @value-changed="ValidateDatesBusinessTrip">
                                                        <DxValidator>
                                                        <DxRequiredRule :message="$t('Vozidla.VyplnteDobuPojisteni')" />
                                                    </DxValidator> 
                                        </DxDateRangeBox>
                                        <WarningImage v-if="this.formDataCarUsage.vykricnik5" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px"/>


                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="6" :col="0" :colspan="2" screen="lg" />
                                    <DxLocation :row="10" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="11" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="11" :col="0" :colspan="4" screen="xs" />
                                    <div class="CarBoxItem">
                                        <DxCheckBox :disabled="ReadOnlyState" :id="13" class="checkboxzverejnitZvyraz"
                                            :text="$t('Vozidla.JinyVlastnikVozidla') + ' '" height="45px" 
                                            v-model:value="formDataCarUsage.jinyVlastnik" ref="myCheckBox" />
                                    </div>
                                    <div id="divJinyVlastnikParent" width="100%">
                                        <div class="CarBoxItem" v-if="formDataCarUsage.jinyVlastnik" width="auto">
                                        <DxTextBox  v-model:readOnly="ReadOnlyState" :id="14" width="auto" height="45px"
                                            :label="$t('Vozidla.JinyVlastnikJmeno')" placeholder="" v-model:value="formDataCarUsage.jinyvlastnik_jmeno" class="canBeEdited"
                                            >
                                        </DxTextBox>
                                    </div>
                                    <div class="CarBoxItem" v-if="formDataCarUsage.jinyVlastnik" width="auto" >
                                        <DxTextBox  v-model:readOnly="ReadOnlyState" :id="15" width="auto" height="45px"
                                            :label="$t('Vozidla.JinyVlastnikPrijmeni')" placeholder="" v-model:value="formDataCarUsage.jinyvlastnik_prijmeni" class="canBeEdited"
                                            >
                                        </DxTextBox>
                                        
                                    </div>
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="6" :col="2" :colspan="2" screen="lg" />
                                    <DxLocation :row="11" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="12" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="12" :col="0" :colspan="4" screen="xs" />
                                    <div v-if="(formDataCarUsage.jinyVlastnik) && (volaniTyp==2) && (!this.zobrazButtonsPridaniVozidla)">
                                    
                                        <PrintVariants Report="SouhlasVlastnika" :PrintID="TripRequestID" :Label="$t('pracCesta.TiskSouhlasu')" PositionMY="top" PositionAT="bottom" position-collision="flipfit" ColorOfButton="orange" id="btnPrintVariants"/>
                                   
                                    
                                </div>
                                </DxItem>
                            
                                <DxItem>
                                        <DxLocation :row="8" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="12" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="13" :col="0" :colspan="4" screen="sm" />
                                        <DxLocation :row="13" :col="0" :colspan="4" screen="xs" />
                                        <div class="CarBoxItem" >
                                            <DxButton v-if="!this.zobrazButtonsPridaniVozidla && this.zobrazitTlacitka" icon="floppy" id="ulozit" class="button1 buttonUlozitZmeny" height="auto" width="auto" type="normal" styling-mode="contained" :text="$t('Vozidla.UlozitZmeny')"
                                            :use-submit-behavior="true"/> 
                                            <DxButton v-if="this.zobrazButtonsPridaniVozidla && this.zobrazitTlacitka" icon="floppy"   id="pridat" class="button1 buttonZalozitVozidlo" height="auto" width="auto" type="normal" styling-mode="contained" :text="$t('Vozidla.UlozitZmeny')"
                                            :use-submit-behavior="true"/>
                                            <DxButton v-if="(zobrazEditaci) && (formDataCarUsage.spz!=0)"  id="btnUpravInfoAuto" class="button1 buttonKEditaci" icon="edit" width="auto" height="auto"  styling-mode="contained"  :text="$t('central-Editovat')" type="normal" 
                                             @click="Action('Editovat'); "/> 
                                        </div>
                                    </DxItem>
                                <DxItem>
                                                <DxLocation :row="8" :col="3" :colspan="1" screen="lg" />
                                                <DxLocation :row="12" :col="3" :colspan="1" screen="md" />
                                                <DxLocation :row="14" :col="0" :colspan="4" screen="sm" />
                                                <DxLocation :row="14" :col="0" :colspan="4" screen="xs" />
                                                <div class="CarBoxItem" >
                                                    <DxButton v-if="this.SmazatVozidloEnabled && !ZakladaniNoveVozidlo" id="smazat" class="button1 buttonSmazatVozidlo" icon="trash" height="auto" width="auto" type="normal" styling-mode="contained" :text="$t('Vozidla.SmazatVozidlo')"
                                                    @click="Action('SmazatVozidlo');" />
                                                    <DxButton v-if="this.zobrazButtonsPridaniVozidla && this.zobrazitTlacitka" icon="revert" id="smazat" class="button1 buttonZrusit" height="auto" width="auto" type="danger" styling-mode="contained" :text="$t('Vozidla.ZalozitZruseni')"
                                                    @click="Action('Zrusit');"/>
                                                </div>
                                            </DxItem>
                            </DxResponsiveBox>
                            <div class="CarBoxItem" v-if="false">
                                <hr class="dotted-black">
                            <DxButton  id="potvrdit" class="button1 buttonPotvrzeniVyberu" icon="todo" height="auto" width="100%" type="normal" styling-mode="contained" :text="$t('Vozidla.PotvrditZvoleniAuta')"
                                            @click="Action('PotvrzeniVyberu');"/>
                            
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</template>
<script>
import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxDateRangeBox from 'devextreme-vue/date-range-box';
import DxTabs from 'devextreme-vue/tabs';
import PrintVariants from '@/components/PrintVariants.vue';
import WarningImage from '@/components/WarningImage.vue';
export default {
    name: "CarsComponent",

    components: {
        DxTextBox,
        DxTabs,
        PrintVariants,
        WarningImage,
        DxNumberBox,
        DxDateRangeBox
    },
    props: {
        SmazatVozidloEnabled: {
            type: Boolean,
            required: false,
            default: true,
        },
        Disabled: {
            type: Boolean,
            required: true,
        },
        CarID: {
            type: Number,
            required: false,
            default: 0
        },
        TripRequestID: {
            type: Number,
            required: false,
            default: 0
        },
        volaniTyp:{
            type: Number,
            required: false,
            default: 0, //0- default, 1- osobni informace, 2- pozadavky_prac_cesta
        },
        DatumCestyDo: {
            type: Date,
            required: false,

        }
        

    },


    data() {
        return {
            //  pattern: /^\d{}/i,
            PrevIndex: -5,
            pohonneHmoty: [],
            ZakladaniNoveVozidlo: false,
            propchanged: false,
            selectedVozidloIndex: -1,
            vozidloID: null,
            prevVozidloID: null,
            prevTripID: null,
            prevDatumCestyDo: null,
            formDataCarUsage: {
                ridicskyprukazcislo: null,
                platnost_ridic_skoleni_do: null,
                platnost_lekar_prohlidka_do: null,
                cil: '',
                odhad_cesta_km: null,
                ucel_cesty: '',
                konani_od: null,
                konani_do: null,
                konani: null,
                spz: '',
                vyrobni_znacka: '',
                objem_valcu: null,
                jinyVlastnik: false,
                jinyvlastnik_jmeno: '',
                jinyvlastnik_prijmeni: '',
                phm_druh: null,
                spotreba: null,
                STK_platnost: null,
                povinne_pojistovna: '',
                povinne_cislo_pojistky: '',
                povinne_od: null,
                povinne_do: null,
                havarijni_pojistovna: null,
                havarijni_cislo_pojistky: '',
                havarijni_od: null,
                havarijni_do: null,
                pozadavek_cislo: null,
                vykricnik3: false,
                vykricnik4: false,
                vykricnik5: false,

            },
            spzZamestnance: [],
            vozidla: [],

            zobrazEditaci:false,
            zobrazButtonsPridaniVozidla:false,
            autovybrano: false,
            zobrazitTlacitka: false,
            ReadOnlyState:true,
            zobrazitInformace:false,
            ReadOnlySPZ:true,
            zobrazitEditSPZIcon:true,
            podminkaNenacteni:true,
           
            

        }
    },
    computed: {

    },
    watch: {
        CarID(newVozidloIndex) {
            this.handleParentPropChange(newVozidloIndex);
        },
        TripRequestID(newTripRequestID) {
            this.handleParentPropChangeTrip(newTripRequestID);
        },
        DatumCestyDo(newDatumCestyDo) {
            this.handleParentPropChangeDatumCestyDo(newDatumCestyDo);
        },
       /* 'formDataCarUsage.jinyVlastnik': function(newVal, oldVal) {
     // console.log(`Value changed from "${oldVal}" to "${newVal}"`);console.log(newVal);
      if (newVal === true){
        this.changeBackgroundColorByClass('canBeEdited','var(--colorByThemeHalfOpacity)')
        console.log('TEST PRUCHODU');
      }}*/
      
      // Execute your desired JavaScript method or code here
      // Call your method here
      // yourMethod(newVal);
    },
    created() {
        this.GetCars();
    },
    mounted() {
        /*if (this.CarID != 0) {
            this.SetSPZ(this.CarID)
        }*/
        this.$log.debug('CARSssssssssssssssssssssssssss')
        this.$log.debug('Datum cesty do'+ this.DatumCestyDo)
        let self = this;
        this.PHM();
        this.$log.debug(this.TripRequestID);
        this.zobrazEditaci=true;
        this.zobrazButtonsPridaniVozidla=false;
        this.autovybrano=false;
        this.ReadOnlyState=true;
        this.ReadOnlySPZ=true;
        this.zobrazitEditSPZIcon=false;
        if (this.formDataCarUsage.spz!=''){this.zobrazDetailAutoVybrano=true} else {this.zobrazDetailAutoVybrano=false}
        setTimeout(() => {
            this.$nextTick(() => {
            self.LoadBarvyTheme();

    });}, 500);
        
        
     
        /*this.$nextTick(() => {
        const dxCheckBox = this.$refs.myCheckBox;
        if (dxCheckBox) {
            dxCheckBox.on('change', this.handleJinyVlastnikChange);
        }
  });*/
    },

    methods: {
        ValidateDatesBusinessTrip() {
            this.$log.debug('Validace Datumu ')
            this.$log.debug(this.DatumCestyDo)
            this.$log.debug(this.formDataCarUsage.STK_platnost < this.DatumCestyDo)
            if (this.formDataCarUsage.STK_platnost < this.DatumCestyDo) this.formDataCarUsage.vykricnik3 = true
            else this.formDataCarUsage.vykricnik3 = false
            if (this.formDataCarUsage.povinne_do < this.DatumCestyDo) this.formDataCarUsage.vykricnik4 = true
            else this.formDataCarUsage.vykricnik4 = false
            if (this.formDataCarUsage.havarijni_do < this.DatumCestyDo) this.formDataCarUsage.vykricnik5 = true
            else this.formDataCarUsage.vykricnik5 = false
        },
        LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
            var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
            var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
            var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.1)");
            //console.log('Background Color:', FillColor);
            document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
            document.documentElement.style.setProperty('--colorByThemeHalfOpacity', rgbaColorTheme);

            var elementBarvaTextu = document.getElementsByClassName('dx-widget')[0];
            var computedBarvaTextu = window.getComputedStyle(elementBarvaTextu);
            var colorBarvaTextu = computedBarvaTextu.color;
            this.barvaTextu = computedBarvaTextu.color;
            //console.log('Background Color:', colorBarvaTextu);
            document.documentElement.style.setProperty('--colorTextByTheme', colorBarvaTextu);
        },
        /*handleJinyVlastnikChange(eventArgs) {
      // This method is called when the value of jinyVlastnik changes
      const newValue = eventArgs.value;
      console.log(`Value changed to: ${newValue}`);

      if (newValue === true) {
        this.changeBackgroundColorByClass('canBeEdited', 'var(--colorByThemeHalfOpacity)');
      } else {
        this.changeBackgroundColorByClass('canBeEdited', ''); // Remove the class or reset styles
      }
    },*/
        Action(i) {
            this.$log.debug('volano action')
            switch (i) {
                case 'Editovat':{
                this.changeBackgroundColorByClass('canBeEdited','var(--colorByThemeHalfOpacity)')
                this.zobrazEditaci=false; 
                this.zobrazitTlacitka=true; 
                this.ReadOnlyState=false;
                this.zobrazitEditSPZIcon=true;
                } break;
                case 'PotvrzeniVyberu' : {
                let self = this;
                if(this.formDataCarUsage.spz!=''){
                    this.APISetCar();
                    notify({
                                message: self.$t('Vozidla.PridanoDoCesty'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 10000);
                } else{
                    notify({
                                message: self.$t('Vozidla.VyberteVozidlo'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'error', 5000);
                }   
                this.autovybrano=false;
                this.changeBackgroundColorByClass('canBeEdited','')
                this.changeBackgroundColorByClass('canBeEditedSPZ','')
                } break;
                case 'Zrusit':{
                    this.SetDefaultCar();   
                    this.zobrazitTlacitka=false; 
                    this.changeBackgroundColorByClass('canBeEdited','');
                    this.changeBackgroundColorByClass('canBeEditedSPZ','');
                    this.GetCars();
                    this.zobrazEditaci=true;
                } break;
                case 'SmazatVozidlo':{
                    this.DeleteCar(); 
                    this.zobrazEditaci=true;  
                    this.zobrazitTlacitka=false;
                    this.changeBackgroundColorByClass('canBeEdited','')
                    this.changeBackgroundColorByClass('canBeEditedSPZ','')
                } break;
                case 'SPZ':{
                    this.ReadOnlySPZ=!this.ReadOnlySPZ;
                    if (this.ReadOnlySPZ === false) {
                    this.changeBackgroundColorByClass('canBeEditedSPZ','var(--colorByThemeHalfOpacity)') 
                    } else {
                    this.changeBackgroundColorByClass('canBeEditedSPZ','')   
                    }
                }break;
                case 'NoveAuto':{
                    this.PridaniNovehoAuto();
                      
                } break;
                case 'InfoSpotreba':{
                    let self = this;
                    notify({
                            message: self.$t('Vozidla.VyplnteSpotrebuZTechnickehoPrukazu'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                } break;
            }

        },
        async PridaniNovehoAuto(){
            await this.NewCar();
            await this.PridatZaznam();
         /*    const eventData = {
                itemData: {
                    statusZakladni: 1
                }
                }; */
            //  await this.TabsItemClick(eventData);  
                    this.zobrazEditaci = false;
                    this.zobrazButtonsPridaniVozidla = true;
                    this.changeBackgroundColorByClass('canBeEdited', 'var(--colorByThemeHalfOpacity)')
                    this.changeBackgroundColorByClass('canBeEditedSPZ', 'var(--colorByThemeHalfOpacity)')
                    this.ReadOnlySPZ = false;
                    this.ReadOnlyState = false;
                    this.zobrazitTlacitka = true;
                    this.zobrazitEditSPZIcon=false;
                    this.zobrazitInformace = true;
                    this.autovybrano=true;
                    this.zobrazDetailAutoVybrano=true;
                 //   this.changeBackgroundColorByClass('canBeEdited','');
                  //  this.changeBackgroundColorByClass('canBeEditedSPZ','')
                  //  this.ReadOnlyState=true;
               //     this.ReadOnlySPZ=true;
                    
        },
        changeBackgroundColorByClass(className, newColor) {
        const elements = document.getElementsByClassName(className);
          for (let i = 0; i < elements.length; i++) {
              elements[i].style.backgroundColor = newColor;
          }
      },
        SetDefaultCar(){
            this.SetSPZ('defaultprvni');
        },
        
        PHM() {
            let self = this;
            self.pohonneHmoty.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('TypyPHM', '', 1, 25,
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.pohonneHmoty.length;

                        self.pohonneHmoty[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    self.$log.debug('phm hotovo')
                    self.$log.debug(self.$refs.selectboxphm.instance.getDataSource())
                    var phm = this.formDataCarUsage.phm_druh
                    self.$refs.selectboxphm.instance.reset()
                    self.$refs.selectboxphm.instance.option('value', parseInt(phm))
                    self.$log.debug(self.$refs.selectboxphm.instance.option('value'))
                    
                }, window.this.ApiError());
        },
        TabsItemClick(e) {
            this.$log.debug(e)
            if (e.itemData != undefined && e.itemData.statusZakladni != undefined && e.itemData.statusZakladni == 1) {
                this.zobrazEditaci = false;
                this.zobrazButtonsPridaniVozidla = true;
                this.changeBackgroundColorByClass('canBeEdited', 'var(--colorByThemeHalfOpacity)')
                this.changeBackgroundColorByClass('canBeEditedSPZ', 'var(--colorByThemeHalfOpacity)')
                this.ReadOnlySPZ = false;
                this.ReadOnlyState = false;
                this.zobrazitTlacitka = true;
                this.zobrazitEditSPZIcon = false;
                this.zobrazitInformace = true;
                this.autovybrano = true;
                this.zobrazDetailAutoVybrano = true;
                this.NewCar();
                
                return 1;
            } else { this.zobrazEditaci = true; }            
            this.zobrazitInformace=true;
            this.autovybrano=true;
            this.zobrazDetailAutoVybrano=true;
            this.zobrazitTlacitka=false;
            this.changeBackgroundColorByClass('canBeEdited','');
            this.changeBackgroundColorByClass('canBeEditedSPZ','')
            this.ReadOnlyState=true;
            this.ReadOnlySPZ=true;
            
            if(this.volaniTyp == 2){
                this.SetSPZ(e);
                //this.zobrazEditaci=true; 
                if (!this.zobrazButtonsPridaniVozidla){
                setTimeout(() => {
                        this.$nextTick(() => {
                            this.Action('PotvrzeniVyberu');
                });}, 250);}
                this.zobrazButtonsPridaniVozidla=false;
                //this.$log.debug('TEST volaniTyp 2');
                
            }
            if(this.volaniTyp == 1) {
                this.SetSPZ(e);
                this.zobrazEditaci=true;
                this.zobrazButtonsPridaniVozidla=false;
                //this.$log.debug('TEST volaniTyp 1');
                //
            }
        
       
        
            
         
        },
        handleParentPropChange(newVozidloID) {
            this.propchanged = true;     
            this.$log.debug('Vozidlo prop changed:', this.prevVozidloID, '->', newVozidloID);
            this.prevVozidloID = newVozidloID;
            /*if (this.vozidla.length<1){
                setTimeout(() => {
                    this.handleParentPropChange(newVozidloID)
                }, 500);
                   // this.podminkaNenacteni=false;
                }
                else this.SetSPZ(newVozidloID)*/
                setTimeout(() => {
                    this.SetSPZ(newVozidloID)
                }, 750);
        
            
        },
        handleParentPropChangeTrip(newProp) {
            this.$log.debug('prop changed:', this.prevTripID, '->', newProp);
            this.APISetCar();
            this.prevTripID = newProp; 
        },
        handleParentPropChangeDatumCestyDo(newProp) {
            this.$log.debug('prop changed:', this.prevDatumCestyDo, '->', newProp);
            this.ValidateDatesBusinessTrip()
            this.prevDatumCestyDo = newProp;
        },
        PridatZaznam() {
            return new Promise((resolve) => {
                var novyZaznam = {
                text: this.$t('Vozidla.Zakladani'),
                x: this.vozidla.length,
                statusZakladni: 1,
            };
            this.vozidla.push(novyZaznam);
            this.$refs.tabs.instance.getDataSource().reload(); 
            this.selectedVozidloIndex = novyZaznam.x
            this.PrevIndex = novyZaznam.x
            this.ZakladaniNoveVozidlo = true;
            this.zobrazitInformace=false;          
                resolve(); 
            });
           
        },
        NewCar() {
            return new Promise((resolve) => {
                this.selectedVozidloIndex=-1
                this.formDataCarUsage.spz = null
                this.formDataCarUsage.objem_valcu = null
                this.formDataCarUsage.vyrobni_znacka = null
                this.formDataCarUsage.spotreba = null
                this.formDataCarUsage.jinyVlastnik = false
                this.formDataCarUsage.jinyvlastnik_jmeno = ''
                this.formDataCarUsage.jinyvlastnik_prijmeni = ''
                this.formDataCarUsage.STK_platnost = null
                this.formDataCarUsage.phm_druh = null
                this.formDataCarUsage.povinne_pojistovna = null
                this.formDataCarUsage.povinne_cislo_pojistky = null
                this.formDataCarUsage.povinne_od = null
                this.formDataCarUsage.povinne_do = null
                this.formDataCarUsage.havarijni_pojistovna = null
                this.formDataCarUsage.havarijni_cislo_pojistky = null
                this.formDataCarUsage.havarijni_od = null
                this.formDataCarUsage.havarijni_do = null      
                this.formDataCarUsage.vykricnik3 = false
                this.formDataCarUsage.vykricnik4 = false
                this.formDataCarUsage.vykricnik5 = false      
                resolve(); 
            });
            
        },
        SetSPZ(e) {
            this.$log.debug(e)
            this.$nextTick(() => {
            if ( e == 'defaultprvni') {
                if (this.vozidla.length == 0) { this.zobrazitInformace=false;}
                else { 
                    /*const prvniZaznam= {
                        itemIndex: 0
                        }; 
                    //this.GetCars(0);
                    //this.SetSPZ(prvniZaznam);*/
                    this.zobrazitInformace=true;
                }
            } else {
            this.$log.debug(e)
            var index = -1;
        if (typeof e === 'number') {
            // If e is a number (e.g., 206), find the index of the car with that Id
            index = this.vozidla.findIndex(vozidlo => vozidlo.Id == e);
            this.$log.debug(e);
            this.$log.debug('Hledáme indexové číslo vozidla');
            this.$log.debug(index);
        } else if (e.itemIndex !== undefined) {
            // If e has an itemIndex property, use it as the index
            index = e.itemIndex;
        }
                          
        this.$log.debug(index);
        
        if (index >= 0 && this.vozidla[index] && this.vozidla[index].Engine) {
            // Set the form data with car information based on the index
            // (Assuming this.vozidla is an array of car objects)

            this.PrevIndex = index;
            this.selectedVozidloIndex = index;
            this.selectedVozidloIndex = index
                this.formDataCarUsage.spz = this.vozidla[index].Spz
                this.formDataCarUsage.objem_valcu = this.vozidla[index].Engine
                this.formDataCarUsage.vyrobni_znacka = this.vozidla[index].Manufacturer
                this.formDataCarUsage.spotreba = this.vozidla[index].Consumption
                this.formDataCarUsage.jinyVlastnik = this.vozidla[index].OwnerFirstname != '' || this.vozidla[index].OwnerSurname != ''
                this.formDataCarUsage.jinyvlastnik_jmeno = this.vozidla[index].OwnerFirstname
                this.formDataCarUsage.jinyvlastnik_prijmeni = this.vozidla[index].OwnerSurname
                this.formDataCarUsage.STK_platnost = this.vozidla[index].STKDateTo
                this.formDataCarUsage.phm_druh = parseInt(this.vozidla[index].CisFuelType)
                this.formDataCarUsage.povinne_pojistovna = this.vozidla[index].PovinneInsurance
                this.formDataCarUsage.povinne_cislo_pojistky = this.vozidla[index].PovinneNumOfInsurance
                this.formDataCarUsage.povinne_od = this.vozidla[index].PovinneDateFrom
                this.formDataCarUsage.povinne_do = this.vozidla[index].PovinneDateTo
                this.formDataCarUsage.havarijni_pojistovna = this.vozidla[index].HavarijniInsurance
                this.formDataCarUsage.havarijni_cislo_pojistky = this.vozidla[index].HavarijniNumOfInsurance
                this.formDataCarUsage.havarijni_od = this.vozidla[index].HavarijniDateFrom
                this.formDataCarUsage.havarijni_do = this.vozidla[index].HavarijniDateTo
                this.ValidateDatesBusinessTrip();
        } else {
            this.NewCar();
        }
        
        if (this.volaniTyp == 2) {
            this.$log.debug('CarID je TOTO ');
            this.$log.debug(this.CarID);
            if (this.CarID != 0) {
                this.zobrazitInformace = true;
                this.$log.debug('CarID je nenulové --> zobrazuji informace');
                this.$log.debug(self.selectedVozidloIndex);
            } else if(this.CarID==0) {this.zobrazitInformace = true;
            } else {this.zobrazitInformace = false;}
            }
        }       
    });
        },
        GetCars() {
            var self = this
            var per = new window.Personal(window.channel, window.message);
            this.$log.debug(self.selectedVozidloIndex)
            var selectedindex= self.selectedVozidloIndex
            this.vozidla.length=0
            per.GetCars(0,
                function (result) {
                    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.vozidla[x] = {
                            x: x,
                            text: result.List.value.items[x].Licence_Plate.value,
                            Spz: result.List.value.items[x].Licence_Plate.value,
                            Id: result.List.value.items[x].ID_EvOfCars.value,
                            Actual: result.List.value.items[x].Actual.value,
                            Consumption: result.List.value.items[x].Consumption.value,
                            Engine: result.List.value.items[x].Engine_Content.value,
                            HavarijniDateFrom: result.List.value.items[x].Havarijni_DateFrom.value,
                            HavarijniDateTo: result.List.value.items[x].Havarijni_DateTo.value,
                            HavarijniInsurance: result.List.value.items[x].Havarijni_Insurance.value,
                            HavarijniNumOfInsurance: result.List.value.items[x].Havarijni_NumOfInsurance.value,
                            Manufacturer: result.List.value.items[x].Manufacturer.value,
                            OwnerFirstname: result.List.value.items[x].Owner_Firstname.value,
                            OwnerSurname: result.List.value.items[x].Owner_Surname.value,
                            PovinneDateFrom: result.List.value.items[x].Povinne_DateFrom.value,
                            PovinneDateTo: result.List.value.items[x].Povinne_DateTo.value,
                            PovinneInsurance: result.List.value.items[x].Povinne_Insurance.value,
                            PovinneNumOfInsurance: result.List.value.items[x].Povinne_NumOfInsurance.value,
                            STKDateTo: result.List.value.items[x].STK_DateTo.value,
                            Zamid: result.List.value.items[x].ZamId.value,
                            CisFuelType: result.List.value.items[x].cis_FuelType.value,
                        };

                    }
                    self.$log.debug(self.$refs)
                    self.$refs.tabs.instance.getDataSource().reload();
                    self.$refs.tabs.instance.option('selectedIndex', selectedindex )
                    self.selectedVozidloIndex = selectedindex
                    self.PrevIndex = selectedindex
                    /*if (self.propchanged) {
                        self.SetSPZ(self.prevVozidloID)
                        self.propchanged = false
                    }*/
                    self.ZakladaniNoveVozidlo = false
                    
                }, window.this.ApiError());
            this.zobrazEditaci=false;
            this.zobrazitTlacitka=false;
            
        },
        onFormSubmit(e) {
            e.preventDefault();
            let self = this;
        //    var tresholdPozadavek;
            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e)
           
            if (e.submitter.attributes[0].ownerDocument.activeElement.id == "ulozit") {
                self.APISetCar();
                self.zobrazitEditSPZIcon=false;
                self.ReadOnlyState=true;
            }
            if (e.submitter.attributes[0].ownerDocument.activeElement.id == "pridat") { // tohle je treba upravit
              //  tresholdPozadavek = self.TripRequestID;
                //this.handleParentPropChangeTrip(0);  
                self.zobrazitEditSPZIcon=false; 
                self.zobrazitInformace=false;
                //this.handleParentPropChangeTrip(tresholdPozadavek);
                this.APISetCar();
                //self.APISetCar();
                        
            }


        },
        APISetCar() {
            let self = this;
            var pers = new window.Personal(window.channel, window.message);
            window.this.$log.debug('APISetCar')
            window.this.$log.debug(this.TripRequestID)
            window.this.$log.debug(self.formDataCarUsage)
            pers.SetCar(self.TripRequestID, self.formDataCarUsage.spz, self.formDataCarUsage.vyrobni_znacka,
                self.formDataCarUsage.objem_valcu, self.formDataCarUsage.phm_druh, self.formDataCarUsage.spotreba, self.formDataCarUsage.STK_platnost,
                self.formDataCarUsage.jinyvlastnik_jmeno, self.formDataCarUsage.jinyvlastnik_prijmeni, self.formDataCarUsage.povinne_pojistovna,
                self.formDataCarUsage.povinne_cislo_pojistky, self.formDataCarUsage.povinne_od, self.formDataCarUsage.povinne_do, self.formDataCarUsage.havarijni_pojistovna,
                self.formDataCarUsage.havarijni_cislo_pojistky, self.formDataCarUsage.havarijni_od, self.formDataCarUsage.havarijni_do, function (result) {
                    window.this.$log.debug(result);
                    window.this.$log.debug('APISetCar');
                    if (result.Error.value.ErrorNum.value == 0) {
                        self.GetCars()
                        if (self.zobrazButtonsPridaniVozidla==true){ setTimeout(() => {
                                    self.TabsItemClick(result.Value.value)}, 1000);}
                        
                        if (self.zobrazButtonsPridaniVozidla==false) {
                            notify({
                            message: self.$t('Vozidla.ZmenyUlozeny'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);} else {
                            notify({
                            message: self.$t('Vozidla.VozidloPridano'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                        }
                        self.zobrazEditaci=true;
                        self.changeBackgroundColorByClass('canBeEdited','')
                        self.changeBackgroundColorByClass('canBeEditedSPZ','')
                    }
                    
                }, window.this.ApiError());
                

        },
        DeleteCar() {
            let self = this;
            var pers = new window.Personal(window.channel, window.message);
            const spz = this.formDataCarUsage.spz
            window.this.$log.debug(this.vozidla[this.selectedVozidloIndex]); 
            if (this.vozidla[this.selectedVozidloIndex] !=undefined && this.selectedVozidloIndex != -1 && this.vozidla[this.selectedVozidloIndex].Spz == undefined) {
                self.GetCars()
                 notify({
                    message: self.$t('Vozidla.VozidloSmazano'),
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'success', 5000);
            }
            else
            pers.DeleteCar(spz, function (result) {
                    window.this.$log.debug(result);
                    if (result.ErrorNum.value == 0) {
                        self.GetCars()
                        self.NewCar()
                        notify({
                        message: self.$t('Vozidla.VozidloSmazano') + " (SPZ: "+ spz +')',
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'success', 5000);
                }
                    else {
                        notify({
                        message: result.ErrorTextCZ.value,
                        position: {
                            my: 'center top',
                            at: 'center top',

                        },
                    }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }

                }, window.this.ApiError());

        },
    }
}

</script>



<style scoped> 
:deep(.dx-daterangebox .dx-dropdowneditor-icon::before){
    content: "\f026";
    
}
:deep(.dx-icon-to::before) {
    content: "\f00e";
}
:root {
  --colorByTheme: rgb(0, 128, 255);
  --colorTextByTheme: rgb(0, 128, 255);
}
.button1{
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
}
@media (max-width: 600px) {
    #newcar{
    border-radius: 0;
    width: 28.5px !important;
} 
.dx-item .dx-tab { background: black  !important;}
}
#newcar{
    border-radius: 0;
}
#SpodniMenu{display:flex; 
    flex-direction: row;
    flex-wrap: nowrap;
    border-style: solid;
    border-color: rgba(66, 66, 66, 0.5);
    border-left: 0;
    border-right: 0;
    border-top: 0;
   border-bottom: 0;
   border-width: 1px;
   border-radius: 2px;
    background-color: rgba(80, 80, 80, 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width:100%;
    margin-top: 250px;
    margin: 2px; 
    position: sticky;
    position: -webkit-sticky;
    bottom: 0.5rem;
}
.childSpodniMenu{float: left;padding-top: 10px;margin-bottom: 10px; border:solid 1px transparent}
 .CarBoxItem {
     padding: 5px;
 }
 .CarBoxItemNadpis{
    padding-left: 5px;
    
 }
 .inlineflex{
    display: inline-flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: normal;
 }
 #labelalignedcenter{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    padding-left: 5px;
}
#CarsResponsiveBox{
    width: 100%;
}
#divTabVozidla{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 5px 5px 5px;
    padding-top: 5px;
}
@keyframes content {
   from {
    opacity: 0;
    transform: translateY(5%);
	}
   to {
    opacity: 1;
    transform: translateY(0%);
	}
}
#divJinyVlastnikParent{
    display: block;
   
}
.kratkapole .dx-placeholder{
    text-align: center;  
}/*h4.h4centered{text-align: center;}*/
h4.h4left{text-align: left;}

.textNeeditovatelny{
    height:"20px";
    display: inline-flex;
    border: 1px solid; border-color:rgba(211, 211, 211, 0.452); 
}
p.pObsah{   
    width: 100%; border: 1px solid; border-color: var(--colorByTheme); text-align: left; padding-top: 5px; padding-left: 5px ; height: 30px;}

/*.clResponsiveBordered{border: 1px solid; border-color: var(--colorByTheme); border-radius: 10px; margin-top: 25px; margin-bottom: 25px;}*/
.dx-item .dx-tab .dx-tab-selected{color: var(--colorByTheme) !important; font-weight: bold !important;}
.dx-tabs-wrapper{color: var(--colorByTheme) !important; font-weight: bold !important;}
p.pNazev{padding-top: 5px;}
#btnSpzEdit,#btnInfoSpotreba{align-self:center; order: 1; margin-right: 0px;}

.dotted-black{border: 1px dashed currentColor; padding-left: 5px; padding-right: 5px;}
/*#btnPrintVariants{background-color: var(--colorByTheme); }*/
.buttonUlozitZmeny:hover,.buttonZalozitVozidlo:hover,.buttonPotvrzeniVyberu:hover,.buttonKEditaci:hover,.buttonSmazatVozidlo:hover,.buttonZrusit:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
}
.buttonUlozitZmeny,.buttonZalozitVozidlo,.buttonPotvrzeniVyberu{
    background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    color:black;
    border: 0px; 
}
.buttonSmazatVozidlo,.buttonZrusit{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.CarBoxWithWarning{
    display:flex;
    flex-direction:row !important;
}
</style>
    
