
<template>
<div class="clNadpisAktualniStranky" height="50px">
    <h2 class="prevent-select nadpisyAktualne nadpisH2Moduly" id="Nastenka">{{ $t('aktualne.Nastenka') }}</h2>
</div>
<div>
    
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000697301?lang=cs" 
                   URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000697301?lang=en" 
                PopisLinku='clanky.seznamFciAlbiny'></HelpLink>
    <div id="">
       <div class="clPadLeft5">
        <div id="dashboard" >
            <DxScrollView direction="horizontal" showScrollbar="always" >
                <ul id="list" class="ulRychloProkliky">
                    <li v-for="item in Dashboard" :key="item" class="child" id="dashboarditem" @click="Redirect(item)" >
                        <span v-if="item.index == 0 && PocetKeSchvaleni == 0" id="pocetPozadavku" class="prevent-select pocetPozadavkuGreen">{{ PocetKeSchvaleni }}</span>
                        <span v-if="item.index != 0 && item.Count==0" id="pocetPozadavku" class="prevent-select pocetPozadavkuGreen">{{item.Count}}</span>
                        <span v-if="item.index == 0 && item.Count != 0 && PocetKeSchvaleni!=0" id="pocetPozadavku" class="prevent-select">{{ PocetKeSchvaleni }}</span>
                        <span v-if="item.index != 0 && item.Count != 0" id="pocetPozadavku" class="prevent-select">{{item.Count}}</span>
                        <p id="popisPozadavku" class="prevent-select">{{item.Description}}</p>
                    </li>
                </ul>
                </DxScrollView>
        </div>
        <DxScrollView direction="horizontal" showScrollbar="always" >
         <div id="wrapperapprovedashboard">
            <div v-for="item in ApproveDashboard" :key="item" class="clParApprovedashboard" >
                <div class="dx-button-mode-contained approvedashboard " :id="'approvedashboard'+item.index" v-if="item.index<this.VisibleIndex && item.visible==true">
                        <div id="htmlObsahNadpis">
                            <label id="htmlObsahNadpisLabel" v-html="item.value"></label> 
                            <DxButton id="htmlObsahNadpisBTN" height="auto" width="auto" type="normal" icon="info" styling-mode="text" @click="this.$router.push('/Pozadavky?Vypis=1&Stav=0&' + 'Pozid=' + item.id + '&Typ=' + item.typ+'&Return=Aktualne')" :hint="$t('central-DetailPozadavku')"></DxButton> 
                        </div>
                        <td id="htmlObsahDetail"  v-html="item.detail"></td>
                        <br>
                        <div id="buttonsSpodniLista">
                        <div id="buttonsSchvalovani">
                            <DxButton height="auto" :text="$t('central-Schvalit')" type="success" icon="todo" styling-mode="contained" id="schvalit" @click="SetState(1,item)"
                                            :use-submit-behavior="true" class="SchvalovaniKomentarButtons buttonKeSchvaleni" />
                            <DxButton height="auto" :text="$t('central-Vratit')" type="default" icon="revert" id="vratit" @click="SetState(3, item) "
                                            :use-submit-behavior="true" class="SchvalovaniKomentarButtons buttonKEditaci" />
                            <DxButton height="auto" :text="$t('central-Zamitnout')" type="danger" icon="close" styling-mode="contained" id="zamitnout"
                                            @click="SetState(2, item)" :use-submit-behavior="true" class="SchvalovaniKomentarButtons buttonSmazat" />
                        </div>
                        <div id="buttonsSkryt">
                            <DxButton height="auto" :text="$t('central-Skryt')" type="default" styling-mode="contained" id="skryt"
                            @click="Animation('hide', item, 'approvedashboard'); HideFromApproveDashboard(item.id) " icon="clearformat" :use-submit-behavior="true" class="SchvalovaniKomentarButtons buttonUlozitProUpravy" />
                        </div>
                        </div>
                    </div>
             </div> 
            </div>
        </DxScrollView>
    </div>
        <hr class="hrModuly clAktualne">
        <div class="TEST">
            <div class="clNadpisAktualniStranky clAktualne" height="50px">
            <h2 class="nadpisyAktualne prevent-select nadpisH2Moduly" width="auto" id="Aktualne">{{$t('aktualne.Aktualne')}}</h2>
                <span style="margin-top: 4px" id="btnAktualneEdit" class="TEST" > <!-- || NovinkaPersonalPrijemci Tuto část podmínky nemáme ošetřenou na API-->
                    <span  v-if="editNews || NovinkaPersonalPrijemci" v-bind:title="$t('aktualne.pridatNovinku')">
                        <DxButton @click='vytvareniNoveNovinky();this.stavAktualneEditoru=true; ' height="auto" width="auto" type="default" styling-mode="outlined" icon="tableproperties" :text="$t('central-PridatClanek')" :visible="true"></DxButton> 
                    </span>
                </span>
                <span id="flexHistorie" style="margin-top: 4px">
                    <span  v-bind:title="$t('aktualne.historieNovinek')">
                        <DxButton id="btn1Historie"   @click="HistorieNovinekRedirect()" height="auto" width="auto" type="default" styling-mode="outlined" icon="overflow" :text="$t('aktualne.historieNovinek')" :visible="true" ></DxButton> 
                    </span>
                </span>
        </div>
    </div>
    
</div> 
<br>
        <div id="AktualneContainer">
            <div id="novinkyParent" class="novinkyClass" width="100%">
                <div v-for="item in News" :key="item" class="novinkyItemCely" :Budouci="item.DateFrom.getTime()>new Date(Date.now())" >
                    <div :typOhraniceni="item.Personal" :key="item" class="newsitem" :id="'article'+item.ID"  v-if="item.visible==true">
                            <div id="hlavickaNovinky" style="height: 50px;">
                                <div v-if="item.State == 0 && item.ForConfirmation" id="buttons" style="display: inline-block;" >    
                                    <DxButton class="buttonnewsitem"  icon="todo" :text="$t('aktualne.Prectena')+item.Text" @click="SetNewsState(item.ID, 1, false);" :type="item.ButtonType" > </DxButton>
                            <!--         <span v-if="item.DaysToConfirm>=0 " style="color: rgb(255, 153, 0); font-weight: bold; font-size: 15px; line-height: 40px;  white-space:nowrap;" >{{ $t('aktualne.ZpravaPotvrdit') + item.DaysToConfirm }}</span>                                 
                                    <span  v-else style="color: red; font-weight: bold; font-size: 15px; line-height: 40px;  white-space:nowrap;">{{ $t('aktualne.ZpravaPromeskana') }}</span> 
                                    -->
                                </div>    
                                <div v-else >
                                        <DxButton class="buttonnewsitem"  icon=""  :text="$t('central-Skryt')" @click="SetNewsState(item.ID, 2, true); Animation('hide', item, 'article');"  > </DxButton>
                                </div>  
                                <div v-if=" item.MyOwnNews || editNews" style="float:right; padding-top:5px;"  >
                                    <DxButton class="editnewsitem" icon="edit"  :hint="$t('central-UpravitNovinku')" @click="nactiNovinkuKEditaci(item.ID); this.ClanekID= item.ID; this.$log.debug(item.ID);this.stavAktualneEditoru=true; " > </DxButton>
                                </div>            
                                <span style="color:inherit; font-style:italic; font-size: 15px; line-height: 45px;  white-space:nowrap; float:right; padding-right: 5px;" >{{  item.OwnerTXT+", "+item.DateFrom.toLocaleDateString()}}</span>   
                            </div>
                            <div id="htmlObsah" class="dynamic-content">
                                <DxScrollView direction="both" showScrollbar="always" >
                                <div></div>
                                <div v-html="item.Content"></div>
                                <div></div>
                                </DxScrollView>
                            </div>
                        </div>
                    
                    </div>
                    <br>
                </div>
            
        </div>
        <DxPopup id="NovinkyHistorie" v-model:visible="popupNewsHistory" :show-title="true" :drag-enabled="false" :hide-on-outside-click="true"
                    :show-close-button="true" :title="$t('aktualne.historieNovinek')">
                    <DxScrollView class="dxScrollViewPozadavky" direction="both" showScrollbar="always" ref="dxScrollViewPopupObjZasobovani"> 
                    <div id="AktualneContainer">
                    <div  class="novinkyClass">
                       <!-- <FsLightbox :images="images" :index="selectedIndex" :show="lightboxVisible" @close="closeLightbox"></FsLightbox>-->
                        <div v-for="item in NewsHistory" :key="item" class="novinkyItemCely" :Budouci="item.DateFrom.getTime() > new Date(Date.now())">
                            <DxScrollView direction="both" showScrollbar="always" > 
                            <div :typOhraniceni="item.Personal" :key="item" class="newsitem" :id="'article' + item.ID"  v-if="item.visible == true">
                                    <div id="hlavickaNovinky" style="height: 50px;">   
                                    <!-- <DxButton class="buttonnewsitem"  icon=""  :text="$t('central-Obnovit')" @click="SetNewsState(item.ID, 0, true); Animation('hide', item, 'article');" > </DxButton> -->      
                                        <span style="color:inherit; font-style:italic; font-size: 15px; line-height: 45px;  white-space:nowrap; float:right; padding-right: 5px " >{{ item.OwnerTXT + ", " + item.DateFrom.toLocaleDateString() }}</span>   
                                    </div>
                                    
                                    <div id="htmlObsah" width="auto" >
                                        <div class="htmlDivHtmlObsah" v-html="item.Content" ref="htmlContentContainer"></div>
                                    </div>
                                    
                                </div>
                            </DxScrollView>
                            </div>
                            <br>
                        </div>
            
                </div>
                </DxScrollView>
        </DxPopup>
        <!-- 
-->  
        <DxPopup v-if="stavAktualneEditoru" id="PopupAktualneEditor" v-model:visible="stavAktualneEditoru"  :show-title="true"  :drag-enabled="false"
                            :hide-on-outside-click="false" :show-close-button="true" :title="$t('aktualne.PridatNovinku')" 
                            width="95%" height="95%"  :focusStateEnabled="true" :shading="true"  titleTemplate="title"> 
                            <DxPosition
                                at="center"
                                my="center"
                                collision="fit" />
            <DxScrollView direction="vertical" showScrollbar="always" ><!--stavAktualneEditoru to visibility-->  
                <DxForm :form-data="formData" v-model="formData" id="NovinkyEditorForm">
                    <DxGroupItem :col-count="1" css-class="first-group">
                        <DxGroupItem css-class="test-stylu" :col-span="5" :col-count="5">
                        
                            <DxSimpleItem  :col-span="2" data-field="typPersonal"  >
                                <DxLabel  :text="$t('aktualne.Typ')"/>
                                <div v-if="editNews" >
                                <dxSelectBox :dataSource='novinkyTyp' display-expr="name" value-expr="idtyp" :value="formData.Personal"  
                                @value-changed ="naplneniFormDataPersonal"/></div>
                                <div v-else>
                                <dxSelectBox :dataSource='novinkyTyp' display-expr="name" value-expr="idtyp" :value="formData.Personal"  
                                @value-changed ="naplneniFormDataPersonal" :disabled="true"/></div>
                            </DxSimpleItem>
                            <DxSimpleItem :col-span="2"  data-field="" editorType="dxTagBox" :visible="formData.Personal == 1">
                                
                                <DxLabel :text="$t('aktualne.Komu')"/>
                                    <DxTagBox 
                                            :showSelectionControls="true"
                                            :data-source="zamestnanci"
                                            display-expr="content"
                                            value-expr="id"
                                            :selectedItems="selectedzamestnanci"
                                            ref="tagBoxA"
                                            :placeholder="$t('aktualne.Vyberte')"
                                            @selectionChanged="PrijemciKNovinky"
                                            >
                                            
                                    </DxTagBox>
                            </DxSimpleItem>
                            
                        </DxGroupItem>

                        <DxGroupItem :col-span="5" :col-count="5"  css-class="second-group">
                            <DxSimpleItem  data-field="DatumOd" editor-type="dxDateBox" :editor-options="dateBoxOptions">
                                <DxLabel :text="$t('aktualne.DatumOd')"/>
                                <DxRequiredRule />
                            </DxSimpleItem>
                            <DxSimpleItem ref="ToDateBox" data-field="DatumDo" editor-type="dxDateBox" :editor-options="ToDateDateBoxOptions">
                                <DxLabel :text="$t('aktualne.DatumDo')"/>
                                <DxRequiredRule />
                            </DxSimpleItem>
                            <DxSimpleItem data-field="DatumPotvrzeni" editor-type="dxDateBox" :editor-options="ConfirmationDateBoxOptions" >
                                <DxLabel :text="$t('aktualne.DatumPotvrzeni')"/>
                            </DxSimpleItem>
                        </DxGroupItem>
                        
                    <DxGroupItem css-class="editory-group">
                        <DxSimpleItem data-field="ClanekVCj"  ref="contentEditor" >
                            
                            <DxLabel :text="$t('aktualne.ClanekVCj')"/>
                            <DxHtmlEditor
                        :value="formData.ClanekVCj"
                        height="500px"
                        :placeholder="$t('aktualne.PlaceholderCZ')"
                        
                        ref="ClanekCJ"
                        
                        >
                        <DxMediaResizing :enabled="true"/>
                        <DxToolbar :multiline="false">
                            <DxItem name="undo"/>
                            <DxItem name="redo"/>
                            <DxItem name="separator"/>
                            <DxItem
                            :accepted-values="sizeValues"
                            name="size"
                            />
                            <DxItem
                            :accepted-values="fontValues"
                            name="font"
                            />
                            <DxItem
                            
                            name="header" :accepted-values="acceptedValuesHeader" 
                
                            />
                            <DxItem name="separator"/>
                            <DxItem name="bold"/>
                            <DxItem name="italic"/>
                            <DxItem name="strike"/>
                            <DxItem name="underline"/>
                            <DxItem name="separator"/>
                            <DxItem name="alignLeft"/>
                            <DxItem name="alignCenter"/>
                            <DxItem name="alignRight"/>
                            <DxItem name="alignJustify"/>
                            <DxItem name="separator"/>
                            <DxItem name="orderedList"/>
                            <DxItem name="bulletList"/>
                            <DxItem name="separator"/>
                            <DxItem name="separator"/>
                            <DxItem name="color"/>
                            <DxItem name="background"/>
                            <DxItem name="separator"/>
                            <DxItem name="link"/>
                            <DxItem name="image"/>
                            <DxItem name="separator"/>
                            <DxItem name="clear"/>
                            <DxItem name="codeBlock"/>
                            <DxItem name="blockquote"/>
                            <DxItem name="separator"/>
                            <DxItem name="insertTable"/>
                            <DxItem name="deleteTable"/>
                            <DxItem name="insertRowAbove"/>
                            <DxItem name="insertRowBelow"/>
                            <DxItem name="deleteRow"/>
                            <DxItem name="insertColumnLeft"/>
                            <DxItem name="insertColumnRight"/>
                            <DxItem name="deleteColumn"/>
                        </DxToolbar>
                    </DxHtmlEditor>
                    </DxSimpleItem>

                    <DxSimpleItem data-field="ClanekVAj"  ref="contentEditorEN" >
                        <DxLabel :text="$t('aktualne.ClanekVAj')" />
                    <DxHtmlEditor
                        :value="formData.ClanekVAj"
                        height="500px"
                        :placeholder="$t('aktualne.PlaceholderEN')"
                        
                        ref="ClanekAJ"
                        >
                        <DxMediaResizing :enabled="true"/>
                        <DxImageUpload
                            
                            file-upload-mode="base64"
                        />
                        <DxToolbar :multiline="false">
                            <DxItem name="undo"/>
                            <DxItem name="redo"/>
                            <DxItem name="separator"/>
                            <DxItem
                            :accepted-values="sizeValues"
                            name="size"
                            />
                            <DxItem
                            :accepted-values="fontValues"
                            name="font"
                            />
                            <DxItem
                            name="header" :accepted-values="acceptedValuesHeader"
                            />
                            <DxItem name="separator"/>
                            <DxItem name="bold"/>
                            <DxItem name="italic"/>
                            <DxItem name="strike"/>
                            <DxItem name="underline"/>
                            <DxItem name="separator"/>
                            <DxItem name="alignLeft"/>
                            <DxItem name="alignCenter"/>
                            <DxItem name="alignRight"/>
                            <DxItem name="alignJustify"/>
                            <DxItem name="separator"/>
                            <DxItem name="orderedList"/>
                            <DxItem name="bulletList"/>
                            <DxItem name="separator"/>
                            <DxItem name="separator"/>
                            <DxItem name="color"/>
                            <DxItem name="background"/>
                            <DxItem name="separator"/>
                            <DxItem name="link"/>
                            <DxItem name="image"/>
                            <DxItem name="separator"/>
                            <DxItem name="clear"/>
                            <DxItem name="codeBlock"/>
                            <DxItem name="blockquote"/>
                            <DxItem name="separator"/>
                            <DxItem name="insertTable"/>
                            <DxItem name="deleteTable"/>
                            <DxItem name="insertRowAbove"/>
                            <DxItem name="insertRowBelow"/>
                            <DxItem name="deleteRow"/>
                            <DxItem name="insertColumnLeft"/>
                            <DxItem name="insertColumnRight"/>
                            <DxItem name="deleteColumn"/>
                        </DxToolbar>
                            </DxHtmlEditor>
                        </DxSimpleItem>
                        </DxGroupItem>
                        
                        
                        <DxSimpleItem>
                            <br>
                            <DxButton height="auto" :text="$t('aktualne.ZverejnitClanek')" type="success" styling-mode="contained" id="schvalit" 
                            @click=" if(this.$refs.tagBoxA == null) var tagbox=0 
                                               else tagbox = this.$refs.tagBoxA.instance.option('selectedItems')  ;EditaceNovinky(formData,0, tagbox )"
                                                                    :use-submit-behavior="true" class="SchvalovaniKomentarButtons" />

                        
                            <DxButton  v-if="SmazatelnostNovinka" height="auto" :text="$t('aktualne.SmazatClanek')" type="danger" styling-mode="contained" id="smazat"
                             @click="if (this.$refs.tagBoxA == null) var tagbox = 0
                             else tagbox = this.$refs.tagBoxA.instance.option('selectedItems'); EditaceNovinky(formData, 1, tagbox )"
                                                                    class="SchvalovaniKomentarButtons" />
                            </DxSimpleItem>
                    </DxGroupItem>
                </DxForm>                      
            </DxScrollView>
        </DxPopup>
    </div>    
</template>




<script>

import DxTagBox from 'devextreme-vue/tag-box';
import data from '@/data/NovinkyTyp.js';
import notify from 'devextreme/ui/notify';
import HelpLink from '@/components/HelpLink.vue';
import {
DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxImageUpload,
  DxItem,
} from 'devextreme-vue/html-editor';

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
} from 'devextreme-vue/form';


const novinkyTyp = data.getNovinkyTyp();
export default {
    name: 'AktualneView',
    components: {       
        DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel,
        DxHtmlEditor,
        DxMediaResizing,
        DxToolbar,
        DxItem,
        DxImageUpload,
        DxTagBox,
        HelpLink,
         },
    data() {        
        return {
            ElPodpis: false,
            popupNewsHistory: false,
            PocetPruchodu: 0,
            PocetKeSchvaleni: 0,
            ApproveDashboard: [],
            Dashboard: [],
            News: [],
            NewsHistory: [],
            VisibleIndex: 3,
            stavAktualneEditoru: false,
            test: new Date,
            formData: {
                Personal: 1,
                DatumOd: new Date(),
                DatumDo: new Date(Date.now() + 24 * 60 * 60 * 1000*2),
                DatumPotvrzeni: null,
                ClanekVAj: "",
                ClanekVCj: "",
                Kategorie: 0,
                SchvalitDo: 0
            },
            reditel: false,
            ClanekID: 0,
            dateBoxOptions: { min: new Date()  },
            ToDateDateBoxOptions: { min: new Date(Date.now() + 24 * 60 * 60 * 1000), onValueChanged: this.DateToDateBoxValueChanged },
            ConfirmationDateBoxOptions: { min: new Date(Date.now() + 24 * 60 * 60 * 1000), max: new Date(Date.now() + 24 * 60 * 60 * 1000*100), showClearButton: true, onInitialized: this.ConfirmationDateBoxInit   },
            novinkyTyp,
            editNews:false,
            vlastniNovinka:false,
            zamid:0,
            zamestnanci:[],
            selectedzamestnanci:[],
            SmazatelnostNovinka:true,
            NovinkaPersonalPrijemci: false,
            ZobrazeniPrijemci:false,
            ConfirmationDateBoxComponent: '',
            acceptedValuesHeader:[
                1,
                2,
                3,
                4,
                false
            ],
            fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
            sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
    };

    },
    computed: {
       /* TagBoxNews: function () {
            return this.$refs[tagBoxA].instance;
        }, */

    },
    
    mounted() {
        var EmpID = undefined
        if(window.localStorage.getItem('user') != undefined ) EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
        if (EmpID == 3136) this.reditel = true
        this.AnimationInsert()
        this.DrawerSelected()
        this.GetDashboard()
        this.GetApproveDashboard()
        this.GetNews()
        this.GetNewsHistory()
        this.GetEmployees()
       // this.handleCtrlF();
       //this.HandleHtmlObsah();
       /*const contentContainer = this.$refs.htmlContentContainer;
       const images = contentContainer.querySelectorAll('img');    
        images.forEach(image => {
      console.log(image.src);
      image.addEventListener('click', () => {
        console.log('Image clicked:', image.src);
      });
    });*/
    },
    methods: {
        HistorieNovinekRedirect(){
           // window.location.reload();
           if (window.jazyk=='en') {
                window.open(
                            'https://helpdesk.ekois.cz/en/support/solutions/categories/43000372624/folders/43000591323',
                            '_blank' // <- This is what makes it open in a new window.
            );
           }
           else {
                window.open(
                            'https://helpdesk.ekois.cz/cs/support/solutions/categories/43000372624/folders/43000591323',
                            '_blank' // <- This is what makes it open in a new window.
                );
           }
           
        },
        /*HandleHtmlObsah(){
            var dynamicContent = document.querySelector('#novinkyParent');
            this.$log.debug('dynamicContentA',dynamicContent)
            var htmlObsah = document.querySelector('#htmlObsah');
            this.$log.debug('dynamicContentB',htmlObsah)
            if (dynamicContent) {
                var images = dynamicContent.querySelectorAll('img');
                images.forEach(function(image) {
                    image.style.width = "200px";
                    image.style.border = "2px solid black";
                    image.style.borderRadius = "10px";
                    image.style.boxShadow = "2px 2px 5px rgba(0, 0, 0, 0.3)";
                });
            }
        
        },*/
        handleCtrlF() {
            // Naslouchejte události klávesnice
            document.addEventListener('keydown', function (event) {
                // Pokud je stisknuta klávesa Ctrl a klávesa F
                if (event.ctrlKey && event.key === 'f') {
                  //  event.preventDefault(); // Zabraňte výchozímu chování pro hledání

                    // Zde implementujte logiku pro vyhledání a scrollování na hledaný text
                    var searchText = prompt('Hledat text:');
                    if (searchText) {
                        // Najděte první výskyt hledaného textu v rámci obsahu popupu
                        var element = document.querySelector('#NovinkyHistorie');
                        var foundTextElement = element.querySelector(':contains(' + searchText + ')');
                        if (foundTextElement) {
                            // Scrollovat na pozici hledaného textu
                            foundTextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        } else {
                            alert('Hledaný text nebyl nalezen.');
                        }
                    }
                }
            });
        },
        DateToDateBoxValueChanged(e){
            
            this.$log.debug(e)
           // this.$log.debug(this.$refs.ToDateBox)
            this.ConfirmationDateBoxComponent.option('max', this.formData.DatumDo)
            if(this.ConfirmationDateBoxComponent.option('value') > this.ConfirmationDateBoxComponent.option('max')) this.ConfirmationDateBoxComponent.option('value', this.formData.DatumDo )
        },
        ConfirmationDateBoxInit(e){
            this.$log.debug(e)
            this.ConfirmationDateBoxComponent = e.component
            this.ConfirmationDateBoxComponent.option('max', this.formData.DatumDo)
            if(this.ConfirmationDateBoxComponent.option('value') > this.ConfirmationDateBoxComponent.option('max')) this.ConfirmationDateBoxComponent.option('value', this.formData.DatumDo )
        },
        PrijemciKNovinky(e) {
            let self = this;
            self.$log.debug(e)
           
            self.PocetPruchodu++
            var NewsID = 0
            var Zamid = 0
            if (e.removedItems.length > 0 && self.ClanekID > 0 ) {  
                NewsID = self.ClanekID  
                self.$log.debug('odebirani')
                self.$log.debug(NewsID)
                self.$log.debug(Zamid)
                var wh = new window.WorkFlow(window.channel, window.message);
                for (var x = 0; x < e.removedItems.length; x++){
                    Zamid = e.removedItems[x].id
                    wh.RemoveNewsRecipient(NewsID, Zamid,
                        function (result) {
                            self.$log.debug(result)
                            if (result.Error.value.ErrorNum.value != 0) {
                                notify({
                                    message: result.Error.value.ErrorTextCZ.value,
                                    position: {
                                        my: 'center top',
                                        at: 'center top',

                                    },
                                }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                            }
                            else notify({
                                message: self.$t('aktualne.PrijemceOdebran'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',

                                },
                            }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                            return 0;
                        }, window.this.ApiError());
                }
            
            } 
            if (e.addedItems.length > 0 && self.ClanekID > 0 && self.PocetPruchodu > 2) { // Pocet pruchodu, aby se pri prvnim otevreni, neukazoval notify o pridani prijemcu, protoze se vola ten event
                NewsID = self.ClanekID
                Zamid = e.addedItems
                self.$log.debug('pridavani')
                self.$log.debug(NewsID)
                self.$log.debug(Zamid)
                var result = self.PridaniPrijemcuKNovinkam(NewsID, Zamid);
                if (result == 0) {
                    notify({
                        message: 'Something went wrong...',
                        position: {
                            my: 'center top',
                            at: 'center top',

                        },
                    }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                }
                else notify({
                    message: self.$t('aktualne.PrijemcePridan'),
                    position: {
                        my: 'center top',
                        at: 'center top',

                    },
                }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                
            }
        },
        PridaniPrijemcuKNovinkam(IDNovinky, zam){
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            self.$log.debug(IDNovinky)
            var pole = []
           // var prijemci = self.$refs.tagBoxA.instance.option('selectedItems')
            var prijemci = zam
            self.$log.debug(prijemci)
            for (var x = 0; x < prijemci.length; x++) {
                //  this.$log.debug(result.List.value.items[x]);
                var i = pole.length;
                pole[i] = {
                    Value: "",
                    Detail: "",
                    Id: prijemci[x].id,
                };
            }
             self.$log.debug(pole)
                wh.AddNewsRecipient(IDNovinky, pole ,
                    function (result) {
                        self.$log.debug(result)
                         if (result.Error.value.ErrorNum.value != 0) return 1;
                         else return 0;
                    }, window.this.ApiError());
        },

    GetEmployees() {         
      var getEmployeeFilter =':MYSUB';//:MYSUB
      let self = this;
      var gen = new window.General(window.channel, window.message);
      this.$log.debug("GET EMPLOYEES START");
            
      self.zamestnanci.length = 0;
      //  window.this.$log.debug(Zamestnanec)
      gen.ListEmp(getEmployeeFilter,
        function (result) {
            
            self.$log.debug("RESULT GET EMPLOYEES");
            self.$log.debug(result);
            //self.TestEditNews();
            if ((result.List.value.items.length >= 2)) {
                self.NovinkaPersonalPrijemci=true;
            } else { self.NovinkaPersonalPrijemci=false; }
            
          //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
          //    window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[self.zamestnanci.length] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              };
          }
          if (result.ErrorNum.value != 0) {
                            notify({
                                message: result.ErrorTextCZ.value,
                                position: {
                                    my: 'center top',
                                    at: 'center top',

                                },
                            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                        }
        }, window.this.ApiError());
        this.$log.debug("GET EMPLOYEES END");
    },

    TestEditNews() {
      this.$log.debug('TestEditImportant');
      var g = new window.General(window.channel, window.message);
      let self = this;     
      g.Validate('Novinky',
        function (result) {
          self.$log.debug("NOVINKY POVOLENI EDITOVAT GLOBAL");
          self.$log.debug(result);
          if (result.ErrorNum.value==0) {self.editNews=true} else {self.editNews=false}
          self.$log.debug(self.editNews);
          //self.editNews=result.ErrorNum.value==0;
        }, window.this.ApiError());

    },
        naplneniFormDataPersonal(e){
            let self = this;
            self.$log.debug('naplneniFormDataPersonal')
            self.$log.debug(e.value)
                self.formData.Personal=e.value
                if ( self.formData.Personal==1) { self.ZobrazeniPrijemci=true;}
                else if ( self.formData.Personal==0) { self.ZobrazeniPrijemci=false; }
            
            
        },
        vytvareniNoveNovinky() {
            this.$log.debug(' Vytvareni novinky k editaci')
        let self =this;
            self.SmazatelnostNovinka=false;
            self.formData.Personal=1,
            self.formData.DatumOd=new Date(),
            self.formData.DatumDo= new Date(Date.now() + 24 * 60 * 60 * 1000 * 2),
            self.formData.DatumPotvrzeni=null,
            self.formData.ClanekVAj="",
            self.formData.ClanekVCj="",
            self.formData.Kategorie=0,
            self.ClanekID = 0
        },
        SetNewsState(NewsID, State, AnimationBool) {
            let self = this;
            self.$log.debug(NewsID);
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.SetNewsState(NewsID, State,
                function (result) {
                    self.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        if(!AnimationBool) self.GetNews();
                        var text = self.$t('aktualne.NovinkaPrectena');
                        if (State == 2) text = self.$t('aktualne.NovinkaSkryta');
                        if (State == 0) text = self.$t('aktualne.NovinkaVracena');
                        notify({
                            message: text,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                }, window.this.ApiError());
        },
        nactiNovinkuKEditaci(NewsID){
            let self = this;
            this.PocetPruchodu=0
            self.$log.debug('ID NOVINKY k editaci')
            self.$log.debug(NewsID);
            self.$log.debug(this.$refs);
            self.SmazatelnostNovinka=true;
            self.selectedzamestnanci = []
            var wh = new window.WorkFlow(window.channel, window.message);
                wh.GetNewsDetail(NewsID,
                            function(result){
                                self.$log.debug('nacteni NOVINKY k editaci');
                                self.$log.debug(result);
                                self.formData.ClanekVAj=result.List.value.items[0].ContentEN.value;
                                self.formData.ClanekVCj=result.List.value.items[0].ContentCZ.value;
                                self.formData.DatumDo=result.List.value.items[0].DateTo.value;
                                self.formData.DatumOd=result.List.value.items[0].DateFrom.value;
                                self.formData.DatumPotvrzeni = result.List.value.items[0].ConfirmTo.value;
                                if (self.formData.DatumPotvrzeni.getTime()  == self.formData.DatumOd.getTime()) {
                                    self.$log.debug('Rovnaji se datumy');
                                    self.formData.DatumPotvrzeni = null
                                } 
                                self.formData.Kategorie=result.List.value.items[0].Category.value;
                                self.formData.Personal = result.List.value.items[0].Personal.value;
                                if (self.formData.Personal == 1 && result.List.value.items[0].Recipients.value.items.length > 0) {
                                    for (var x = 0; x < result.List.value.items[0].Recipients.value.items.length; x++) { 
                                    self.selectedzamestnanci[x] = result.List.value.items[0].Recipients.value.items[x].Id.value
                                    }
                                }
                                
                                self.$refs.tagBoxA.instance.option('value', self.selectedzamestnanci)                         
                                self.ClanekID = result.List.value.items[0].ID.value;
                                self.$log.debug('obsah NOVINKY k editaci');
                                self.$log.debug(self.formData.SchvalitDo);
                                self.$log.debug(self.formData.Personal);
                                //self.$log.debug(self.formData.ClanekVCj);
                               // self.$log.debug(self.formData.ClanekVAj); 
                },window.this.ApiError());
        },
        PocetDni(date1, date2) {
            // převést data na časové razítko v milisekundách
            const time1 = date1.getTime();
            const time2 = date2.getTime();

            // 86400000 milisekund je jeden den
            const oneDay = 86400000;

            // rozdíl mezi časovými razítky ve dnech
            const diffInDays = Math.round((time2 - time1) / oneDay);
            return diffInDays;
        },
        EditaceNovinky(data, typ, zam){
            var self = this;
            this.$log.debug('zacatek editace novinky')
            this.$log.debug(data)
            this.$log.debug(data.ID + ' Predane ID')
            this.$log.debug(zam)
            this.$log.debug(self.formData.DatumPotvrzeni)       
            if (self.formData.DatumPotvrzeni == null) {
                this.$log.debug('Datum potvrzeni jako datumod')
                self.formData.DatumPotvrzeni = self.formData.DatumOd
            }
            if (typ==0){
                //let obsah = self.getContent('contentEditor');
                //let obsahEN = self.getContent('contentEditorEN');
                //nový článek vytvoření           
                this.$log.debug('Pridani NOVINKY')
                this.$log.debug(self.$refs.ClanekAJ.instance.option('value'))
                this.$log.debug(self.formData.DatumDo)
                this.$log.debug(self.formData.DatumOd)
                this.$log.debug(self.formData.DatumPotvrzeni)
                //self.$refs.ClanekAJ.instance
                this.$log.debug(self.formData.ClanekVCj)
                this.$log.debug(self.formData.ClanekVAj)
                //let self = this;
                //cellInfo.setValue(e.value);
                //this.$log.debug(cellInfo.component.cellValue(cellInfo.rowIndex, "ClanekVCj", false));
                if (self.ClanekID ==0) {
                    this.$log.debug('Vytvoreni NOVE NOVINKY')
                    this.$log.debug(self.formData.Personal)
                    this.$log.debug(self.formData.ClanekVCj)
                    this.$log.debug(self.formData.ClanekVAj)
                    var wh = new window.WorkFlow(window.channel, window.message);
                    wh.SetNews(0, self.formData.Personal, self.$refs.ClanekCJ.instance.option('value'), self.$refs.ClanekAJ.instance.option('value'),
                        self.formData.Kategorie, self.formData.DatumOd, self.formData.DatumDo, self.formData.DatumPotvrzeni, false,
                                function (result){
                                    self.$log.debug(result);
                                    if (result.Error.value.ErrorNum.value != 0) {
                                        notify({
                                            message: result.Error.value.ErrorTextCZ.value,
                                            position: {
                                                my: 'center top',
                                                at: 'center top',

                                            },
                                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                                    }
                                    else {
                                        self.$log.debug('B221 Pridani Prijemcu k novinkam');
                                        self.delay(500).then(() => self.GetNews());
                                        self.$log.debug(zam);
                                       // self.$log.debug(refs.tagBoxA.instance.option('selectedItems').length);
                                        self.$log.debug(self.formData.Personal);
                                        self.stavAktualneEditoru = false;
                                        if (self.formData.Personal == 1 && zam.length > 0) self.PridaniPrijemcuKNovinkam(result.Value.value, zam);
                                        notify({
                                            message: self.$t('aktualne.NovinkaZverejnena'),
                                            position: {
                                                my: 'center top',
                                                at: 'center top',

                                            },
                                        }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                                    }
                                },window.this.ApiError());
                                
                                    
                            }
                else {  this.$log.debug('Editace STAVAJICI NOVINKY')
                        var wf = new window.WorkFlow(window.channel, window.message);
                        this.$log.debug(self.formData.ClanekVCj)
                        this.$log.debug(self.formData.ClanekVAj)
                        wf.SetNews(self.ClanekID, self.formData.Personal, self.$refs.ClanekCJ.instance.option('value'), self.$refs.ClanekAJ.instance.option('value'),
                            self.formData.Kategorie, self.formData.DatumOd, self.formData.DatumDo, self.formData.DatumPotvrzeni, false,
                                    function (result){
                                        self.$log.debug(result);
                                        if (result.Error.value.ErrorNum.value != 0) {
                                            notify({
                                                message: result.Error.value.ErrorTextCZ.value,
                                                position: {
                                                    my: 'center top',
                                                    at: 'center top',

                                                },
                                            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                                        }
                                        else {
                                            self.delay(500).then(() => self.GetNews());
                                            self.stavAktualneEditoru = false;
                                            self.$log.debug('B221s Pridani Prijemcu k novinkam');
                                            self.$log.debug(self.formData.Personal);
                                             if (self.formData.Personal == 1 && zam.length > 0) self.PridaniPrijemcuKNovinkam(result.Value.value, zam);
                                            
                                            notify({
                                                
                                                message: self.$t('aktualne.NovinkaZverejnena'),
                                                position: {
                                                    my: 'center top',
                                                    at: 'center top',

                                                },
                                            }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                                    
                                        } 
                                    },window.this.ApiError());
                                        
                }                
            } 
            else if (typ==1){
                //smazání článku
                self.$log.debug('Mazani novinky');
                self.$log.debug(self.ClanekID+' SAMOSTATNA');
                var wh2 = new window.WorkFlow(window.channel, window.message);
                wh2.SetNews(self.ClanekID, self.formData.Personal, self.$refs.ClanekCJ.instance.option('value'), self.$refs.ClanekAJ.instance.option('value'),
                    self.formData.Kategorie, self.formData.DatumOd, self.formData.DatumDo, self.formData.DatumPotvrzeni, true,
                                function (result){
                                    self.$log.debug(result); 
                                    if (result.Error.value.ErrorNum.value != 0) {
                                        notify({
                                            message: result.Error.value.ErrorTextCZ.value,
                                            position: {
                                                my: 'center top',
                                                at: 'center top',

                                            },
                                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                                    }
                                    else {
                                        self.stavAktualneEditoru = false;
                                        self.delay(500).then(() => self.GetNews());
                                        // TODO ODEBRANI PRIJEMCU OD NOVINKY
                                        notify({
                                            message: self.$t('aktualne.NovinkaSmazana'),
                                            position: {
                                                my: 'center top',
                                                at: 'center top',

                                            },
                                        }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error' 
                                        
                                    } 
                                                
                                },window.this.ApiError());
                                
            }
            
            
            self.formData.ClanekVAj="";
            self.formData.ClanekVCj="";

        },
        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
            },
        AnimationInsert() {
            const css = window.document.styleSheets[0];
             /*   css.insertRule(`
                    @keyframes setstate {
                    0%   { left: 0; top: 0px; }
                    25%  { left: 0; top: -25px; }
                    50%  { left: 0; top: 25px; }
                    100% { left: -100%;  top: -25px; }
                    }`, css.cssRules.length);*/
                css.insertRule(`
                    @keyframes hide {
                    0% { opacity: 1; border-color: transparent; left:-10px}
                    50% {opacity: 0.5; background-color: #888888; border-color: transparent;}
                    75% {opacity: 0.3;}
                    100% { opacity: 0.05; background-color: 8888883b; border-color: transparent; left:-100%; }
                    }`, css.cssRules.length);
                css.insertRule(`
                    @keyframes approve {
                    0%   { opacity: 1; background-color: white; border-color: transparent; top: -10px }
                    50%   { opacity: 0.5; background-color: #5cb85c; border-color: transparent; }
                    75% {opacity: 0.3;}
                    100%   { opacity: 0.10; border-color: transparent; top:-100%}
                    }`, css.cssRules.length);
                css.insertRule(`
                    @keyframes return {
                    0%   { opacity: 1; background-color: white; border-color: transparent; left:-10px}
                    50%   { opacity: 0.5; background-color: orange; border-color: transparent;}
                    75% {opacity: 0.3;}
                    100%   { opacity: 0.10; border-color: transparent; left:-100%;}
                    }`, css.cssRules.length);
                css.insertRule(`
                    @keyframes deny {
                    0%   { opacity: 1; background-color: white; border-color: transparent; left:-10px}
                    50%   { opacity: 0.5; background-color: #d9534f; border-color: transparent;}
                    75% {opacity: 0.3;}
                    100%   { opacity: 0.10; border-color: transparent; left:-100%;}
                    }`, css.cssRules.length);
                    css.insertRule(`
                    @keyframes setstate {
                    0%   { opacity: 1; }
                    50%   { opacity: 0.5; border-color: transparent;}
                    100%   { opacity: 1; }
                    }`, css.cssRules.length);

        },
        Animation(type, item, element) {
             // = "[animation_name] [Duration] [TimingFunction] [Delay] [IterationCount] [Direction] [fillMode] [playState]";
             if(item.index==undefined) item.index=item.ID
                    switch (type) {        
                    case 'setstate':
                        document.getElementById(element + item.index).style.WebkitAnimation = "setstate 1.5s ease";
                    setTimeout(() => {
                            item.visible = false
                            if(element=='approvedashboard') this.VisibleIndex++
                        }, 1500);
                        break;
                    case 'approve':
                        document.getElementById(element + item.index).style.WebkitAnimation = "approve 1.5s ease";
                        setTimeout(() => {
                            item.visible = false
                            if(element=='approvedashboard') this.VisibleIndex++
                        }, 1500);
                        break;
                    case 'return':
                        document.getElementById(element + item.index).style.WebkitAnimation = "return 1.5s ease";
                        setTimeout(() => {
                            item.visible = false
                            if(element=='approvedashboard') this.VisibleIndex++
                        }, 1500);
                        break;
                    case 'deny':
                        document.getElementById(element + item.index).style.WebkitAnimation = "deny 1.5s ease";
                        setTimeout(() => {
                            item.visible = false
                            if(element=='approvedashboard') this.VisibleIndex++
                        }, 1500);
                        break;
                    case 'hide':
                        document.getElementById(element + item.index).style.WebkitAnimation = "hide 1s ease";
                        setTimeout(() => {
                            item.visible = false
                            if(element=='approvedashboard') this.VisibleIndex++
                        }, 1000);
                        break;  

            }

            
            
           
            
            
            
        },
        ElectronicSignature(id) {
            console.log('uvnitr ElectronicSignature')
            var self = this;
            var auth = new window.Auth(window.channel, window.message);
            var wh = new window.WorkFlow(window.channel, window.message);
            this.$log.debug(id)
            console.log(id)
            wh.GetRequest(id,
                function (result2) {
                    self.$log.debug(result2);
                    if (result2.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result2.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                         self.ElPodpis = false      
                        if (result2.Actions.value.items.some(item => item.Id.value === -100)) self.ElPodpis = true                 
                        self.$log.debug('reqstate ' + result2.ReqState.value);
                        console.log('reqstate ' + result2.ReqState.value)
                        self.$log.debug(self.ElPodpis);
                        if (self.ElPodpis) {
                            auth.GenerateToken(-1,
                                function (result) {
                                    console.log(result)
                                    self.$log.debug(result);
                                    self.$log.debug('ekoissigner:' + id + '&' + result);
                                    window.location.href = 'ekoissigner:' + id + '&' + result;
                                }, window.this.ApiError());
                        }
                    }
                }, window.this.ApiError());
        },
        SetState(stav, item) {
           
            this.$log.debug('SETSTATE')
            this.$log.debug(stav)
            this.$log.debug(item)
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            if (stav == 2 || stav == 3) {
                notify({
                    message: self.$t('pozadavky-VyplnteKomentarKeSchvalovani'),
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'warning', 5000);
                self.$router.push('/Pozadavky?Vypis=1&Stav=0&' + 'Pozid=' + item.id + '&Typ=' + item.typ + '&Return=Aktualne'+'&ToFill=1')
                return 1;
            }
                wh.SetState(item.id, stav, '',
                    function (result) {
                        self.$log.debug(result);
                        if (result.ErrorNum.value != 0) {
                            if (result.ErrorNum.value == 6) {
                                self.$router.push('/Pozadavky?Vypis=1&Stav=0&' + 'Pozid=' + item.id + '&Typ=' + item.typ + '&Return=Aktualne'+'&ToFill=0')
                             } 
                            notify({
                                message: result.ErrorTextCZ.value,
                                position: {
                                    my: 'center top',
                                    at: 'center top',

                                },
                            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                        }
                        else {
                            var stavText;
                            switch (stav) {
                                case '1': case 1:
                                    self.Animation('approve', item, 'approvedashboard');
                                    stavText = self.$t('pozadavky-PozadavekSchvalen')
                                    break;
                                case '3': case 3:
                                    self.Animation('return', item, 'approvedashboard');
                                    stavText = self.$t('pozadavky-PozadavekVracen')
                                    break;
                                case '2': case 2:
                                    self.Animation('deny', item, 'approvedashboard'); 
                                    stavText = self.$t('pozadavky-PozadavekZamitnut')
                                    
                            }
                            notify({
                            message: stavText,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                            }, 'success', 3000);
                            self.PocetKeSchvaleni--
                            self.$log.debug(item.typ == '12');
                             if (stav == 1) {
                                console.log('volam ElectronicSignature')
                                self.ElectronicSignature(item.id)
                        }
                        }
                    }, window.this.ApiError());
            

        },
        HideFromApproveDashboard(id) {
            var self = this;
            var w = new window.WorkFlow(window.channel, window.message);
            w.HideFromApproveDashboard(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        notify({
                            message: self.$t('pozadavky-PozadavekSchovan'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                      //  self.GetApproveDashboard()
                    }
                }, window.this.ApiError());  
        },
        DrawerSelected() {
            var e = window.Drawer
            if (window.prevElement != undefined && window.prevPosition != undefined)
                window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
            this.$log.debug(window.Drawer)
            this.$log.debug(this.$route.meta.id)
            this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
            for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
                // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
                if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
                    e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
                    window.prevElement = e
                    window.prevPosition = x
                }
            }
        },
        Redirect(data) {
            this.$log.debug(data)
            this.$router.push('/'+data.Route)
        },
        GetDashboard() {
            var self = this;
            var w = new window.WorkFlow(window.channel, window.message);
            this.Dashboard.length = 0
            w.GetDashboard(
                function (result) {
                    self.$log.debug(result); 
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.Dashboard[x] = {
                            Count: result.List.value.items[x].Count.value,
                            Description: result.List.value.items[x].Description.value,
                            Route: result.List.value.items[x].Route.value,
                            index: x,
                        }
                    }
                    self.PocetKeSchvaleni = self.Dashboard[0].Count
                }, window.this.ApiError());
        },   
        GetApproveDashboard() {
            var self = this;
            var w = new window.WorkFlow(window.channel, window.message);
            this.ApproveDashboard.length = 0
            w.GetApproveDashboard(0,
                function (result) {
                    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        var str = result.List.value.items[x].Value.value;
                        var typ = str.substr(0, str.indexOf('-'));
                        var nazev = str.substring(str.indexOf("-") + 1);
                        self.ApproveDashboard[x] = {
                            value: nazev,
                            detail: result.List.value.items[x].Detail.value,
                            id: result.List.value.items[x].Id.value,
                            visible: true,
                            index: x,
                            typ: typ
                        }
                    }
                       self.TestEditNews() 
  
                    
                }, window.this.ApiError());
        },
        GetNews() {
            var self=this
            var owner=false;
            var au = new window.Auth(window.channel, window.message);
                    au.GetEmployeeInfo(0, function (result) {
                        self.$log.debug("LoggedUSER");
                        self.$log.debug(result.EmployeeID.value);
                        self.zamid=result.EmployeeID.value;
                        self.$log.debug(self.zamid);
                    }, window.this.ApiError());
            var w = new window.WorkFlow(window.channel, window.message);
            this.News.length=0
            w.GetNews(
                function (result) {
                    self.$log.debug("GETNEWS");
                    self.$log.debug(result);
                    
                    
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        //self.$log.debug("OWNER VICE ITERACI PRED");
                        //self.$log.debug(self.zamid);
                        //self.$log.debug(result.List.value.items[x].Owner.value);
                       if (result.List.value.items[x].Owner.value==self.zamid) {owner= true} else {owner=false}
                       //self.$log.debug("OWNER VICE ITERACI PO");
                        //self.$log.debug(owner);
                        var ForConfirmation = true
                        var confirmTo = result.List.value.items[x].ConfirmTo.value
                        var Text = ''
                        if (result.List.value.items[x].ConfirmTo.value.getTime() == result.List.value.items[x].DateFrom.value.getTime()) {
                            confirmTo = null
                            ForConfirmation = false
                            self.$log.debug("Potvrzeni do bude null"); 
                        }
                        else {
                            self.$log.debug(result.List.value.items[x].ConfirmTo.value);
                            self.$log.debug(result.List.value.items[x].DateFrom.value);
                            self.$log.debug("Nerovnaji se datumy");
                            var DaysToConfirm  = self.PocetDni(new Date(), result.List.value.items[x].ConfirmTo.value)
                            var ButtonType ='success'
                            if(DaysToConfirm<0) ButtonType ='danger'
                            var zbyva = 'zbývá'
                            var daysCZ = ''
                            var daysEN = ''
                            switch(DaysToConfirm){
                                case 0: daysCZ='dnů'; daysEN = 'days'
                                break;
                                case 1: daysCZ='den'; daysEN = 'day'
                                break;
                                case 2:case 3:case 4: daysCZ='dny' ; daysEN = 'days'; zbyva = 'zbývají'
                                break;
                                default: daysCZ='dnů' ; daysEN = 'days'
                                break;
                            }
                            if(self.$i18n.locale == 'cs') {
                                if(DaysToConfirm>=0) {
                                   Text = ' ('+zbyva+' '+DaysToConfirm+' '+daysCZ+')'
                                }
                                else Text=' (potvrzení promeškáno!)'
                            }
                            else {
                                if(DaysToConfirm>=0) Text = ' ('+DaysToConfirm+' '+daysEN+' '+'remaining)'
                                else Text=' (confirmation missed!)'
                            } 
                         } 
                        self.News[x] = {
                            DaysToConfirm: DaysToConfirm,
                            Category: result.List.value.items[x].Category.value,
                            ConfirmTo: confirmTo,
                            Content: result.List.value.items[x].Content.value,
                            Date: result.List.value.items[x].Date.value,
                            DateFrom: result.List.value.items[x].DateFrom.value,
                            DateTo: result.List.value.items[x].DateTo.value,
                            ForConfirmation: ForConfirmation,
                            Owner: result.List.value.items[x].Owner.value,
                            OwnerTXT: result.List.value.items[x].OwnerTXT.value,
                            ID: result.List.value.items[x].ID.value,
                            State: result.List.value.items[x].State.value,
                            MyOwnNews:owner,
                            Personal: result.List.value.items[x].Personal.value,
                            Text: Text,
                            ButtonType: ButtonType,
                            visible: true,
                        }
                       
                    }
                    
                }, window.this.ApiError());
                
        },
        GetNewsHistory() {
            var self = this
            var w = new window.WorkFlow(window.channel, window.message);
            this.NewsHistory.length = 0
            w.GetNewsHistory( 
                function (result) {
                    self.$log.debug("GETNEWSHISTORY");
                    self.$log.debug(result);
                   
                    for (var x = 0; x < result.List.value.items.length; x++) {                     
                        self.NewsHistory[x] = {
                            Category: result.List.value.items[x].Category.value,
                            Content: result.List.value.items[x].Content.value,
                            Date: result.List.value.items[x].Date.value,
                            DateFrom: result.List.value.items[x].DateFrom.value,
                            Owner: result.List.value.items[x].Owner.value,
                            OwnerTXT: result.List.value.items[x].OwnerTXT.value,
                            ID: result.List.value.items[x].ID.value,
                            State: result.List.value.items[x].State.value,
                            Personal: result.List.value.items[x].Personal.value,
                            visible: true,
                        }

                    }

                }, window.this.ApiError());
                //this.HandleHtmlObsah();

        }
    },

}

</script>

<style scoped>
.dxScrollViewPozadavky{
    overflow: auto;
    }
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}
#dashboard {
    display: inline;
    
}

#list {
    width: 100%;
    display: flex;
    justify-content: left;
}
#dashboard{
    width: auto
}
hr {
    margin-top: 20px;
    clear: both;
    width: 100%;
}

.newsitem {
    border-style: ridge;
    border-color: rgba(128, 128, 128, 0.226);
    border-radius: 10px;
    padding: 5px;
    box-shadow: 20px 20px 20px rgba(rgba(70,70,70, 0.425)),20px 20px 20px rgba(rgba(128, 128, 128, 0.178));
    width: auto;
    height: auto;
}
#popisPozadavku{
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;

}
.pocetPozadavkuGreen{
    color:rgb(27, 216, 68) !important;
}
#pocetPozadavku{
    color: orangered;
    font-size: 18px;
    font-weight:700;
    text-shadow: 0.5px 0.5px 0.5px grey;
    border-style: ridge;
    border-radius: 50px;
    border-color: rgba(0, 0, 0, 0.555);
    border-width: 1.5px;
    color: rgb(#8888883b);
    
    padding-left: 10px;
    padding-right: 10px;
    float: right;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(255, 255, 255);
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 5px 10px 5px;
    
}

#dashboarditem {
    margin: 0px 0px 0px 5px !important;
    height: max-content;
    width: max-content;
    border-style: ridge;
    border-color: grey;
    border-width: 1.5px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px rgba(0, 32, 91,0.1),1.5px 1.5px 1.5px rgba(0, 32, 91,0.25);

    background-color: rgba(255, 255, 255, 0.8);
    
    text-align: center;
    color: black;

    transform: translate3d(0, 0, 0);
  transition: all 200ms;
}
#dashboarditem:nth-child(1){
    margin: 0px 0px 0px 0px !important;
}

#dashboarditem:hover {
    background-color: rgba(252, 147, 0, 0.9);
    cursor: pointer;
    
    
    box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
    
}
ul {
    list-style-type: none;
    width: 100%;
}
.nadpisyAktualne{text-align: center;}
#AktualneContainer{
display: flex;
flex-direction: row;
flex-wrap:nowrap;
justify-content: flex-start;
align-content: flex-start;}
#htmlObsah{color: inherit; inline-size: auto; width: 100%; overflow: hidden;}
.approvedashboard{
    width:500px;
    border: 1.5px solid;
    border-color: inherit;
    border-radius: 10px;
    padding: 5px;
    position: relative;
    box-shadow: 1px 1px 1px rgba(0, 32, 91,0.1),1.5px 1.5px 1.5px rgba(0, 32, 91,0.25);
    opacity:0.7;
}
.clParApprovedashboard:nth-child(2),.clParApprovedashboard:nth-child(3){
    margin-left: 5px;
}
.clParApprovedashboard:nth-child(1),.clParApprovedashboard:nth-child(2),.clParApprovedashboard:nth-child(3){
    margin-bottom: 10px;
}
#htmlObsah > div > p > img{
    width: 100%;

}
#htmlObsah > div > ol > li > span{
    color: red;
    
}
.approvedashboard:hover{box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
    opacity:1;}
#wrapperapprovedashboard{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
}
#wrapperapprovedashboard:last-child{
    padding-right: 25px;
}
#buttonsSkryt{
    float:right;
    clear: none;
}
#skryt{margin-right: 5px !important;}
#buttonsSkryt #buttonsSchvalovani {
    display: inline-flex;
}
.SchvalovaniKomentarButtons {
    scale: 0.8 !important;
    margin-right: 0;
}
#htmlObsahNadpis{
    display: inline-block;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px black solid;
    width:100%;
    

}
#htmlObsahDetail{
    display:inline-table;
    
}
#buttonsSpodniLista{display: flex;
        justify-content: space-between;}
#btnAktualneEdit, #Aktualne {display: inline-block;}
#btnAktualneEdit{ margin-left: 10px; width: 100%;}
#Aktualne {vertical-align:bottom;}
#htmlObsahNadpisBTN{margin-right: 5px !important; size: 1px; border-radius: 50%;}
#htmlObsahNadpisLabel{     align-self: center;}
#btnPopupAktulneEditorSettings{float:right; }


.first-group, .second-group {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}

.form-avatar {
  height: 128px;
  width: 128px;
  margin-right: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background-image: url("@/assets/images/user.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.editory-group{
    padding:10px;
    
}
#novinkyGrp{
margin-top: 10px;
}
.test-stylu {
  margin-top: 10px !important; 
  color:red !important;
  padding: 15px !important;
  border: 1px solid black !important;
  background-color: rgba(191, 191, 191, 0.15) !important;
}
.dx-box-flex .dx-box .dx-widget .dx-visibility-change-handler .dx-collection{
    background-color: rgba(191, 191, 191, 0.15);
}
.editnewsitem{
    border: 0px rgba(128, 128, 128, 0.3) solid;
    border-radius: 50%;
    float:right;
    margin-right: 0px ;
   
}
.buttonnewsitem{
    float:left;
    margin-left: 0px ;
    margin-bottom: 20px ;
    margin-right: 10px ;
    padding: 0px;
   
}
#divEditNewsItem{
    display: inline-block;
    width: auto;
    height: auto;
    clear:both;
    padding: 0px;
    
}
#smazat{float:right}
#NovinkyEditorForm{padding-right: 50px;}
.novinkyClass{
    display: flex;
flex-direction: row;
flex-wrap:wrap;
justify-content: flex-start;
align-content: flex-start;
padding :0;
    list-style:none;
}
.novinkyClass li {
    margin-bottom: 10px;
    
display: inline-block;
}
.novinkyItemCely{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    overflow: hidden;
}
[Budouci='true']{
    opacity: 1;
    background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 10px,
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0.1) 20px
    );
}
[typOhraniceni='0']{
    border-color: rgba(0, 32, 91,0.5) ;
    background-color: rgba(0, 32, 91, 0.05);
}
[typOhraniceni='1']{
    border-color:rgba(131, 63, 0, 0.35);
    background-color: rgba(131, 63, 0, 0.05);
}
.dot {
  height: 24px;
  width: 24px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.dot p{
    margin-left: 25px;
    margin-bottom: 50px;
}
.global{border: 1px solid;
    border-color: rgba(0, 32, 91,0.5) ;
    background-color: rgba(0, 32, 91, 0.05);}
.personal{
    border: 1px solid;
    border-color:rgba(131, 63, 0, 0.35);
    background-color: rgba(131, 63, 0, 0.05);}
    
.buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}
.ulRychloProkliky{
    border:0.5px solid transparent; 
    border-radius:10px; 
    padding: 0px 25px 0px 0px;
    width:max-content;
    margin: 5px auto;
    list-style-type: none;
    margin-bottom: 10px;
}
#flexHistorie{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#btn1Historie{
    width: 100%;
}
.clNadpisAktualniStranky.clAktualne{
    flex-direction: row ;
}
.hrDelici.clAktualne{
    margin-bottom:2px;
}

.dynamic-content > div > p > img {
            /* Example CSS rules for the img element */
            width: 200px; /* Set the width of the image */
            height: auto; /* Maintain aspect ratio */
            border: 2px solid black; /* Add a border */
            border-radius: 10px; /* Rounded corners */
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add a shadow */
}
.htmlDivHtmlObsah > p > img {
    width: 100%;
}
#novinkyParent{
    width: 100%;
}
.clPadLeft5{
    padding-left: 10px;
}
</style>