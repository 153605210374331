<template>
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=en" 
                PopisLinku='clanky.objednavkaVyvojovky'></HelpLink>
<div height="calc(100% - 57px)">
    <form action="your-action" @submit="onFormSubmit($event)">
    <DxScrollView >
        <div id="objednavky">
            <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Upozorneni')"
                :contentTemplate="popupAlertTemplate" :width="400" :height="200">
                <template #SmazatPoz>
                    <h3 class="fontAndColor">{{$t('central-OpravduSiPrejeteSmazat')}}</h3>
                    <div id="popupDivAnoNe">
                        <DxButton class="button1 popupAnoNe" height="auto" width="auto" type="default" styling-mode="contained"
                        :text="$t('central-Ano')" @click="SetState(-99, true); popupAlert=false " />
                        <DxButton class="button1 popupAnoNe" height="auto" width="auto" type="default" styling-mode="contained"
                        :text="$t('central-Ne')" @click="popupAlert=false" />
                    </div>
                </template>
            </DxPopup>
            <DxButton icon="back" :text="$t('pozadavky-ZpetNaPozadavky')" height="auto" type="outlined" styling-mode="contained" id="link"
                 @click="$router.push('Pozadavky')" />
            <h2  class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="h">{{ $t('pozadavky-ZalozeniPozadavkuNaVyvojoveDilny') }}</h2>
            <h2  class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{$t('pozadavky-ObjednavkaPresVyvojoveDilny')}} {{ obj +' ('+state+')'}} </h2>
            
           <div id="MarginBottom">
                <div id="obaleniResponsiveBox1">
                <div id="container">
                    <div id="inner">
                        <!-- Přes je vynecháno z důvodu nepoužívání graficky, kódově však musí zůstat. Používá se na pozadí -->
                        <div class="child" v-show="false">
                            <DxSelectBox :disabled="stateid!=4" :id="1" width='250px' height="45px" label="Přes" :data-source="vias"
                                display-expr="content" value-expr="id" placeholder="" :value="objVia[0].id" @value-changed="setSelectedValue">
                                <DxValidator>
                                    <DxRequiredRule message="Přes musí být vyplněno" />
                                </DxValidator>
                            </DxSelectBox>
                        </div>   
                    </div>
                </div>
                
                <DxResponsiveBox single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />

                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2"  screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2"  screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="4"  screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="4"  screen="xs" />
                        <div id="ResponsiveBoxItem">
                            <div class="flexboxcontainer">
                                    <FundingInstructions  :FundingID="objGrant[0].id" Ident="Pozadavky6"> </FundingInstructions>                         
                                <DxSelectBox style="flex-grow: 1" :disabled="stateid!=4" :id="2" width='auto' height="45px" label="Grant" :data-source="granty"
                                    display-expr="content" value-expr="id" placeholder="" :accept-custom-value="true" :value="objGrant[0].id"
                                    @value-changed="setSelectedValue">
                                    <DxValidator>
                                        <DxRequiredRule message="Grant musí být vyplněn" />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>
                    </DxItem>

                    <DxItem>

                        <DxLocation :row="3" :col="0" :colspan="4"  screen="lg" />
                        <DxLocation :row="3" :col="0" :colspan="4"  screen="md" />
                        <DxLocation :row="3" :col="0" :colspan="4"  screen="sm" />
                        <DxLocation :row="3" :col="0" :colspan="4"  screen="xs" />
                        <div id="ResponsiveBoxItem">
                                        <DxTextArea :disabled="stateid!=4" :id="4" :label="$t('requestVyvojoveDilny.Nazev')" v-model:value="popis" width="auto" height="auto"
                                            placeholder="" :autoResizeEnabled="true" @value-changed="setSelectedValue" :maxLength="50" >
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('requestVyvojoveDilny-PopisMusiBytVyplnen')" />
                                            </DxValidator>
                                        </DxTextArea>
                                        <label style="font-style: italic;">{{ $t('requestVyvojoveDilny.OmezeniNa50Znaku') }}</label>
                        </div>
                    </DxItem>

                    <DxItem>

                        <DxLocation :row="1" :col="0" :colspan="2"  screen="lg" />
                        <DxLocation :row="1" :col="0" :colspan="2"  screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="4"  screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="4"  screen="xs" />
                            <div id="ResponsiveBoxItem">
                                    <DxCheckBox class="prevent-select clCheckBoxForma" width="100%" height="45px" :disabled="stateid!=4" :id="5" v-model:value="checked1" :text="$t('requestVyvojoveDilny-Oprava')" @value-changed="setSelectedValue"/> 
                                </div>
                    </DxItem>

                    <DxItem>
                        <DxLocation :row="2" :col="0" :colspan="4"  screen="lg" />
                        <DxLocation :row="2" :col="0" :colspan="4"  screen="md" />
                        <DxLocation :row="2" :col="0" :colspan="4"  screen="sm" />
                        <DxLocation :row="2" :col="0" :colspan="4"  screen="xs" /> 
                                    <div class="padding5TopBottom">
                                        <div id="ResponsiveBoxItem">
                                        <DxSelectBox :disabled="stateid!=4" class="invc" v-if="checked1" width="auto" :id="3" height="45px" :label="$t('requestVyvojoveDilny-InvC')"
                                            :search-enabled="true" :data-source="majetky" display-expr="content" value-expr="invc" placeholder="" :search-timeout="1000" 
                                            @value-changed="setSelectedValue" @input="SearchInput" noDataText="" :accept-custom-value="true" :value="majetek[0].invc"
                                            @customItemCreating="customItemCreating($event)">
                                            <DxValidator>
                                                <DxRequiredRule :message="$t('requestVyvojoveDilny-InvCMusiBytVyplneno')" />
                                            </DxValidator>
                                        </DxSelectBox>
                                    </div>
                                </div>
                                    
                    </DxItem>
                </DxResponsiveBox>
            </div>
            
            <div id="obaleniResponsiveBox2" v-if="!h"> 
                <DxResponsiveBox single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />


                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                        <div id="ResponsiveBoxItem">
                            <div class="dx-field" id="dx-field-Komentar" v-if="!h">
                                <DxTextArea :disabled="stateid!=4 " :id="6" :label="$t('majetekVyrazeni-Komentar')" width="auto" :height="100"
                                v-model:value="komentar" placeholder="" @value-changed="setSelectedValue" />
                            </div>
                        </div>
                    </DxItem>

                    <DxItem>
                        <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
                            <div v-if="!h" >       
                                <Attachments :Disabled="stateid != 4" :Pozid="this.obj" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                            </div>
                    </DxItem>                   
                   
                </DxResponsiveBox>
            </div>
            
            </div>
            
        </div>
        
    </DxScrollView>
    <div width="100%" class="SpodniMenuDetailPozadavku" >
                <DxResponsiveBox single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5"/>
                <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                                    <div v-if="h" id="ResponsiveBoxItemActionButtons">
                                        <DxButton icon="add" id="vytvorit" height="auto" width="auto"
                                            :text="$t('pozadavky-ZalozitVyvojoveDilny')" :use-submit-behavior="true" type="success"
                                             class="actionsVytvoreni"/>
                                        </div>
                    </DxItem>
                <DxItem>
                        <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                        <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                        <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!h">
                            <div id="ResponsiveBoxItemActionButtons" v-if="!h">
                                <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                                        <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default"
                                            styling-mode="contained" :text="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                            v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                                    </div>
                                    <div class="child" >
                                    <ChangeOwner :Disabled="false" :RequestID="obj" @owner-changed="setSelectedValue" >  </ChangeOwner>
                                </div>
                            </div>
                        </DxScrollView>

                    </DxItem>
                </DxResponsiveBox>
            </div>
        </form>

    
</div>
</template>

<script>

import ChangeOwner from '@/components/ChangeOwner.vue';
import "devextreme/ui/html_editor/converters/markdown";
import 'whatwg-fetch';
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import FundingInstructions from '@/components/FundingInstructions.vue';
import DataSource from 'devextreme/data/data_source';
import HelpLink from '@/components/HelpLink.vue';
//import { suppliersData } from '@/data.js';
import { isNull, isUndefined } from 'lodash';
import DxScrollView from 'devextreme-vue/scroll-view';
import Attachments from '@/components/Attachments.vue';
const majetekDataSource = new DataSource({
    store: {
        data: '',//suppliersData,
        type: 'array',
        key: 'id',
    },
});

export default {
    name: "App",
    components: {
        Attachments,
        FundingInstructions,
        ChangeOwner,
        HelpLink,
        DxScrollView

    },
    data() {
        return {
            //  pattern: /^\d{}/i,
            MountingData: false,
            PocetKolonek: 3,
            checked1: false,
            popis: '',
            majetky: [],
            majetek: [{ content: '', invc: ''}],
            screen: '',
            state: 'Uložený',
            stateid:4,
            popupAlert: false,
            popupAlertTemplate: '',
            actionsPozadavky: [],
            objGrant: [{ content: '', id: -1 }],
            objVia: [{ content: '', id: -1 }],
            komentar: '',
            granty: [],
            vias: [],
            showModal: false,
            obj: '',
            h: true,

        }
    },
    computed: {
    },
    created() {
    },
    mounted() {
        window.MountCount=0
        this.MountingData=true
      //  window.localStorage.setItem("refresh-page", 'ObjednavkaKnihovna')
        this.ListFunding();
        this.GetAssetsInit()
        if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
            window.this.$log.debug(' ve funkci')
            this.h = false
            this.obj = window.localStorage.getItem("pozid")
            this.DisplayRequest();

        }
        this.LoadBarvyTheme();
    },

    methods: {
        LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
        GetAssets(assets) {
            if (assets.component != undefined) {
                var e = assets
                var filter = e.component.option("text")
            }
            var asset = new window.Asset(window.channel, window.message);
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value

            let self = this;
            self.majetky.length = 0;
            var y = 0;
            asset.GetAsset(EmpID, true, filter, 0, 1,
                function (result) {

                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        if (result.List.value.items[x].Locked.value == false) {
                            self.majetky[y] = {
                                content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value,
                                id: result.List.value.items[x].InternalId.value,
                                invc: result.List.value.items[x].Id.value
                            };
                            y++

                        }
                    }
                    e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
        GetAssetsInit() {
            var asset = new window.Asset(window.channel, window.message);
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
            let self = this;
            self.majetky.length = 0;
            var y = 0;
            asset.GetAsset(EmpID, true, '', 0, 1,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        if (result.List.value.items[x].Locked.value == false) {
                            self.majetky[y++] = {
                                content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value,
                                invc: result.List.value.items[x].Id.value
                            };

                        }

                    }
                 //   e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
        SearchInput(e) {
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetAssets(e)  
            }, 1000)

        },
        log(log) {
            window.this.$log.debug(log)
        },
        FileDelete(FileID) {
            var self = this;
            var gen = new window.General(window.channel, window.message);
            gen.FileDelete(1, self.obj, FileID,
                function (result) {
                    window.this.$log.debug(result);
                    self.Prilohy();
                }, window.this.ApiError());

        },
        async downloadItem(url, name, suffix) {
            window.this.$log.debug(suffix)
            suffix = '.pdf'
            axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
                .then(function (response) {
                    switch (suffix) {
                        case '.pdf': {
                            window.this.$log.debug(response)
                            const blob = new Blob([response.data], { type: 'application/' + suffix });
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = name;
                            link.click();
                            window.this.$log.debug(link)
                            window.URL.revokeObjectURL(link.href);
                            break;
                        }
                    }
                })
                .catch(function (error) {
                    window.this.$log.debug(error)
                    window.this.$log.debug(JSON.stringify(error))
                })
        },
        GetHash(FileName, FileID) {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            gen.GetFileHash(FileID,

                function (result) {
                    window.this.$log.debug(result);
                    self.hash = window.URL1+'/js/download/' + result.Value.value;
                    window.this.$log.debug(self.hash + " " + FileName)
                    self.downloadItem(self.hash, FileName);

                }, window.this.ApiError());
        },
        FileUpload(file) {
            window.this.$log.debug(file)
            var self = this;
            var gen = new window.General(window.channel, window.message);
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                window.this.$log.debug(theFile.name)
                return function (e) {
                    gen.FileUpload(1, self.obj, theFile.name,
                        e.target.result,
                        function (result) {
                            window.this.$log.debug(result);
                            self.Prilohy()
                        }, window.this.ApiError());
                }
            })(file);
            reader.readAsBinaryString(file);

        },
        SetState(stav, alert) {
            window.this.$log.debug(alert + ' alert<<<??>')
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);

            if (stav == -1) {
                window.this.$log.debug('konec')
                this.popupAlert = true
                this.popupAlertTemplate = 'SmazatPoz'
                return 1;
            }
            if (alert == true) stav = -1
            self.open();
            wh.SetState(self.obj, stav, '',
                function (result) {
                    window.this.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.DisplayRequest();
                        if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

                        var stavText;
                        switch (stav) {
                            case -1: stavText = self.$t('pozadavky-StavZrusen')
                                break;
                            case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                                break;
                            case 4: stavText = self.$t('pozadavky-StavUlozeny')
                                break;
                        }
                        notify({
                            message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                    }
                    self.hide();
                }, window.this.ApiError());

        },
        DisplayRequest() {
            window.this.$log.debug('display request')
            let self = this;
            self.actionsPozadavky.length = 0;
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetRequest(self.obj,
                function (result2) {
                    window.this.$log.debug(result2);
                    self.state = result2.ReqStateText.value;
                    self.stateid = result2.ReqState.value;
                    for (var x = 0; x < result2.Actions.value.items.length; x++) {
                        var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.obj
            };
                    }
                }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
        },

        customItemCreating(data) {

            window.this.$log.debug(data)
            window.this.$log.debug(data.text + ' text')
            if (!data.text) {
                data.customItem = null;

                return;
            }

            // const itemIds = suppliersData.map((item) => item.ID);
            //const incrementedId = Math.max.apply(null, itemIds) + 1;

                const newItem = {
                    content: data.text,
                    invc: data.text
                    // id: incrementedId,
                };
                data.customItem = majetekDataSource
                    .store()
                    .insert(newItem)
                    .then(() => majetekDataSource.load())
                    .then(() => newItem)
                    .catch((error) => {
                        throw error;
                    });
            this.majetek[0] = { content: data.text, invc: data.text, event: 'itemCreating' }

        },
        onFormSubmit(e) {
            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e)
            if (e.submitter.attributes[0].ownerDocument.activeElement.id == "vytvorit") {
                notify({
                    message: this.$t('pozadavky-ObjednavkaZalozena'),
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'success', 3000);
                this.NewProductionOrder("vytvorit")
            }
            else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "upravit") {
                notify({
                    message: this.$t('pozadavky-ObjednavkaZmenyUlozeny'),
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'success', 3000);
                this.NewProductionOrder("upravit");
            }
            e.preventDefault();
        },
        setSelectedValue(e) {
            window.MountCount++
            window.this.$log.debug(e);
            window.this.$log.debug(window.MountCount + 'MountCount');
            window.this.$log.debug(this.PocetKolonek + 'Dokolika');
            if (window.MountCount == this.PocetKolonek + 1) this.MountingData = false // tohle neni uplne dobry napad delat
                switch (e.element.id) {
                    case '1':
                        this.objVia[0].id = e.value
                        break;
                    case '2':
                        this.objGrant[0].id = e.value
                        break;
                    case '3':
                        this.majetek[0].invc = e.value
                        if (isUndefined(this.majetek) || isNull(e.value)) {
                            this.majetek[0] = { content: '', invc: '' }
                            if (isUndefined(this.majetek[0].invc))
                                this.majetek[0].invc = ''
                        }
                        break;
                    case '4':
                       
                        break;
                    case '5':
                        if (e.value == false && !this.h) {
                            this.majetek[0].invc = ''
                            this.NewProductionOrder("upravit")
                        } 
                          return 0
                    case '6':

                        break;
                    case 'ownerchanged':
              this.$router.push('Pozadavky')
              return 1;
            }
            if (!isUndefined(e.value) && !isNull(e.value) && !this.h && !this.MountingData)
            this.NewProductionOrder("upravit")
        },
        GetProductionOrder(id) {
            let self = this;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetProductionOrder(id,
                function (result) {
                    window.this.$log.debug(result);
                    self.objGrant[0].content = result.Request.value.FundingTXT.value
                    self.objGrant[0].id = result.Request.value.Funding.value
                    self.objVia[0].id = result.Request.value.ReqVia.value
                    self.komentar = result.Request.value.Comment.value
                    if (self.komentar != '') self.PocetKolonek++
                    self.popis = result.Description.value;
                    self.checked1 = result.Repair.value;
                    if (result.Repair.value == true) self.PocetKolonek++
                   // self.majetek[0].content = result.AssetNumber.value
                    self.majetek[0].invc = result.AssetNumber.value
                }, window.this.ApiError());
        },
        open() {
            window.loader = this.$loading.show({
                loader: 'dots'
            });
        },
        hide() {
            window.loader.hide()
        },
        ListVias() {
            let self = this;
            self.vias.length = 0;
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('REQUEST_VIAS[1]', '', 1, 50,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        self.vias[x] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    if (self.h)
                        self.objVia[0] = self.vias[0]
                    else
                        self.GetProductionOrder(self.obj)
                }, window.this.ApiError());

        },
        NewProductionOrder(param) {
            let self = this;
            var wf = new window.WorkFlow(window.channel, window.message);
            if (param == 'vytvorit') {
                window.this.$log.debug(this.majetek + 'invcislo')

                window.this.$log.debug(self.via)
                wf.SetProductionOrder(0, self.objVia[0].id, self.objGrant[0].id, self.popis,self.komentar, self.majetek[0].invc,self.checked1,
                    function (result) {
                        if (result.Error.value.ErrorNum.value == 0) {
                            window.this.$log.debug(result);
                            self.obj = result.Value.value
                            window.localStorage.setItem("pozid", result.Value.value)
                            self.h = false;
                            self.DisplayRequest();
                        }
                        else {
                            notify({
                                message: result.Error.value.ErrorTextCZ.value,
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'error', 3000);

                        }
                        
                    }, window.this.ApiError());

            }
            else {
                
                wf.SetProductionOrder(self.obj, self.objVia[0].id, self.objGrant[0].id, self.popis, self.komentar, self.majetek[0].invc,self.checked1,
                    function (result) {
                        window.this.$log.debug(result);
                        if (result.Error.value.ErrorNum.value == 0) {
                            notify({
                                message: self.$t('pozadavky-VyvojoveDilnyZmenyUlozeny'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                            
                          //  self.DisplayRequest();
                        }
                        else {
                            notify({
                                message: result.Error.value.ErrorTextCZ.value,
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'error', 3000);

                        }
                    }, window.this.ApiError());
            }
        },
        ListFunding() {
            let self = this;
            self.granty.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.ListFunding(1, 3,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        self.granty[x] = {
                            content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
                            //  number: result.List.value.items[x].Text.value,
                            id: result.List.value.items[x].ID.value,
                        };
                    }
                    if (self.h)
                        self.objGrant[0] = self.granty[0]
                    self.ListVias()
                }, window.this.ApiError());
        },
    },
}
    ;     
</script>

<style scoped>
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}

#upravit {
    margin-bottom: 10px;
    
}

.actionsbuttons {
    clear: both;
    flex-direction: row;
    display: inline-block;
}

#button {
    margin-right: 40px;
}

label {
    margin-right: 20px;
}

#text1 {
    text-align: top;
    height: 70px;
    width: 300px;
}

input {
    text-align: top;
    margin-right: 40px;
}
    #link{float: left;
 }
  #obaleniResponsiveBox1,#obaleniResponsiveBox2{ border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 10px;
height: auto;
margin-bottom: 10px; background-color: rgba(128, 128, 128, 0.075);}
#obaleniResponsiveBox2{margin-bottom: 10px; margin-top: 0px;}
#MarginBottom{margin-bottom: 60px;}
    


.actionsPozadavky{
    align-self: flex-start;
}
.popupAnoNe{
    text-align: center;
}
#popupDivAnoNe{text-align: center;}

#checkboxOprava{flex-direction: row !important; border-style: solid; border-color: rgba(128, 128, 128, 0.4); border-width: 1px; padding: 5px; width: max-content; border-radius: 5px;}
#checkboxOprava:hover{flex-direction: row !important;color: black; }
#labelOprava{left: 0;right: 0; bottom: 0; top: 0;}
[h="false"]{visibility: hidden;}
#ResponsiveBoxItem{padding: 2px;}

@media only screen and (max-width: 768px) {
     
    
    }/*xs */
    @media only screen and (min-width: 778px) and (max-width: 992px) {
     }
  /*sm */
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        #checkboxOprava{margin-left: 85px;}
        
      }/*md */
    @media only screen and (min-width: 1200px) {
        #checkboxOprava{margin-left: 85px;}
        
    }

    .buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}
.clCheckBoxForma{
  margin-top:0px !important;  
}
</style>