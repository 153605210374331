<template >
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000690322?lang=en" 
PopisLinku='clanky.objednavkaKnihovna'></HelpLink>
<div height="calc(100% - 57px)">
    <div id="objednavky">
      <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
        :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')" :contentTemplate="popupAlertTemplate"
        :width="400" :height="200">
        <template #SmazatPoz>
          <h3>{{$t('objednavkaPresKnihovnu-OpravduSiPrejeteSmazatPozadavek')}}</h3>
          <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ano')"
            @click="SetState(-99, true); popupAlert=false " />
          <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ne')"
            @click="popupAlert=false" />
        </template>
      </DxPopup>
      <div id="horniListaPozadavku">
        <DxButton icon="back" :text="$t('pozadavky-ZpetNaPozadavky')" height="auto" type="outlined" styling-mode="contained" id="link"
          @click="$router.push('Pozadavky')" />
        <h2  class="nadpisH2Pozadavky fontAndColor prevent-select" v-if="h">{{$t('objednavkaPresKnihovnu-ZalozeniObjPresKnihovnu')}}</h2>
        <h2  class="nadpisH2Pozadavky fontAndColor prevent-select" v-else>{{$t('objednavkaPresKnihovnu-ObjPresKnihovnu:')}} {{ obj +' ('+state+')'}} </h2>
      </div>
  

      <form action="your-action" @submit="onFormSubmit($event)">
        <div id="obaleniResponsiveBox1">
        <DxResponsiveBox single-column-screen="sm">
          <DxRow :ratio="1" />
          <DxRow :ratio="1" />
          <DxRow :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxItem>
            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />
            <div id="ResponsiveBoxItem" v-if="false">
              <div class="dx-field" v-show="false">
                <DxSelectBox :disabled="stateid!=4 " :id="1" width='250px' height="45px" :label="$t('pozadavky-Pres')"
                  :data-source="vias" display-expr="content" value-expr="id" placeholder="" :value="objVia[0].id"
                  @value-changed="setSelectedValue">
                  <DxValidator>
                    <DxRequiredRule :message="$t('majetekPrevod-PresMusiBytVyplneno')" />
                  </DxValidator>
                </DxSelectBox>
                </div>
              </div>
          </DxItem>
          <DxItem>
            <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
            <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
            <DxLocation :row="0" :col="0" :colspan="4" screen="sm" />
            <DxLocation :row="0" :col="0" :colspan="4" screen="xs" />
            
            <div id="ResponsiveBoxItem">
              <div class="flexboxcontainer">
                <FundingInstructions :FundingID="currentGrant" Ident="Pozadavky4"> </FundingInstructions>
                <DxSelectBox style="flex-grow: 1;" :disabled="stateid!=4" :id="2" width="auto" height="45px" :label="$t('central-Grant')"
                  :data-source="granty" display-expr="content" value-expr="id" placeholder=""
                  :accept-custom-value="false" :search-enabled="false" :value=" this.currentGrant " @value-changed="setSelectedValue" class="GranPozadavkyKnihovna">
                  <DxValidator>
                    <DxRequiredRule :message="$t('central-GrantMusiBytVyplnen')" />
                  </DxValidator>
                </DxSelectBox>
              </div>
             
            </div>
          </DxItem>

        </DxResponsiveBox>
          </div>

          <div id="obaleniResponsiveBox2" v-if="!h">
          <DxResponsiveBox single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />

                    <DxItem>
                       <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                      <div id="ResponsiveBoxItem">
                        <div class="dx-field" v-if="!h">
                              <DxTextArea :disabled="stateid!=4" id="6" :label="$t('central-Komentar')" width="auto" :height="100"
                                v-model:value="komentar" placeholder="" @value-changed="setSelectedValue($event)" />
                        </div>
                      </div>
                    </DxItem>

                    <DxItem>
                        <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
                      <div id="ResponsiveBoxItem">
                        <div v-if="!h">
                          <Attachments :Disabled="stateid != 4" :Pozid="this.obj" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                              
                            </div> 
                        </div>
                    </DxItem> 

        </DxResponsiveBox>
        </div>
      
      <div class="table" v-show="!h" id="obaleniDataGridu">
        <h3>{{$t('pozadavky-Objednavka-PolozkyObjednavky')}}</h3>
        <DxDataGrid :allow-column-resizing="true" :column-min-width="60" :ref="dataGridRefKey" id="DataGrid" :hoverStateEnabled="true" :rowAlternationEnabled="true" 
          :data-source="GridRows" :allow-column-reordering="true" :show-borders="true" key-expr="Id" background-color="transparent"
          @row-click="startEdit" :noDataText="$t('central-NenalezenyZadneZaznamy')" @init-new-row="InitNewRow" @saved="GridEditting" @edit-canceled="this.zam=-1"
          @editing-start="InitNewRow($event);" @value-changed="InitNewRow"
          :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}">
          <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
          <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="GridObjKnihovna"
         />
         <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :allow-export-selected-data="false"
          :texts="{exportAll: $t('central-ExportVseExcel')}"
          />
          <DxToolbar :visible="stateid==4">
            
            <DxItem location="after" locate-in-menu="auto">
              <DxButton icon="add" id="PridatZaznam" height="auto" width="auto" :text="$t('central-PridatZaznam')" :hint="$t('central-PridatZaznam')"
                             type="success" styling-mode="contained" @click="AddRowButtonClick" class="actionButtonsPozadavkyResizableB" />
            </DxItem>
            
           
            <DxItem location="before" locate-in-menu="auto" >
                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')" :hint="$t('central-ObnovitPuvodniRozlozeni')"
                 type="success" styling-mode="outlined" @click="GridLayoutReset" class="actionButtonsPozadavkyResizableB"/>      
            </DxItem>
            <DxItem
              name="exportButton" location="after" locate-in-menu="auto"
            />

            <DxItem
              name="searchPanel" location="after" locate-in-menu="auto"
              :visible="true"
            />
          </DxToolbar>       
         <DxSummary>
        <DxTotalItem
          summary-type="count"
          showInColumn="description"
          :display-format="$t('central-PolozekCelkem')+' {0}'"
          cssClass="polozekCelkem"
        />
        <DxTotalItem column="pocet" summary-type="sum" showInColumn="pocet" :display-format="'‎ '" cssClass="sum" />
        <DxTotalItem 
          column="celkem"
          summary-type="sum"
          value-format=",##0.00"
          :display-format="$t('central-SumaCelkem')+' {0}'"
          cssClass="cenaCelkem"
        />
      </DxSummary>

          <template #SelectBoxEditor="{ data: cellInfo }">
            <DxSelectBox :id="6" ref="SelectBoxRefKey" height="auto" :searchable="true"
              search-mode="contains" :data-source=" typyDokumentu " display-expr="content" value-expr="id"  placeholder="" 
              @value-changed="SetValue($event,cellInfo)" :noDataText="$t('central-NenalezenyZadnePolozky')" :value="cellInfo.value"> </DxSelectBox>
          </template>
          <template #SelectBoxEditorDeponovano=" { data: cellInfo } ">
                <DxSelectBox :disabled=" !cellInfo.column.allowEditing" :id="4" :placeholder="''"
                    :data-source="zamestnanci" display-expr="content" value-expr="id" 
                    @value-changed=" SetValueLookupZam($event, cellInfo)  " :search-enabled=" true " search-mode="contains" :search-timeout=" 1000 "
                    class="DeponovanoUPozadavkyKnihovna"
                    @input="SearchInput" :value=" this.zam " noDataText="">
              </DxSelectBox>
              <!-- :placeholder=" $t('central-Placeholder') " -->
            </template>
            <template #SelectBoxEditorForma=" { data: cellInfo } ">
              <DxSelectBox :id=" 6 " ref="SelectBoxRefKey" height="auto" :searchable=" true "
                search-mode="contains" :data-source="formy " display-expr="content" placeholder="" value-expr="id"
                @value-changed=" SetValueLookup($event, cellInfo) " :noDataText=" $t('central-NenalezenyZadnePolozky') " :value=" this.forma " :disabled="!cellInfo.column.allowEditing"> </DxSelectBox>
            </template>
            <!-- 
          <template #DropDownBoxEditor="{ data: cellInfo }">
            <DxDropDownBox ref="dropdown" v-model:value="treeBoxValue" :show-clear-button="true" :data-source="KnihovnaMedia" value-expr="ID"
              display-expr="name" placeholder="" @value-changed="syncTreeViewSelection($event, cellInfo);" >
              <template #content="{ }">
                <DxTreeView :ref="treeViewName" :data-source="KnihovnaMedia" :select-by-click="true"
                  :select-nodes-recursive="false" data-structure="plain" key-expr="ID" parent-id-expr="categoryId"
                  selection-mode="multiple" show-check-boxes-mode="normal" display-expr="name" 
                  @content-ready="syncTreeViewSelection($event)" @item-selection-changed="treeView_itemSelectionChanged($event, cellInfo)" />
              </template>
            </DxDropDownBox>
          </template>
          -->
          <DxColumn width="60px" data-field="Id" :allow-editing="false" :visible="false"
            :formItem="{ visible: false }" />
          <DxColumn data-field="description" :caption="$t('central-Popis')"> <!-- + -->
            <DxRequiredRule :message="$t('central-PopisMusiBytVyplnen')" />
          </DxColumn>     
          <DxColumn  data-field="isbn" :caption="$t('central-ISBN/ISSN')" width="120px" :allow-editing="true"   />  <!-- + -->
          <DxColumn data-field="url" width="60px" caption="URL">
          </DxColumn>
          <DxColumn format=",##0.00" data-field="pocet" :caption="$t('central-Pocet')" width="70px" 
            data-type="number">  <!-- + -->
            <DxNumericRule :message="$t('central-PocetMusiBytCislo')" />
            <DxRequiredRule :message="$t('central-PocetMusiBytVyplnen')" />
          </DxColumn>
          <DxColumn format=",##0.00" :allow-editing="!katalog" width="130px" data-field="cena" data-type="number"
            :caption="$t('central-Cena')" >  <!-- + -->
            <DxNumericRule :message="$t('central-CenaMusiBytCislo')" />
            <DxRequiredRule :message="$t('central-CenaMusiBytVyplnena')" />
          </DxColumn>
          <DxColumn  data-field="menaid" width="80px" :caption="$t('central-Mena')" placeholder=""> <!-- + -->
            <DxLookup :data-source="meny" display-expr="content" value-expr="id">
            </DxLookup>
            <DxRequiredRule :message="$t('central-MenaMusiBytVyplnena')" />
          </DxColumn>
          <DxColumn data-field="typ" width="120px" :caption="$t('central-TypDokumentu')" edit-cell-template="SelectBoxEditor">
            <DxLookup :data-source="typyDokumentu" display-expr="content" value-expr="id">
              </DxLookup>
            <DxRequiredRule :message=" $t('central-TypMusiBytVyplnen') " />
          </DxColumn>  <!-- + -->  <!-- + -->
          <DxColumn data-field="deponovano" width="120px" :caption="$t('central-DeponovanoU:') " :allow-editing=" false " >

          </DxColumn>
          <DxColumn data-field="deponovanoid" width="120px" :caption=" $t('central-DeponovanoU:') " :visible=" false " edit-cell-template="SelectBoxEditorDeponovano" :allow-editing=" false " >

            </DxColumn>
          <DxColumn data-field="forma" width="70px" :caption="$t('central-Forma')" edit-cell-template="SelectBoxEditorForma" :allow-editing=" false ">  <!-- + -->
            <DxLookup :data-source="formy " display-expr="content" value-expr="id" ></DxLookup>
           <!--  <DxRequiredRule message="Forma musí být vyplněna." /> -->
          </DxColumn>
          <DxColumn format=",##0.00" width="130px" data-field="celkem" :allow-editing="false" :caption="$t('central-Celkem')">
            <DxNumericRule :message="$t('central-CelkemMusiBytCislo')" />
          </DxColumn>
          
          <DxEditing :allow-updating="stateid==4" :allow-deleting="stateid == 4"
            :allow-adding="stateid == 4" mode="form" :editRow="$t('central-Upravit')" :texts="{
              saveRowChanges: $t('central-Potvrdit'), cancelRowChanges: $t('central-Zrusit'), editRow: '', deleteRow: $t('central-Smazat'),
              confirmDeleteTitle: $t('central-Varovani'), confirmDeleteMessage: $t('central-OpravduSiPrejeteSmazatZaznam'),
            }">
            <DxForm>
              <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem data-field="description" />
                <DxItem data-field="isbn" />
                <DxItem data-field="url" />
              </DxItem>
              <DxItem :col-count="3" :col-span="2" item-type="group">      
                <DxItem data-field="pocet" />
                <DxItem data-field="cena" :editor-options="{ step: 0 }"/>  
                <DxItem data-field="menaid"/>
                
              </DxItem>           
              <DxItem :col-count="3" :col-span="2" item-type="group"> 
                <DxItem data-field="typ" />
                <DxItem data-field="deponovanoid" />
                <DxItem data-field="forma"  />    
                 
              </DxItem>      
            </DxForm>
          </DxEditing>
          <DxPaging :enabled="false" />
          <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
          <DxGrouping :auto-expand-all="true" />
          <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
          <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
        </DxDataGrid>
        
      </div>
    <div width="100%" class="SpodniMenuDetailPozadavku">
                <DxResponsiveBox single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5"/>
                    <DxItem>
                      <DxLocation :row="0" :col="0" :colspan="5" screen="lg" />
                      <DxLocation :row="0" :col="0" :colspan="5" screen="md" />
                      <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                      <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                      <div v-if="h" id="ResponsiveBoxItemActionButtons">
                              <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('pozadavky-VytvoreniObjednavky')"
                                :use-submit-behavior="true" type="success" v-if="h" />
                        </div>
                        </DxItem>
                    <DxItem>
                      <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                      <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                      <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                      <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                     
                      <div  v-if="!h">
                        <DxScrollView direction="horizontal" showScrollbar="always" width="100%" >
                          <div id="ResponsiveBoxItemActionButtons">
                            <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item">
                              <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default" styling-mode="contained"
                                :text="item.nazev" :hint="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                            </div>
                            <div class="child actionButtonsPozadavkyMargin" >
                               <ChangeOwner :Disabled="false" :RequestID="obj" @owner-changed="setSelectedValue" class="actionButtonsPozadavkyResizableA">  </ChangeOwner>
                              </div>
                           
                      </div>
                    </DxScrollView>
                    </div>
                        </DxItem>
                </DxResponsiveBox>
              
      </div>
    </form>
  </div>
</div>
  
</template>

<script>
const font = '';
const dataGridRefKey = 'my-data-grid';
const treeViewName = 'treeView';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel} from 'devextreme/excel_exporter';
import { KnihovnaMedia } from '@/data/KnihovnaMedia.js';
import "devextreme/ui/html_editor/converters/markdown";
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import FundingInstructions from '@/components/FundingInstructions.vue';
import ChangeOwner from '@/components/ChangeOwner.vue';
import HelpLink from '@/components/HelpLink.vue';
import Attachments from '@/components/Attachments.vue';
import {  isNull, isUndefined, isFunction } from 'lodash';
import {
  DxSummary,
  DxTotalItem,
  DxStateStoring,
  DxExport,
  DxLoadPanel,
  DxToolbar,
  DxSorting
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
const suppliersDataSource = new DataSource({
  store: {
    data: '',//suppliersData,
    type: 'array',
    key: 'id',
  },
});
const KatalogDataSource = new DataSource({
  store: {
    data: '',//suppliersData,
    type: 'array',
    key: 'id',
  },
});
export default {
  name: "ObjKnihovna",
  components: {
  Attachments,
  FundingInstructions,
  DxExport,
  DxStateStoring,
  DxSummary,
  DxTotalItem,
  DxLoadPanel,
  DxToolbar,
  ChangeOwner,
  HelpLink,
  DxScrollView,
  DxSorting
  },

  data() {
    return {
      //  pattern: /^\d{}/i,
      formData: {
        description: '',
        isbn: '',
        url: '',
        pocet: 1,
        cena: 0,
        menaid: 0,
        typ: 0,
        deponovano: 0,
        forma: 0,
      },
      editaceVlastnika: false,
      SelectBoxValue: [],
      aaa: false,
      currentGrant: 0,
      PocetKolonek: 1,
      MountingData: false,
      stateid: 4,
      KnihovnaMedia,
      treeDataSource: null,
      treeBoxValue: null,
      treeViewName,
      typyDokumentu: [],
      formy: [],
      zamestnanci: [],
      zam: -1,
      forma: -1,
      katalogSelect: false,
      katalog: false,
      dataGridRefKey,
      katalogDodavatel: "",
      katalogVybran: false,
      KatalogList: [],
      screen: '',
      state: 'Uložený',
      popupAlert: false,
      popupAlertTemplate: '',
      actionsPozadavky: [],
      objSupplier: {},
      objGrant: [{ content: '', id: -1 }],
      objVia: [{ content: '', id: -1 }],
      komentar: '',
      meny: [],
      mena: { "content": 'CZK', "id": 129 },
      suppliers: [],
      supplier: "",
      granty: [],
      vias: [],
      showModal: false,
      popis: '',
      pocet: 1,
      menaid: '',
      cenaid: '',
      jednotka: 'ks',
      cena: '100',
      typ: 'Zakazka',
      klasifikace: '',
      obj: '',
      invC: '',
      h: true,
      AssetNumber: '',
      novySupplierId: 0,
      ProductNumber: '',
      GridRows: [],

    }
  },
  computed: {
    dataGrid: function () {
      return this.$refs[dataGridRefKey].instance;
    },
    treeView() {
      return this.$refs[treeViewName].instance;
    },
  },
  created() {
    this.treeDataSource = this.makeAsyncDataSource('objKnihovnaMedia.json');
  },
  mounted() {
    window.MountCount=0
    //this.treeBoxValue = [ '2_4']
    this.MountingData = true
    this.GridOptions()
    this.Meny();
    this.TypyDokumentu();
    this.Formy();
   // this.Specifikace();
    this.ListFunding();
   // this.ListVias();
   // this.NewLibraryOrderItem(2507617, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)
    if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
      window.this.$log.debug(' ve funkciaa')
      this.h = false
      this.obj = window.localStorage.getItem("pozid")
      this.DisplayRequest();

    }
    this.LoadBarvyTheme();

  },

  methods: {
    LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
    onExporting(e) {
        window.this.$log.debug('test uvnitr onexporting')
        if (e.format==="pdf")  {
        const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
        
        var callAddFont = function () {
        this.addFileToVFS('GEO_AI__-normal.ttf', font);
        this.addFont('GEO_AI__-normal.ttf', 'GEO_AI__', 'normal');
        };
        jsPDF.API.events.push(['addFonts', callAddFont])

        

        exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component,
          indent: 0,
          
          
          columnWidths: [30, 50, 22, 30,0],
          

          customizeCell: function(options) {
            const { gridCell, pdfCell } = options;
          
                      if(gridCell.rowType === 'data') {
                        pdfCell.font = { size: 13, name:'Courier'};
                        pdfCell.padding = { top: 1, right: 1, bottom: 1, left: 1 };
                        pdfCell.wordWrapEnabled=true;
                        pdfCell.textColor='#000000' 
                      }
                      if(gridCell.rowType === 'data' && gridCell.column.dataField === this.columnBLOKOVANO){
                        pdfCell.textColor='#ffffff'

                      }
                                      
           
         
          
          }
        }).then(() => {
          
          const datum= new Date();
          doc.save(this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".pdf");
        });
        e.cancel = true;
      }
      else if (e.format==="xlsx") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Companies");
        exportDataGridToExcel({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            const datum= new Date();
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".xlsx"
            );
          });
        });
        e.cancel = true;
      }
    
    
    
    },
    GridLayoutReset(e) {
      this.$log.debug(e)
      this.$log.debug(this.dataGrid)
      this.dataGrid.state(null);
    },
    AddRowButtonClick(e){
      this.$log.debug(e)
      this.dataGrid.addRow();
    },
    SetMediaData(e) {
      window.this.$log.debug('init')
      window.this.$log.debug(e)
      this.timer = setTimeout(() => {
        e.component.cellValue(window.rowIndex, "media", this.treeBoxValue)
      }, 1000)
      
    },
    makeAsyncDataSource(jsonFile) {
      return new CustomStore({
        loadMode: 'raw',
        key: 'ID',
        load() {
          return fetch(`../data/${jsonFile}`)
            .then((response) => response.json());
        },
      });
    },
    SetValueDropDown(e, cellInfo) {
      window.this.$log.debug(e)
      cellInfo.component.cellValue(cellInfo.rowIndex, "media", this.treeBoxValue)
      
    },
    syncTreeViewSelection(e, cellInfo) {
   //   window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
      if (!isUndefined(cellInfo))
      window.rowIndex = cellInfo.rowIndex
      const treeView = (e.component.selectItem && e.component)
        || (this.$refs[this.treeViewName] && this.$refs[this.treeViewName].instance);
      //  window.this.$log.debug(treeView)
    //  treeView.unselectAll();
      
      if (treeView) {
        window.treeViewComponent = treeView
        if (e.value === null) {
         // cellInfo.component.cellValue(cellInfo.rowIndex, "media", null)
          treeView.unselectAll();
        } else {
          const values = e.value || this.treeBoxValue;
          values && values.forEach((value) => {
            treeView.selectItem(value);

          });
        }
      }
    },
    treeView_itemSelectionChanged(e, cellInfo) {
     // window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
      window.this.$log.debug(cellInfo.component.columnOption("deponovano"))
      this.treeBoxValue = e.component.getSelectedNodeKeys();
      if (e.node.itemData.ID == 3) {
      //  window.this.$log.debug('in')
        cellInfo.component.columnOption("deponovano", "visible", true)
       }
    },
    GetEmployee(empID) {
      var gen = new window.General(window.channel, window.message);
      let self = this;
      self.zamestnanci.length = 0;
      //  window.this.$log.debug(employee)
      gen.ListEmp(empID,
        function (result) {
          //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };

            if (x > 20) break;
          }
          self.zam=self.zamestnanci[0].id
        }, window.this.ApiError());
    },
    GetEmployees(employee) {
      if (employee.component != undefined) {
        var e = employee
        employee = e.component.option("text")
      }
      var gen = new window.General(window.channel, window.message);
      let self = this;
      self.zamestnanci.length = 0;
      //  window.this.$log.debug(employee)
      gen.ListEmp(employee,
        function (result) {
          //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[self.zamestnanci.length] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
            if (x > 20) break;
          }
          e.component.getDataSource().reload();
        }, window.this.ApiError());
    },
    ClearValue(e) {
      if (e.component.option("text") != '') e.component.reset()
    },
    SearchInput(e) {
      let self = this;
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        self.GetEmployees(e)
      }, 1000)

    },
    SetMena(e) {
      window.this.$log.debug(e)
      // this.dataGrid.cellValue(window.rowIndex, "description", cellvalue)
    },
    SelectBoxClick(e) {
      window.this.$log.debug(e)
    },
    setCellValuePocet(newData, value, currentRowData) {
      window.this.$log.debug(newData)
      window.this.$log.debug(currentRowData)
      window.this.$log.debug(value)
      //  window.this.$log.debug(((currentRowData.cena).replace(/\./g, '').replace(',', '.')).replace(/\s/g, ''))
      newData.pocet = value;
      // newData.celkem = (((currentRowData.cena).replace(/\./g, '').replace(',', '.')).replace(/\s/g, '') * value).toLocaleString('cs-CZ');
      newData.celkem = (currentRowData.cena * value).toFixed(2);

      //  this.dataGrid.editRow(1);

    },
    setCellValueCena(newData, value, currentRowData) {
      newData.cena = value;
      newData.celkem = (currentRowData.pocet * value).toFixed(2);
    },
    RowHeight(e) {
      window.this.$log.debug(e)
      //   e.rowElement.style.height = '100px';
    },
    ChangeColumnProps(id, cellInfo) {
      var ident = this.typyDokumentu.find(x => x.id === id).ident1;
      //console.log(ident)
      cellInfo.component.columnOption('deponovanoid', 'validationRules', [])
      cellInfo.component.columnOption('deponovanoid', 'allowEditing', false)    
      cellInfo.component.columnOption('forma', 'allowEditing', false)
      cellInfo.component.columnOption('forma', 'validationRules', []) 
      if (!ident.includes('forma')) {
        cellInfo.component.columnOption('forma', 'value', null)
        this.forma = -1
      }
      if (!ident.includes('deponovanoid')) {
        cellInfo.component.columnOption('deponovanoid', 'value', null)
        this.zam = -1
      }
      if (!isUndefined(ident)) {
        var str_array = ident.split(',');
        for (var i = 0; i < str_array.length; i++) {
          cellInfo.component.columnOption(str_array[i], 'validationRules', [{ 
            message: (str_array[i] == 'deponovanoid') ? this.$t('majetekPrevod-ZamestnanecMusiBytVyplnen') : this.$t('central-FormaMusiBytVyplnena'), 
            type: "required"
          }])
          cellInfo.component.columnOption(str_array[i], 'allowEditing', true)
        }   
      }
    },
    SetValue(e, cellInfo) {
      window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
      cellInfo.setValue(e.value);
     // this.SelectBoxValue[0] = e.value
      e.component.getDataSource().reload();
      this.$log.debug(cellInfo.component.columnOption('forma'))
      this.typ = e.value
      this.ChangeColumnProps(e.value, cellInfo)     
    },
    SetValueLookup(e,cellInfo) {
      window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
      if(isFunction(cellInfo.setValue) && e.value != -1) cellInfo.setValue(e.value);
      this.forma=e.value
    },
    SetValueLookupZam(e, cellInfo) {
      window.this.$log.debug(e)
      window.this.$log.debug(cellInfo)
      if (isFunction(cellInfo.setValue) && e.value!=-1 ) cellInfo.setValue(e.value);
      this.zam=e.value 
    },
    log(log) {
      window.this.$log.debug(log)
    },

    ListKatalog(search, dodavatel, e) {
      var self = this;
      self.KatalogList.length = 0
      var orders = new window.Orders(window.channel, window.message);
      window.this.$log.debug(search)
      window.this.$log.debug(dodavatel)
      if (self.katalogVybran == true)
        dodavatel = self.katalogDodavatel
      window.this.$log.debug(dodavatel)
      if (isNull(search) || search == '') search = ' '
      window.this.$log.debug(search)
      orders.KatalogList(search, dodavatel, 30,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            // var i=self.KatalogList.length;             

            self.KatalogList[x] = {
              content: result.items[x].nazev.value,
              id: result.items[x].id.value,
              supplier: result.items[x].dodavatel.value,
              jednotka: result.items[x].jednotka.value,
              katcis: result.items[x].katcis.value,
              cena: result.items[x].cenaj.value,
            };
          }
          window.this.$log.debug(e.component.getDataSource)
          if (!isNull(e.component.getDataSource()))
            e.component.getDataSource().reload();

        }, window.this.ApiError());
    },
    ListKatalogv2(search, dodavatel) {
      var self = this;
      self.KatalogList.length = 0
      var orders = new window.Orders(window.channel, window.message);
      window.this.$log.debug(search)
      window.this.$log.debug(dodavatel)
      if (self.katalogVybran == true)
        dodavatel = self.katalogDodavatel
      window.this.$log.debug(dodavatel)
      if (isNull(search) || search == '') search = ' '
      window.this.$log.debug(search)
      orders.KatalogList(search, dodavatel, 30,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            // var i=self.KatalogList.length;             

            self.KatalogList[x] = {
              content: result.items[x].nazev.value,
              id: result.items[x].id.value,
              supplier: result.items[x].dodavatel.value,
              jednotka: result.items[x].jednotka.value,
              katcis: result.items[x].katcis.value,
              cena: result.items[x].cenaj.value,
            };
          }

        }, window.this.ApiError());
    },
    FileDelete(FileID) {
      var self = this;
      var gen = new window.General(window.channel, window.message);
      gen.FileDelete(1, self.obj, FileID,
        function (result) {
          window.this.$log.debug(result);
          self.Prilohy();
        }, window.this.ApiError());

    },
    async downloadItem(url, name, suffix) {
      window.this.$log.debug(suffix)
      suffix = '.pdf'
      axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
        .then(function (response) {
          switch (suffix) {
            case '.pdf': {
              window.this.$log.debug(response)
              const blob = new Blob([response.data], { type: 'application/' + suffix });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              window.this.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              break;
            }
          }
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          window.this.$log.debug(JSON.stringify(error))
        })
    },
    GetHash(FileName, FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(FileID,

        function (result) {
          window.this.$log.debug(result);
          self.hash = window.URL1+'/js/download/' + result.Value.value;
          window.this.$log.debug(self.hash + " " + FileName)
          self.downloadItem(self.hash, FileName);

        }, window.this.ApiError());
    },
    FileUpload(file) {
      window.this.$log.debug(file)
      var self = this;
      var gen = new window.General(window.channel, window.message);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        window.this.$log.debug(theFile.name)
        return function (e) {
          gen.FileUpload(1, self.obj, theFile.name,
            e.target.result,
            function (result) {
              window.this.$log.debug(result);
              self.Prilohy()
            }, window.this.ApiError());
        }
      })(file);
      reader.readAsBinaryString(file);

    },
    SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      let self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) stav = -1
      self.open();
      wh.SetState(self.obj, stav, '',
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            self.DisplayRequest();
            if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')
            
            var stavText;
            switch (stav) {
              case -1: stavText = self.$t('pozadavky-StavZrusen')
                break;
              case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                break;
              case 4: stavText = self.$t('pozadavky-StavUlozeny')
                break;
            }
            notify({
              message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 5000);
          }
          self.hide();
        }, window.this.ApiError());

    },
    DisplayRequest() {
      window.this.$log.debug('display request')
      let self = this;
      self.actionsPozadavky.length = 0;
      var wh = new window.WorkFlow(window.channel, window.message);
      wh.GetRequest(self.obj,
        function (result2) {
          window.this.$log.debug(result2);
          self.state = result2.ReqStateText.value;
          self.stateid = result2.ReqState.value;
          self.editaceVlastnika= result2.AbleToEditOwner.value
          if (!result2.AbleToEdit.value) self.stateid = -1;
          for (var x = 0; x < result2.Actions.value.items.length; x++) {
            var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.obj
            };
          }
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },

    customItemCreating(data, id) {
      window.this.$log.debug(data)
      window.this.$log.debug(data.text + ' text')

      if (!data.text) {
        data.customItem = null;

        return;
      }


      //  const itemIds = suppliersData.map((item) => item.ID);
      //  const incrementedId = Math.max.apply(null, itemIds) + 1;
      if (id == 1) {
        const newItem = {
          content: data.text,
          // id: incrementedId,
        };
        data.customItem = suppliersDataSource
          .store()
          .insert(newItem)
          .then(() => suppliersDataSource.load())
          .then(() => newItem)
          .catch((error) => {
            throw error;
          });
      }
      else {
        const newItem = {
          content: data.text,
          // id: incrementedId,
        };
        data.customItem = KatalogDataSource
          .store()
          .insert(newItem)
          .then(() => KatalogDataSource.load())
          .then(() => newItem)
          .catch((error) => {
            throw error;
          });
        window.this.$log.debug(window.rowIndex)
        window.this.$log.debug(data.text + ' text pred')
        var cellvalue = { content: data.text }
        window.this.$log.debug(this.dataGrid.cellValue(window.rowIndex, "description"))
        this.dataGrid.cellValue(window.rowIndex, "description", cellvalue)

      }

    },

    GridOptions() {
      window.this.$log.debug(this.dataGrid)
    },
    onFormSubmit(e) {
      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "vytvorit") {
        notify({
          message: this.$t('pozadavky-ObjednavkaZalozena'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
        this.NewLibraryOrder("vytvorit")
      }
      else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "upravit") {
        notify({
          message: this.$t('pozadavky-ObjednavkaZmenyUlozeny'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
        this.NewLibraryOrder("upravit");
      }
      e.preventDefault();
    },
    LookupValueChanged(e) {
      window.this.$log.debug('value changed event')
      window.this.$log.debug(e)
    },
    InitNewRow(e) {
     // var self = this
     this.$log.debug(e)
      window.rowIndex = undefined
     if(isUndefined(e.data.pocet)){ 
      e.data.pocet = 1;
      e.data.menaid = 129;     
      this.zam = -1;
      this.forma = -1;
      }
      e.component.columnOption('deponovanoid', 'validationRules', [])
      e.component.columnOption('deponovanoid', 'allowEditing', false)
      e.component.columnOption('forma', 'allowEditing', false)
      e.component.columnOption('forma', 'validationRules', [])
    },
    DeleteLibraryOrderItem(id) {
      var self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.DeleteLibraryOrderItem(id,
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

          }
          self.PolozkyObjednavkyGrid(self.obj)
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },
    GridEditting(data) {
     // if()     
     // var self = this;
      window.this.$log.debug(data)
      var url = '', isbn = '';
      if (data.changes.length != 0) {
       
        switch (data.changes[0].type) {
          case 'insert':
             if (!isUndefined(data.changes[0].data.url)) url = data.changes[0].data.url
            if (!isUndefined(data.changes[0].data.isbn)) isbn = data.changes[0].data.isbn
            window.this.$log.debug("Přidání nové položky")
           // NewLibraryOrderItem(requestId, id, Description, Price, Amount, TypeOfDocument, ISBN_ISSN, Form, Currency, DepositedAtEmployeeID, url,) 
            this.NewLibraryOrderItem(this.obj, 0, data.changes[0].data.description, data.changes[0].data.cena, data.changes[0].data.pocet,
            data.changes[0].data.typ, isbn, this.forma, data.changes[0].data.menaid, this.zam, url)
            break;
          case 'update':
             if (!isUndefined(data.changes[0].data.url)) url = data.changes[0].data.url
            if (!isUndefined(data.changes[0].data.isbn)) isbn = data.changes[0].data.isbn
            this.NewLibraryOrderItem(this.obj, data.changes[0].key, data.changes[0].data.description, data.changes[0].data.cena, data.changes[0].data.pocet,
              data.changes[0].data.typ, isbn, this.forma, data.changes[0].data.menaid, this.zam, url)
            break;
          case 'remove':
            window.this.$log.debug("remove")
            window.this.$log.debug(data.changes[0].key)
            this.DeleteLibraryOrderItem(data.changes[0].key)
            break;

          //    default:
          //      break;

        }
      }
      this.zam=-1
    },
    UpdateItem(data) {
      window.this.$log.debug('update')
      window.this.$log.debug(data)
      if (data.changes[0].type == 'update') window.this.$log.debug("Přidání nové položky")
    },
    RemoveItem(data) {
      window.this.$log.debug('remove')
      window.this.$log.debug(data)
      if (data.changes[0].type == 'remove') window.this.$log.debug("Přidání nové položky")
    },
    setEditedValue(valueChangedEventArg, cellInfo) {
      window.this.$log.debug(cellInfo + ' DATAAA')
      window.this.$log.debug(valueChangedEventArg)
      //  this.mena = valueChangedEventArg.value
      cellInfo.setValue(valueChangedEventArg.value.content);
    },
    RefreshDataGrid() {
      this.dataGrid.refresh()
        .then(function () {
          window.this.$log.debug('REFRESH SUCCESS')
          // ...
        })
        .catch(function (error) {
          window.this.$log.debug(JSON.stringify(error))
          window.this.$log.debug('REFRESH ERROR')
          // ...
        });
    },

    startEdit(e) {
       window.this.$log.debug(e)
      window.Index = e.rowIndex
      e.component.editRow(e.rowIndex);
      window.this.$log.debug('edit')
      if (!isUndefined(e.data.deponovanoid)) {
        this.GetEmployee(e.data.deponovanoid)
      }
      this.forma = e.data.forma;
      this.ChangeColumnProps(e.data.typ,e)
     
    },
    setSelectedValue(e) {
      window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      if (window.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
      switch (e.element.id) {
        case '1':
          this.objVia[0].id = e.value
          break;
        case '2':
          this.currentGrant = e.value
          //   this.objGrant[0].id = e.value
          break;
        case '3':
          this.objSupplier = e.value
          if (isUndefined(this.objSupplier))
            this.objSupplier = ''
          window.this.$log.debug(this.objSupplier)
          break;
        case '4':
          // this.zam=e.value
          break;
        case '6':
          window.this.$log.debug('jop')
          break;
        case 'ownerchanged':
          this.$router.push('Pozadavky')
          return 1;
      }
      if (!isUndefined(e.value) && !isNull(e.value) && !this.h && !this.MountingData)
        this.NewLibraryOrder("upravit")
    },

    PolozkyObjednavkyGrid(id) {
      let self = this;
      let self2 = this.GridRows;
      self2.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.GetLibraryOrder_v2(id,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.Items.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self2.length;
            if (result.Items.value.items[x].Currency.value == 0) result.Items.value.items[x].CurrencyTXT.value = 'CZK'
            self2[i] = {
              description: result.Items.value.items[x].Title.value,
              pocet: result.Items.value.items[x].Amount.value,
              menaid: result.Items.value.items[x].Currency.value,
              cena: (result.Items.value.items[x].UnitPrice.value),
              celkem: (result.Items.value.items[x].TotalCZK.value),
              //cena: (result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
              // celkem: (result.Items.value.items[x].Amount.value * result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
              typ: result.Items.value.items[x].TypeOfDocument.value,
              Id: result.Items.value.items[x].Id.value,
              deponovano: result.Items.value.items[x].DepositedAtTXT.value,
              deponovanoid: (result.Items.value.items[x].DepositedAt.value == -1) ? null : result.Items.value.items[x].DepositedAt.value,
              forma: (result.Items.value.items[x].Form.value == -1) ? null : result.Items.value.items[x].Form.value,
              isbn: result.Items.value.items[x].ISBN_ISSN.value,
              url: result.Items.value.items[x].Url.value,
            };
            self.RefreshDataGrid()
          }
          self.objGrant[0].content = result.Request.value.FundingTXT.value
          self.objGrant[0].id = result.Request.value.Funding.value
          if(result.Request.value.Funding.value>0) self.PocetKolonek++
          self.currentGrant = result.Request.value.Funding.value
          //  self.objVia.content = result.Request.value.ReqViaTXT.value
          self.objVia[0].id = result.Request.value.ReqVia.value
          window.this.$log.debug(self.objVia.id + ' obj via')
          self.komentar = result.Request.value.Comment.value
          if (self.komentar != '') self.PocetKolonek++
        }, window.this.ApiError());
    },
    ModalShow() {
      this.showModal = true;

    },
    open() {
      window.loader = this.$loading.show({
        loader: 'dots'
      });
    },
    hide() {
      window.loader.hide()
    },
    SearchKatalog(e) {
      window.this.$log.debug(e)
      // window.this.$log.debug(e.component.option("text"))
      let self = this;
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (isUndefined(self.objSupplier) || isNull(self.objSupplier) ||
          isUndefined(self.objSupplier.content) || self.objSupplier == '' || self.katalogVybran == false)
          self.ListKatalog(e.component.option("text"), '', e)
        else self.ListKatalog(e.component.option("text"), self.objSupplier.content, e)
      }, 1000)

    },
    ListVias() {
      let self = this;
      self.vias.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('REQUEST_VIAS[1]', '', 1, 50,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            self.vias[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          if (self.h)
            self.objVia[0] = self.vias[0]
          else
            self.PolozkyObjednavkyGrid(self.obj)
        }, window.this.ApiError());

    },
    NewLibraryOrder(param) {
      let self = this;
      var wf = new window.WorkFlow(window.channel, window.message);
      this.$log.debug(self.objGrant[0].id)
      if (param == 'vytvorit') {
        window.this.$log.debug(self.via)
        wf.SetLibraryOrder(0, self.objVia[0].id, self.currentGrant, self.komentar,
          function (result) {
            window.this.$log.debug(result);
            self.obj = result.Value.value
            window.localStorage.setItem("pozid", result.Value.value)
            self.h = false;
            self.DisplayRequest();
            self.dataGrid.addRow()
          }, window.this.ApiError());

      }
      else {
        wf.SetLibraryOrder(self.obj, self.objVia[0].id, self.currentGrant, self.komentar,
          function (result) {
            window.this.$log.debug(result);
            if (result.Error.value.ErrorNum.value == 0) {
              notify({
                message: self.$t('pozadavky-ObjednavkaZmenyUlozeny'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);
              
           // self.DisplayRequest();
            }
            else {
              notify({
                message: result.Error.value.ErrorTextCZ.value,
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'error', 3000);

            }
          }, window.this.ApiError());
      }
    },
    ListFunding() {
      let self = this;
      self.granty.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.ListFunding(1, 3,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            self.granty[x] = {
              content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
              //  number: result.List.value.items[x].Text.value,
              id: result.List.value.items[x].ID.value,
            };
          }
          self.granty[self.granty.length] = {
            content: self.$t('central-GrantNevybran'),
            id: 0,
          }
          
          if (self.h) {
            self.objGrant[0] = self.granty[0]
            self.currentGrant = self.granty[0].id;
          }     
          self.ListVias()
        }, window.this.ApiError());
    },
    FreeAddr() {
      window.this.$log.debug('smazano')
      suppliersDataSource.length = 0;
    },
    ListAddr(supp) {

      let self = this;
      if (supp.component != undefined) {
        var e = supp
        supp = e.component.option("text")
      }
      var gen = new window.General(window.channel, window.message);
      window.this.$log.debug(supp)
      self.suppliers.length = 0;
      gen.ListAddr(supp, 1, 25,
        function (result) {
          //    window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.suppliers.length;
            self.suppliers[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          e.component.getDataSource().reload();
        }, window.this.ApiError());
    },
    Pozadavky() {
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.ListRequests(0, 0, 4, -1, '', 0,
        function (result) {
          window.this.$log.debug(result);
        }, window.this.ApiError());
    },
    Meny() {
      let self = this;
      self.meny.length = 0
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('{Meny(IDENT=ZAKLAD)}', '', 1, 25,
        function (result) {
          //  window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.meny.length;

            self.meny[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
        }, window.this.ApiError());
    },
    TypyDokumentu() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      self.typyDokumentu.length = 0
      gen.ListLookupDetail('ObjKniTypDoku', 1, 25,
        function (result) {
             window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.typyDokumentu.length;
            self.typyDokumentu[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              idx: i,
              ident1: result.List.value.items[x].Ident1.value,
            };
          }

        }, window.this.ApiError());
    },
    Formy() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      self.formy.length = 0
      gen.ListLookupDetail('ObjKniForma', 1, 25,
        function (result) {
             window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.formy.length;

            self.formy[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }

        }, window.this.ApiError());
    },
    NewLibraryOrderItem(requestId, id, Description, Price, Amount, TypeOfDocument, ISBN_ISSN, Form,  Currency, DepositedAtEmployeeID , url) {
      var wf = new window.WorkFlow(window.channel, window.message);
      let self = this;
      this.$log.debug(DepositedAtEmployeeID)
      wf.SetLibraryOrderItem_v2(requestId, id, Description, Price, Amount, TypeOfDocument, ISBN_ISSN, Form, Currency, DepositedAtEmployeeID, url,
        function (result) {
          window.this.$log.debug(result);
          if (result.Error.value.ErrorNum.value == 0) {
            self.PolozkyObjednavkyGrid(self.obj)
          } else {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 3000);

          }
        }, window.this.ApiError());
    }
  },
}
  ;     
</script>

<style scoped>
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}
#upravit {
  margin-bottom: 10px;
}

.actionsbuttons {
  clear: both;
  flex-direction: row;
  display: inline-block;
}

#button {
  margin-right: 40px;
}

label {
  margin-right: 20px;
}

#text1 {
  text-align: top;
  height: 70px;
  width: 300px;
}

input {
  text-align: top;
  margin-right: 40px;
}
#Prilohy{
  border-style: ridge; 
    border-color: rgba(0, 32, 91, 0.5);
    padding: 0px 15px 0px;
    border-radius: 8px;
    border-width: 1.5px;
  margin: 10px;
margin-left:75px;
background-color: rgba(255, 174, 0, 0.5);
font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;}
#obaleniResponsiveBox1, #obaleniResponsiveBox2{ border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 10px;
height: auto;
margin-top: 10px !important;
margin-bottom: 10px; background-color: rgba(128, 128, 128, 0.075);}
#obaleniDataGridu{border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 10px;}
  #objednavky{
    margin-bottom: 75px;
  }
  .DeponovanoUPozadavkyKnihovna, .GrantPozadavkyKnihovna {margin-bottom: 5px;}  
  #ResponsiveBoxItem{padding: 2px;}
  #PridatZaznam{background-color: rgb(0, 214, 0); scale: 80%;}
#PridatZaznam:hover{background-color: rgb(0, 197, 0);
scale: 85%;}
#ObnovitRozlozeni{scale: 85%; color: inherit; border-color: gray;}
.buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px;
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}
:deep(.dx-datagrid-form-buttons-container .dx-widget.dx-button:first-child){
  background-color: #5cb85c;
  color: #fff;
}
</style>